import React, { useEffect, useState } from 'react';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { getMultipleOptionValue, getOptionValue } from 'ui/util/get-option-value';
import { ProductStockAlert } from 'ui/component/product-configurator/product-info/product-stock-alert';
import { IProductAttribute } from '@silkpwa/module/react-component/product-config/util';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useLocation } from '@silkpwa/module/router/use-location';
import { getProductTypeConfigurable } from 'ui/util/get-product-type';
import { AttributeOptions } from './attribute-options';
import { SizeGuide } from './size-guide';
import { IProductConfigData } from '../product-config';
import styles from './style.css';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

const getSelected = (config, id) => getOptionValue(
    x => x.id === id,
)(config.selections, config.product);

const getMultipleSelected = (config, id) => getMultipleOptionValue(
    x => x.id === id,
)(config.product, config.orderMultiple?.current, config.multiSelections);

const getLabel = (attribute, config) => {
    const selected = getSelected(config, attribute.id);
    if (selected) {
        return `${attribute.label}: ${selected}`;
    }
    return attribute.label;
};

interface IConfigAttributeProps {
    attribute: IProductAttribute;
    isQuickView: boolean;
    config: IProductConfigData;
    queryString?: {[key: string]: any};
}

export const ConfigAttribute: React.FC<IConfigAttributeProps> = ({
    attribute,
    isQuickView,
    config,
    queryString = {},
}) => {
    const t = usePhraseTranslater();
    const storeConfig: SilkRestappDataConfigInfoInterface = useConfig();
    const location = useLocation();
    const [triggered, setTriggered] = useState(false);
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const productId = searchParams.get('productId');

        if (triggered || !productId) {
            return;
        }
        const simpleProductOption = config.simpleProduct.index.find(
            option => option.productId.toString() === productId,
        );
        if (simpleProductOption) {
            simpleProductOption.selections.forEach((selection) => {
                const matchingAttribute = config.attributes.find(attr => attr.id === selection.attribute);
                const matchingOption = matchingAttribute?.options.find(opt => opt.id === selection.value);

                if (matchingOption) {
                    matchingOption.handleClick();
                }
            });

            setTriggered(true);
        }
    }, [location.search, config.simpleProduct, config.attributes, triggered]);

    const isValidate = config.validation?.current &&
        (!(getSelected(config, attribute.id) || getMultipleSelected(config, attribute.id)));

    return (
        <div
            className={classes({
                [styles.wrap]: true,
                [styles.quickview]: isQuickView,
                [styles.validate]: isValidate,
            })}
        >
            {attribute.label === 'Size' && storeConfig.size_disclaimer !== '' && (
                <div
                    className={styles.disclaimer}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: storeConfig.size_disclaimer }}
                />
            )}
            {(attribute.label !== 'Size' || config.product.type !== getProductTypeConfigurable()) && (
                <div
                    className={classes({
                        [styles.label]: true,
                        [styles.quickview]: isQuickView,
                    })}
                >
                    {t('Select')}
                    {' '}
                    {getLabel(attribute, config)}
                </div>
            )}

            <AttributeOptions
                config={config}
                attribute={attribute}
                queryString={queryString}
            />

            {attribute.label === 'Size' && (
                <SizeGuide product={config.product} />
            )}
            {(attribute.label === 'Size' || config.attributes.length === 1) && (
                <ProductStockAlert product={config.simpleProduct} />
            )}
        </div>
    );
};
