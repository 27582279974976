import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { OVERRIDE_CATEGORY_ID_OPTION_TYPE } from 'ui/util/override-categories/id-param-resolver';
import { RawSpan } from 'ui/component/raw/span';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import { generateKey } from 'ui/util/generate-key';
import styles from './style.css';

interface IItemOptionsProps {
    item: ICartItemProps;
}

export const ItemOptions: React.FC<IItemOptionsProps> = ({ item }) => {
    const customizableOptions = item?.customizable_options || [];
    const configurableOptions = item?.configurable_options || [];
    const bundleOptions = item?.bundle_options || [];
    return (
        <div className={styles.options}>
            <dl className={styles.list}>
                {customizableOptions.map((option, index) => (
                    <span
                        className={classes(
                            styles.configurableOption,
                            (option?.__typename === OVERRIDE_CATEGORY_ID_OPTION_TYPE ? styles.hidden : ''),
                        )}
                        key={generateKey([index], option.label)}
                    >
                        <dt className={styles.label}>{option.label}</dt>
                        <dd className={styles.values}>
                            <RawSpan>{option.values?.[0]?.value}</RawSpan>
                        </dd>
                    </span>
                ))}
                {configurableOptions.map((option, index) => (
                    <dd className={styles.bundleOption} key={generateKey([index], option.sku)}>
                        <b className={styles.optionLabel}>{option.option_label}</b>
                        {': '}
                        {option.value_label}
                    </dd>
                ))}
                {bundleOptions.map((option, idx) => (
                    <dd className={styles.bundleOption} key={generateKey([idx])}>
                        {option?.values && option?.values.length > 0 && (
                            <>
                                {option.values.map((variant, vidx: number) => (
                                    <span key={vidx.toString()} className={styles.bundleOption}>
                                        {variant.quantity}
                                        {' X '}
                                        {variant.sku}
                                    </span>
                                ))}
                            </>
                        )}
                    </dd>
                ))}
            </dl>
        </div>
    );
};
