import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { IProductConfigData } from '../product-config';
import styles from './style.css';

interface IMultiConfigurableQuantityProps {
    attributeId: number;
    optionId: number;
    quantity?: IProductConfigData['quantity'];
    className?: string;
}

export const MultiConfigurableQuantity: React.FC<IMultiConfigurableQuantityProps> = ({
    attributeId,
    optionId,
    quantity,
    className,
}) => {
    if (!quantity) return null;
    const getQty = () => {
        if (quantity.currentByOption) {
            return quantity.currentByOption(optionId, attributeId);
        }
        return 0;
    };
    return (
        <div className={classes(styles.multiConfigurableQuantity, { [className]: className })}>
            <div className={classes(styles.quantityBox)}>
                <div className={styles.box1}>
                    <div className={classes(styles.quantity, 'multiConfigQuantity')}>
                        <button
                            onClick={() => quantity.decrement(optionId, attributeId)}
                            data-test="btn-qty-decr"
                            type="button"
                        >
                            -
                        </button>
                        <input
                            type="text"
                            value={getQty()}
                            onChange={e => quantity.set(Number(e.target.value), optionId, attributeId)}
                            onClick={e => (e.target as HTMLInputElement).select()}
                            data-test="qty-input"
                        />
                        <button
                            onClick={() => quantity.increment(optionId, attributeId)}
                            data-test="btn-qty-incr"
                            type="button"
                        >
                            +
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
