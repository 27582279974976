import { ModuleCreator, Duck } from '@silkpwa/redux';
import { Router } from '@silkpwa/module/router';

const Blog = new ModuleCreator({
    inject: ['router'],
    create(router: Router) {
        const blogPage = new Duck({
            selectors: {
                getBlogId(state) {
                    const { resourceId } = router.selectors.getCurrentResourceInfo(state);
                    return resourceId;
                },
            },
            initialize(_) {
                router.addHandler('cw_single_blog', async (route) => {
                    if (route.resource.resourceId) {
                        route.progress(1);
                    } else {
                        route.progress(0);
                    }
                });
            },
        });

        return blogPage;
    },
});

export { Blog };
