import React from 'react';
import { ICartItemProps, ICartSummaryData } from 'ui/component/cart/cart-interface';
import { Cart } from './cart';

interface MiniCartProps {
    cartItems: ICartItemProps[];
    cartSummaryData: ICartSummaryData;
    cartFreeShippingProgressBar: string;
    toggleMinicart: () => void;
}

const MiniCart: React.FC<MiniCartProps> = ({
    cartItems,
    cartSummaryData,
    cartFreeShippingProgressBar,
    toggleMinicart,
}) => (
    <Cart
        cartItems={cartItems}
        cartSummaryData={cartSummaryData}
        cartFreeShippingProgressBar={cartFreeShippingProgressBar}
        toggleMinicart={toggleMinicart}
    />
);

export { MiniCart };
