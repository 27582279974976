import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import styles from './style.css';

const getEditUrl = (item: ICartItemProps) => `/checkout/cart?item_id=${item.id}`;

interface IItemEditProps {
    item: ICartItemProps;
    toggleMinicart?: () => void;
}
export const ItemEdit = ({ item, toggleMinicart }: IItemEditProps) => {
    const t = usePhraseTranslater();
    return (
        <a
            href={getEditUrl(item)}
            className={styles.editButton}
            onClick={() => {
                if (toggleMinicart) {
                    toggleMinicart();
                }
           }}
        >
            {t('edit')}
        </a>
    );
};
