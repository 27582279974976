import React from 'react';
import { RoutingProgressBar } from 'ui/component/routing-progress-bar';
import { Notifier } from 'ui/component/notifier';
import { Layout } from 'ui/layout';
import { ScrollToTop } from 'ui/component/scroll-to-top';
import { DocumentTitleProvider } from '@silkpwa/module/react-component/document-title/provider';
import { OfflineBadge } from 'ui/component/offline-badge';
import { ModalSystemProvider } from '@silkpwa/module/react-component/modal-system/provider';
import { DataLayerProvider } from '@silkpwa/module/react-component/data-layer/provider';
import { AppEventsProvider } from '@silkpwa/module/react-component/app-events';
import { SPARouting } from '@silkpwa/module/react-component/spa-routing';
import { HydrationGuard } from '@silkpwa/module/react-component/hydration-guard';
import { ShowLoader } from 'ui/component/show-loader';
import { RestartOnFatalError } from '@silkpwa/module/react-component/restart-on-fatal-error';
import { CMSHead } from '@silkpwa/module/react-component/cms-head';
import { StoreTranslationMock } from 'ui/component/advanced-store-selector';
import { RedirectMeta } from '@silkpwa/module/react-component/redirect-meta';
import { useDesignConfig } from '@silkpwa/module/design-config';
import { RecoveryMessage } from './recovery-message';
import { CartModel } from './component/cart-model';
import './index.css';

const UI = (_: any) => {
    const designConfig = useDesignConfig();
    const title = designConfig.pageTitle ?? '';
    const loadingTitle = `Loading ${title}`;
    return (
        <SPARouting>
            <HydrationGuard fallback={<ShowLoader message={loadingTitle} />}>
                <RestartOnFatalError renderError={RecoveryMessage}>
                    <CMSHead />
                    <StoreTranslationMock />
                    <OfflineBadge />
                    <RoutingProgressBar />
                    <Notifier defaultTime={5000} />
                    <CartModel />
                    <DataLayerProvider>
                        <AppEventsProvider>
                            <ModalSystemProvider>
                                <DocumentTitleProvider value={(pageTitle: string) => `${pageTitle} | ${title}`}>
                                    <Layout />
                                </DocumentTitleProvider>
                            </ModalSystemProvider>
                        </AppEventsProvider>
                    </DataLayerProvider>
                    <ScrollToTop />
                    <RedirectMeta />
                </RestartOnFatalError>
            </HydrationGuard>
        </SPARouting>
    );
};

export { UI };
