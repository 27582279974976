import { useContainerHook } from '@silkpwa/redux';
import { IUsePriceFormatter } from '@silkpwa/module/ecommerce-catalog/config';

export const getCartTotalPrice = (formatPrice, summaryData) => formatPrice(summaryData?.grand_total?.value || 0);

export const useGetCartTotalPrice = () => {
    const formatPrice = useContainerHook<IUsePriceFormatter>('usePriceFormatter');
    return summaryData => getCartTotalPrice(formatPrice, summaryData);
};

export const getRewardsTotal = (formatPrice, summaryData) => {
    const item = summaryData?.raw_totals.filter(e => e.code === 'rewards-total')[0];

    if (!item) {
        return '';
    }
    return item.label.replace(/{{REWARDS}}/g, formatPrice(item?.amount?.value || 0));
};

export const useGetRewardsTotal = () => {
    const formatPrice = useContainerHook<IUsePriceFormatter>('usePriceFormatter');
    return summaryData => getRewardsTotal(formatPrice, summaryData);
};
