import React from 'react';
import { TabGroup } from 'ui/component/tab-group';
import { IProductAttribute } from '@silkpwa/module/react-component/product-config/util';
import {
    ITextSwatchesProps,
    IVisualSwatchesProps,
} from 'ui/component/product-configurator/product-config/config-attribute/swatches';
import { IProductConfigData } from 'ui/component/product-configurator/product-config';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { SingleSize } from './single-size';
import { MultiSize } from './multi-size';

interface ISizeOptionsProps {
    Components: {
        VisualSwatches: React.FC<IVisualSwatchesProps>;
        TextSwatches: React.FC<ITextSwatchesProps>;
    };
    attribute: IProductAttribute;
    config: IProductConfigData;
    queryString?: {[key: string]: any};
}

export const SizeOptions: React.FC<ISizeOptionsProps> = ({
    Components,
    attribute,
    config,
    queryString = {},
}) => {
    const t = usePhraseTranslater();
    const Component = Components[attribute.type];

    if (!Component) return null;

    const singleSizeContent = (
        <SingleSize
            Component={Component}
            attribute={attribute}
            config={config}
            queryString={queryString}
        />
    );

    const multiSizeContent = (
        <MultiSize
            Component={Component}
            attribute={attribute}
            config={config}
        />
    );

    const setOrderMultiple = (value: boolean) => config.orderMultiple?.set(value);

    const tabTypes = [
        {
            title: t('Select Size'),
            content: singleSizeContent,
            handleTabSelection: () => setOrderMultiple(false),
        },
        {
            title: t('Order Multiple'),
            content: multiSizeContent,
            handleTabSelection: () => setOrderMultiple(true),
        },
    ];

    return (
        <TabGroup types={tabTypes} />
    );
};
