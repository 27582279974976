import React, { useEffect, useMemo, useRef } from 'react';
import { persistentHeaderHeight } from 'ui/styles/reserved-height';
import styles from './style.css';

export const Dialog = ({ loadState, children }) => {
    const loadStateMemo = useMemo(() => loadState.toString(), [loadState]);
    const pageRef = useRef<HTMLDivElement>(null);
    const scrollToElement = () => {
        // Get the reference to the page container element
        // TODO: currently there is minor issue but its better than moving configurator under header.
        const pageContainer = document.getElementById('embedded-emb-configurator-content');
        if (pageContainer) {
            const targetScrollPosition = pageContainer.offsetTop - persistentHeaderHeight;
            // eslint-disable-next-line no-unused-expressions
            window.scrollTo({
                top: targetScrollPosition,
                behavior: 'smooth',
            });
        } else {
            // eslint-disable-next-line no-unused-expressions
            pageRef.current?.scrollIntoView({
                behavior: 'smooth',
                inline: 'start',
            });
        }
    };

    useEffect(() => {
        scrollToElement();
    }, [loadStateMemo]);

    return (
        <div ref={pageRef}>
            <div className={styles.body}>
                {children}
            </div>
        </div>
    );
};
