import React from 'react';
import { ICartItemProps, ICartSummaryData } from 'ui/component/cart/cart-interface';
import { LineItems } from './line-items';
import { CartSummary } from './cart-summary';
import { CartTotal } from './cart-total';
import { CartButtons } from './cart-buttons';

interface ContentWithItemsProps {
    cartItems: ICartItemProps[];
    cartSummaryData: ICartSummaryData;
    toggleMinicart?: () => void;
    isCheckoutPage?: boolean;
}

const ContentWithItems: React.FC<ContentWithItemsProps> = ({
    cartItems, cartSummaryData, toggleMinicart, isCheckoutPage = false,
}) => (
    <>
        <LineItems cartItems={cartItems} toggleMinicart={toggleMinicart} />
        <CartSummary cartSummaryData={cartSummaryData} />
        <CartTotal cartSummaryData={cartSummaryData} />
        {!isCheckoutPage && <CartButtons toggleMinicart={toggleMinicart} />}
    </>
);

export { ContentWithItems };
