import { IQueryParams, ExtendedLocation } from '@silkpwa/module/cfur';

const tagType = (x: any): any => {
    if (typeof x === 'number') {
        return `_n${encodeURIComponent(x)}`;
    } if (typeof x === 'string') {
        return `_s${encodeURIComponent(x)}`;
    } if (typeof x === 'boolean') {
        return `_b${encodeURIComponent(x)}`;
    } if (typeof x === 'object' && x instanceof Array) {
        return `_l${x.map(tagType).join(',')}`;
    }
    return x;
};

const unTagType = (x: string): any => {
    if (x.indexOf('_n') === 0) {
        return Number(decodeURIComponent(x.substring(2)));
    } if (x.indexOf('_s') === 0) {
        return decodeURIComponent(x.substring(2));
    } if (x.indexOf('_b') === 0) {
        return Boolean(decodeURIComponent(x.substring(2)));
    } if (x.indexOf('_l') === 0) {
        const list = x.substring(2).split(',').filter(k => k.length > 0);
        return list.map(unTagType);
    }
    return x;
};

export const serializeQuery = (obj: any): string => Object
    .keys(obj)
    .map(k => `${encodeURIComponent(k)}=${obj[k]}`)
    .join('&');

const serialize = (state: any) => {
    const queryObj: any = {};

    const setInQuery = (path: string[], value: any) => {
        queryObj[path.join('.')] = value;
    };

    const recursiveSerialize = (obj: any, path: string[]) => {
        Object.keys(obj).forEach((k) => {
            if (typeof obj[k] === 'object' && obj[k] instanceof Array) {
                setInQuery([...path, k], tagType(obj[k]));
            } else if (typeof obj[k] === 'object') {
                recursiveSerialize(obj[k], [...path, k]);
            } else {
                setInQuery([...path, k], tagType(obj[k]));
            }
        });
    };

    recursiveSerialize(state, []);

    return serializeQuery(queryObj);
};

export const deserializeQuery = (uri: string) => uri
    .split('&')
    .filter(x => x)
    .map(p => p.split('='))
    .reduce((acc: any, [k, v]) => {
        acc[decodeURIComponent(k)] = v;
        return acc;
    }, {});

const deserialize = (queryString: string) => {
    const setIn = (inputObj: any, path: string[], value: any) => {
        let obj = inputObj;

        path.slice(0, -1).forEach((p) => {
            obj[p] = obj[p] || {};
            obj = obj[p];
        });

        obj[path[path.length - 1]] = value;
    };

    const deserializeState = (queryObj: any) => {
        const state: any = {};
        Object.keys(queryObj).forEach((k) => {
            const path = k.split('.').filter(x => x.length > 0);
            setIn(state, path, unTagType(queryObj[k]));
        });

        return state;
    };

    const queryObj = deserializeQuery(queryString);
    return deserializeState(queryObj);
};

const deserializeLocation = (
    location: ExtendedLocation,
    useHash = false,
    deserializeStrategy = deserialize,
) => {
    try {
        const currentVal = useHash ? location.hash : location.search;
        const currentQuery = (currentVal || '?').substring(1);
        const deserializedQuery: IQueryParams = deserializeStrategy(currentQuery);

        const { queryParams } = location;
        const locationParamsExist = queryParams && Object.keys(queryParams).length;
        if (!locationParamsExist) {
            return deserializedQuery;
        }

        const deserializedQueryParamsExist = deserializedQuery && Object.keys(deserializedQuery).length;
        if (!deserializedQueryParamsExist) {
            return queryParams;
        }

        Object.keys(queryParams).forEach((paramKey: string): void => {
            const locationParamString = `${queryParams[paramKey]}`;
            const locationParamArray = locationParamString.split(',');
            if (deserializedQuery[paramKey]) {
                const deserializedQueryParamString = `${deserializedQuery[paramKey]}`;
                const deserializedQueryParamArray = deserializedQueryParamString.split(',');
                const combinedValues = [
                    ...locationParamArray,
                    ...deserializedQueryParamArray,
                ];
                deserializedQuery[paramKey] = combinedValues.join(',');
            } else {
                deserializedQuery[paramKey] = locationParamString;
            }
        });
        return deserializedQuery;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Failed to parse query string', e);
        return {};
    }
};

export { serialize, deserialize, deserializeLocation };
