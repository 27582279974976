import { gql, TypedDocumentNode } from '@apollo/client';

export interface ISubscribeEmailToNewsletterInput {
    email: string;
}

export interface ISubscribeEmailToNewsletterOutput {
    subscribeEmailToNewsletter: {
        status: string;
    };
}

export const GET_CUSTOMER: TypedDocumentNode = gql`
    query GET_CUSTOMER {
        customer {
            firstname
            lastname
            suffix
            email
            addresses {
                id
                firstname
                lastname
                company
                street
                city
                region {
                    region_code
                    region
                }
                postcode
                country_code
                telephone
            }
            default_billing
            default_shipping
            portal {
                id
                code
            }
            cw_po_number
            store_credit {
                enabled
                current_balance {
                    currency
                    value
                }
            }
        }
    }
`;

export const GET_CUSTOMER_LOGOS: TypedDocumentNode = gql`
    query GET_CUSTOMER_LOGOS(
        $orderBy: SortOrderEnum!
        $search: String!
        $pageSize: Int!
        $currentPage: Int!
    ){
        getCustomerLogo(
            order_by: $orderBy
            search: $search
            page_size: $pageSize
            current_page: $currentPage
        ) {
            logo {
                image_url
                image_thumb
                alt
                title
                logo_number
                stitch_count
                design_description
                height
                width
                trims
                colors {
                    color
                    hash_code
                }
            }
            page_info {
                current_page
                page_size
                total_pages
            }
            order_by
        }
    }
`;

export const IS_EMAIL_AVAILABLE: TypedDocumentNode = gql`
    query IS_EMAIL_AVAILABLE(
        $email: String!
    ){
        isEmailAvailable(email: $email) {
            is_email_available
        }
    }
`;

export const SUBSCRIBE_EMAIL_TO_NEWSLETTER:
TypedDocumentNode<ISubscribeEmailToNewsletterOutput, ISubscribeEmailToNewsletterInput> = gql`
    mutation SUBSCRIBE_EMAIL_TO_NEWSLETTER ($email: String!) {
        subscribeEmailToNewsletter(email: $email) {
            status
        }
    }
`;

export const REQUEST_RESET_PASSWORD: TypedDocumentNode = gql`
    mutation requestPasswordResetEmail($email: String!) {
      requestPasswordResetEmail(email: $email)
    }
`;
