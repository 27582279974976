import React, { useEffect, useState } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { QuantityMessage } from 'ui/component/quantity-message';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { StockStatus } from 'ui/component/product-configurator/product-info/stock-status';
import styles from './style.css';

interface ISingleConfigurableQuantityProps {
    quantity: {
        current: number;
        set: (v: number) => void;
        decrement: () => void;
        increment: () => void;
    };
    simpleProduct: any;
    queryString: {[key: string]: any};
}

export const SingleConfigurableQuantity: React.FC<ISingleConfigurableQuantityProps> = ({
    quantity,
    simpleProduct,
    queryString,
}) => {
    const t = usePhraseTranslater();
    const [initialQuantity, setInitialQuantity] = useState<string | null>(null);
    useEffect(() => {
        const searchParams = new URLSearchParams(queryString);
        const wlQuantity = searchParams.get('qty');
        if (wlQuantity && !initialQuantity) {
            setInitialQuantity(wlQuantity);
            quantity.set(Number(wlQuantity));
        }
    }, [queryString, initialQuantity, quantity]);
    return (
        <div className={styles.singleConfigurableQuantity}>
            <div className={classes(styles.quantityBox)}>
                <div className={styles.box1}>
                    <div className={styles.label}>
                        {t('Quantity')}
                    </div>
                    <div className={styles.quantity}>
                        <button
                            onClick={() => quantity.decrement()}
                            data-test="btn-qty-decr"
                            type="button"
                        >
                            -
                        </button>
                        <input
                            type="text"
                            value={quantity.current}
                            onChange={e => quantity.set(Number(e.target.value))}
                            onClick={e => (e.target as HTMLInputElement).select()}
                            data-test="qty-input"
                        />
                        <button
                            onClick={() => quantity.increment()}
                            data-test="btn-qty-incr"
                            type="button"
                        >
                            +
                        </button>
                    </div>
                </div>
                <div className={styles.box2}>
                    <StockStatus
                        product={simpleProduct}
                        parentClass={styles.quantityStockStatus}
                        type="footer"
                    />
                    <QuantityMessage
                        className={styles.quantityMessage}
                        quantity={quantity.current || Number(initialQuantity)}
                        product={simpleProduct}
                    />
                </div>
            </div>
        </div>
    );
};
