import React, { useState } from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

export interface ITabProps {
    active: boolean;
    action: (e?: React.KeyboardEvent | React.MouseEvent<Element, MouseEvent>) => void;
}

export const Tab: React.FC<React.PropsWithChildren<ITabProps>> = ({ active, action, children }) => (
    <AccessibleButton
        tag="div"
        action={action}
        className={classes(styles.tabButton, { [styles.active]: active })}
    >
        {children}
    </AccessibleButton>
);

export interface ITab {
    title: string;
    content: React.ReactNode;
    handleTabSelection: () => void;
}

export interface ITabGroupProps {
    types: ITab[];
    className?: string;
}

export const TabGroup: React.FC<ITabGroupProps> = ({ types, className }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const isActiveTab = (type: ITab) => types.findIndex(
        item => item.title === type.title,
    ) === activeIndex;
    return (
        <div className={classes(styles.tabWrapper, { [className]: className })}>
            <div className={styles.tabButtonGroup}>
                {types.map((type, index) => (
                    <Tab
                        key={type.title}
                        active={isActiveTab(type)}
                        action={() => {
                            setActiveIndex(index);
                            type.handleTabSelection();
                        }}
                    >
                        {type.title}
                    </Tab>
                ))}
            </div>
            <div className={styles.tabContentWrapper}>
                <div className={styles.tabContent}>
                    {types[activeIndex].content}
                </div>
            </div>
        </div>
    );
};
