import {
    IMultiSelection,
} from '@silkpwa/module/react-component/product-config/configurable-product/configurable-product';

export const getOptionValue = predicate => (selections, configurableProduct) => {
    const colorOption = configurableProduct.options.filter(predicate)[0];

    if (!colorOption) return undefined;

    const selectedColorId = selections[Number(colorOption.id)];

    return colorOption.options
        .filter(x => x.id === selectedColorId)
        .map(x => x.label)[0];
};

/**
 * get all selected size option label as comma separated string e.g. "XS,L,2XL"
 * when customer selected more than one size at configurable "M,L"
 */
export const getMultipleOptionValue = predicate => (
    configurableProduct,
    isOrderMultiple = false,
    multiSelections = [],
) => {
    if (!isOrderMultiple) return undefined;

    const sizeOption = configurableProduct.options.filter(predicate)[0];

    if (!sizeOption || !multiSelections.length) return undefined;

    const selectedSizeIds = multiSelections.map(
        (multiSelection: IMultiSelection) => multiSelection.selections[Number(sizeOption.id)],
    );

    return sizeOption.options
        .filter(x => selectedSizeIds.includes(x.id))
        .map((x: {label: string}) => x.label)
        .join();
};
