import React from 'react';
import styles from './style.css';
import { ConfigAttribute } from '../config-attribute';
import { Fits } from '../fits';
import { IProductConfigData } from '../product-config';

interface IConfigurableConfigProps {
    config: IProductConfigData;
    isQuickView: boolean;
    queryString?: {[key: string]: any};
}

export const ConfigurableConfig: React.FC<IConfigurableConfigProps> = ({
    config,
    isQuickView,
    queryString = {},
}) => (
    <div>
        <div id="validation-area" className={styles.attributes}>
            {config.attributes?.map(att => (
                <ConfigAttribute
                    key={att.id}
                    attribute={att}
                    isQuickView={isQuickView}
                    config={config}
                    queryString={queryString}
                />
             ))}
        </div>
        <Fits config={config} />
    </div>
);
