import React from 'react';
import { IProductAttribute } from '@silkpwa/module/react-component/product-config/util';
import { getProductTypeConfigurable } from 'ui/util/get-product-type';
import { SelectSwatches } from './select-swatches';
import { VisualSwatches, TextSwatches } from './swatches';
import { SizeOptions } from './size-options';
import { IProductConfigData } from '../product-config';

interface IAttributeOptionsProps {
    attribute: IProductAttribute;
    config: IProductConfigData;
    queryString?: {[key: string]: any};
}

export const AttributeOptions: React.FC<IAttributeOptionsProps> = ({
    attribute,
    config,
    queryString = {},
}) => {
    if (attribute.label === 'Size' && config.product.type === getProductTypeConfigurable()) {
        return (
            <SizeOptions
                Components={{
                    VisualSwatches,
                    TextSwatches,
                }}
                attribute={attribute}
                config={config}
                queryString={queryString}
            />
        );
    }
    return (
        <SelectSwatches
            Components={{
                VisualSwatches,
                TextSwatches,
            }}
            attribute={attribute}
        />
    );
};
