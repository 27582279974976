import React from 'react';
import { ItemGiftCardDetails } from 'chefworks-theme/src/ui/component/cart/cart-with-items/line-items/items/line-item/item-information/item-giftcard-details';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import { ItemHeader } from './item-header';
import { ItemSKU } from './item-sku';
import { ItemLoyaltyMessage } from './item-loyalty-message';
import { ItemOptions } from './item-options';
import { ItemActions } from './item-actions';
import styles from './style.css';

interface IItemInformationProps {
    item: ICartItemProps;
    toggleMinicart?: () => void;
}

export const ItemInformation: React.FC<IItemInformationProps> = ({ item, toggleMinicart }) => (
    <div className={styles.productItemDetails}>
        <ItemHeader item={item} />
        <ItemSKU item={item} />
        <ItemLoyaltyMessage item={item} />
        <ItemOptions item={item} />
        <ItemGiftCardDetails item={item} />
        <ItemActions item={item} toggleMinicart={toggleMinicart} />
    </div>
);
