import React from 'react';
import { useContainerHook } from '@silkpwa/redux';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { IUsePriceFormatter } from '@silkpwa/module/ecommerce-catalog/config';
import { getValue } from 'ui/component/cart/cart-with-items/order-summary/summary-list/util';
import { classes } from '@silkpwa/module/util/classes';
import { RawSpan } from 'ui/component/raw/span';
import { ICartSummaryData } from 'ui/component/cart/cart-interface';
import { generateKey } from 'ui/util/generate-key';
import styles from './style.css';

interface ICartSummaryProps {
    cartSummaryData: ICartSummaryData;
}

export const CartSummary: React.FC<ICartSummaryProps> = ({ cartSummaryData }) => {
    const formatPrice = useContainerHook<IUsePriceFormatter>('usePriceFormatter');
    const t = usePhraseTranslater();
    const subTotal = cartSummaryData.subtotal_excluding_tax;
    const grandTotal = cartSummaryData.grand_total;
    const taxTotal = cartSummaryData.tax_total?.amount?.value ? cartSummaryData.tax_total?.amount : null;
    const shippingAmount = cartSummaryData.shipping_charge?.amount?.value
        ? cartSummaryData.shipping_charge?.amount : null;
    const discounts = cartSummaryData.discounts ?? [];

    return (
        <div
            className={styles.totalsContainer}
        >
            <p className={styles.summaryHeading} key="order-summery">{t('Order Summary')}</p>
            <p className={styles.priceType} key="subtotal_excluding_tax">
                <span className={styles.summaryLabel}>{t('Subtotal')}</span>
                <span className={classes(styles.price, 'weglot-exclude')}>{getValue(formatPrice, subTotal)}</span>
            </p>
            {discounts.map((discount, index) => (
                <p className={styles.priceType} key={generateKey([index], 'discount')}>
                    <RawSpan className={styles.discountWrapper} data-test="discount-label">{discount.label}</RawSpan>
                    <span
                        className={classes(styles.price, 'weglot-exclude')}
                    >
                        {'-'}
                        {getValue(formatPrice, discount?.amount)}
                    </span>
                </p>
            ))}
            {shippingAmount && (
                <p className={styles.priceType} key="shipping_charge">
                    <span className={styles.summaryLabel}>{t('Shipping & Handling')}</span>
                    <span className={classes(styles.price, 'weglot-exclude')}>{getValue(formatPrice, shippingAmount)}</span>
                </p>
            )}
            {taxTotal && (
                <p className={styles.priceType} key="tax_total">
                    <span className={styles.summaryLabel}>{t('Tax')}</span>
                    <span className={classes(styles.price, 'weglot-exclude')}>{getValue(formatPrice, taxTotal)}</span>
                </p>
            )}
            <p className={styles.priceType} key="grand_total">
                <span className={styles.summaryLabel}>{t('Grand Total')}</span>
                <span className={classes(styles.price, 'weglot-exclude')}>{getValue(formatPrice, grandTotal)}</span>
            </p>
        </div>
    );
};
