import React from 'react';
import { ICartRepository } from '@silkpwa/magento/api/cart-repository/repository';
import { BaseProduct, IBaseProductConfigProps } from '@silkpwa/module/react-component/product-config/base-product';
import { getEnableCommitInfo } from 'chefworks-theme/src/ui/util/get-enable-commit-info';
import { memoize } from '@silkpwa/module/util/memoize';
import { computeSimpleProduct } from '@silkpwa/module/util/compute-simple-product';
import { IProductConfigData } from 'chefworks-theme/src/ui/component/product-configurator/product-config';
import { getProductAttribute } from 'chefworks-theme/src/ui/util/get-product-attribute';
import _ from 'lodash';
import {
    getColors,
    computeProductAttributes,
    IProductAttribute,
    IProductOption,
    ISelections,
    checkSelections, fixValue, isValidMinQty, isValidMaxQty,
} from '../util';
import { RenderConfigurator } from '../render-configurator';

interface IQuantityByOption {
    optionId: number;
    attributeId: number;
    colorSelections: ISelections;
    qty: number;
}

export interface IMultiSelection {
    qty: number;
    selections: ISelections;
    simpleProduct: any; // TODO: add typing
}

export class ConfigurableProductConfig extends BaseProduct {
    private readonly computeSimpleProduct;

    private _minQuantity;

    private readonly computeProductAttributes;

    private readonly defaultQuantityByOption: IQuantityByOption = {
        optionId: 0,
        attributeId: 0,
        colorSelections: {},
        qty: 1,
    };

    constructor(props: Readonly<IBaseProductConfigProps>) {
        super(props);
        this.state = {
            ...this.state,
            quantityByOption: [{ ...this.defaultQuantityByOption }],
            multiSelections: [],
            isOrderMultiple: false,
        };

        this.areConfigurableOptionsSelected = this.areConfigurableOptionsSelected.bind(this);
        this.computeProductAttributes = memoize(computeProductAttributes);
        this.computeSimpleProduct = memoize(computeSimpleProduct);
        this.doEffect = this.doEffect.bind(this);
    }

    componentDidMount() {
        this.handleSingleOptionSelect();
        super.componentDidMount();
    }

    componentDidUpdate() {
        this.handleQuantityChange();
    }

    get attributes(): IProductAttribute[] {
        const { product } = this.props;

        return this.computeProductAttributes(
            product,
            this.selections,
            this.selectOption.bind(this),
        );
    }

    protected get simpleProductId() {
        const { product } = this.props;

        return this.computeSimpleProduct(product, this.selections);
    }

    get colorProductId() {
        const { product } = this.props;
        const colors = getColors(product);
        if (!colors) return undefined;
        const selection = this.selections[colors.id];
        if (!selection) return undefined;
        return product.colorIndex[selection];
    }

    get imageProductId() {
        const { product } = this.props;
        return (
            this.simpleProductId ||
            this.colorProductId ||
            product.id
        );
    }

    private getQtyByOptionId(optionId = 0, attributeId = 0): number {
        const { quantityByOption } = this.state;
        const optionQty = quantityByOption.find(
            (option: IQuantityByOption) => option.optionId === optionId && option.attributeId === attributeId,
        );
        if (optionQty) {
            return Number(optionQty.qty);
        }
        return 0;
    }

    /**
     * Fetch min quantity for selected option id
     * @param optionId
     * @param attributeId
     * @private
     */
    private getMinimumQty(optionId = 0, attributeId = 0): number {
        let minQty = 0;
        const { product } = this.props;
        if (optionId === 0) {
            return this.simpleProduct.minQuantity ?? product.minQuantity;
        }
        if (this.orderMultiple.current && this.multiSelections.length === 1) {
            const { selections } = this.state;
            const selectedOption = { ...selections };
            selectedOption[attributeId] = optionId;
            const simpleProductId = this.computeSimpleProduct(product, selectedOption);
            const simpleProduct = this.getSimpleProductById(simpleProductId);
            if (simpleProduct.minQuantity || product.minQuantity) {
                minQty = simpleProduct.minQuantity ?? product.minQuantity;
            }
        }

        return minQty;
    }

    /**
     * it will process selections and multiple size selections and set proper values to multiSelections
     * @private
     */
    private processMultiSelections(): void {
        const { product, loadProduct } = this.props;
        const { selections, quantityByOption, isOrderMultiple } = this.state;
        const multiSelections: IMultiSelection[] = [];
        if (!isOrderMultiple) {
            return;
        }
        const color = getColors(product);
        // clears single size option values which stored without optionsId and filter out different color options
        const multiSizeSelections = quantityByOption.filter((
            selection: IQuantityByOption,
        ) => selection.optionId !== 0 && selection.colorSelections[color.id] === selections[color.id]);
        multiSizeSelections.forEach((sizeSelection: IQuantityByOption) => {
            const selectedOption: {[key: number]: number} = {};
            product.options.forEach((attribute: IProductOption) => {
                if (attribute.id === sizeSelection.attributeId) {
                    selectedOption[attribute.id] = sizeSelection.optionId;
                } else if (selections[attribute.id] !== undefined) {
                    selectedOption[attribute.id] = selections[attribute.id];
                }
            });
            // check if all the available options were selected
            if (product.options.length === Object.keys(selectedOption).length) {
                const simpleProductId = this.computeSimpleProduct(product, selectedOption);
                let simpleProduct = this.getSimpleProductById(simpleProductId) ?? {};
                if (!simpleProduct) {
                    const isPromise = loadProduct(simpleProductId, product.id, this.cartItem);
                    if (isPromise) {
                        isPromise.then(() => {
                            simpleProduct = this.getSimpleProductById(simpleProductId) ?? {};
                        });
                    }
                }
                multiSelections.push({
                    selections: selectedOption,
                    qty: sizeSelection.qty,
                    simpleProduct,
                });
            }
        });

        this.setState({
            multiSelections,
        });
    }

    private updateQtyByOption(optionId: number, attributeId: number, update: (v: number) => number) {
        const { selections, isOrderMultiple } = this.state;
        // this will filter out only color attribute selection
        const colorSelections = isOrderMultiple ? Object.fromEntries(
            Object.entries(selections).filter(([key, _]) => attributeId !== Number(key)),
        ) : {};
        const minQty = this.getMinimumQty(optionId, attributeId);
        // create new qty by option if not available
        this.setState((s) => {
            const updatedQuantity = [...s.quantityByOption];
            const shouldAddEmpty = s.quantityByOption.find(
                (option: IQuantityByOption) => option.optionId === optionId && option.attributeId === attributeId &&
                    _.isEqual(option.colorSelections, colorSelections),
            );
            if (!shouldAddEmpty) {
                updatedQuantity.push({
                    optionId,
                    attributeId,
                    colorSelections,
                    qty: 0,
                });
                return {
                    quantityByOption: updatedQuantity,
                };
            }
            return {};
        });

        // update qty by option
        this.setState((s) => {
            const updatedQuantity = s.quantityByOption.map((option: IQuantityByOption) => {
                if (
                    option.optionId !== optionId ||
                    option.attributeId !== attributeId ||
                    !_.isEqual(option.colorSelections, colorSelections)
                ) {
                    return option;
                }
                return {
                    ...option,
                    qty: fixValue(
                        update(option.qty),
                        option.qty,
                        minQty,
                    ),
                };
            });
            return {
                quantityByOption: updatedQuantity,
            };
        });

        // remove qtyByOption when qty is not set and then generate multi selections
        this.setState((s) => {
            const updatedQuantity = s.quantityByOption.filter((option: IQuantityByOption) => option.qty > 0);
            return {
                quantityByOption: updatedQuantity,
            };
        }, this.processMultiSelections);
    }

    get quantityByOption(): IProductConfigData['quantity'] {
        const { qtyIncr } = this.processQuantity();
        return {
            current: this.getQtyByOptionId(),
            increment: (
                optionId = 0,
                attributeId = 0,
            ) => this.updateQtyByOption(optionId, attributeId, qty => qty + qtyIncr),
            decrement: (
                optionId = 0,
                attributeId = 0,
            ) => this.updateQtyByOption(optionId, attributeId, qty => qty - qtyIncr),
            set: (
                v,
                optionId = 0,
                attributeId = 0,
            ) => this.updateQtyByOption(optionId, attributeId, () => v),
            currentByOption: (
                optionId = 0,
                attributeId = 0,
            ) => this.getQtyByOptionId(optionId, attributeId),
        };
    }

    get orderMultiple(): {current: boolean; set: (v: boolean) => void} {
        const { isOrderMultiple } = this.state;
        return {
            current: isOrderMultiple,
            set: (v: boolean) => {
                this.setState({ isOrderMultiple: v });
            },
        };
    }

    private getSimpleProductById(productId: number): any { // TODO: add typing
        const { getProduct, state } = this.props;
        if (!productId) {
            return undefined;
        }
        return getProduct(
            state,
            productId,
        );
    }

    get multiSelections(): IMultiSelection[] {
        const { multiSelections } = this.state;
        return multiSelections;
    }

    areConfigurableOptionsSelected(): boolean {
        let result = true;
        const { isOrderMultiple, multiSelections } = this.state;
        const { product } = this.props;
        if (isOrderMultiple) {
            // check multiple size options selection
            const isSimpleProductSelected = multiSelections.every((item: IMultiSelection) => item.simpleProduct.id);
            const isDiscontinued = multiSelections.some(
                (item: IMultiSelection) => getProductAttribute(
                    item.simpleProduct,
                    'discontinued_product',
                ) === 'yes' && item.simpleProduct.quantity <= 0,
            );
            const isInStock = multiSelections.every((item: IMultiSelection) => item.simpleProduct.inStock);
            result = isSimpleProductSelected && isInStock && !isDiscontinued;
        } else {
            // check for single size options selection
            const isSimpleProductSelected = this.simpleProduct.id !== product.id;
            const isDiscontinued = getProductAttribute(this.simpleProduct, 'discontinued_product') === 'yes' &&
                this.simpleProduct.quantity <= 0;
            const isInStock = this.simpleProduct.inStock;
            result = isSimpleProductSelected && isInStock && !isDiscontinued;
        }
        return result;
    }

    handleEditItem() {
        const item = this.cartItem;

        const selections = {};
        item.selections.forEach(([k, v]) => {
            selections[k] = v;
        });

        this.setState({
            selections,
            quantity: item.qty,
            quantityByOption: [{
                ...this.defaultQuantityByOption,
                qty: item.qty,
            }],
        }, this.doEffect);
    }

    handleQuantityChange() {
        if (this.simpleProduct.minQuantity !== this._minQuantity) {
            this._minQuantity = this.simpleProduct.minQuantity;
            if (this.cartItem) return;
            this.setState({
                quantity: this.simpleProduct.minQuantity,
                quantityByOption: [{
                    ...this.defaultQuantityByOption,
                    qty: this.simpleProduct.minQuantity,
                }],
            });
        }
    }

    protected handleNewItem() {
        this.handleQuantityChange();
    }

    handleSingleOptionSelect() {
        const { product } = this.props;
        const selections: {[key: string]: any} = {};
        product.options.forEach((option) => {
            /*
             * prioritize previously selected color option
             * else it will select the first color option from list
             */
            if (option.options.length) {
                if (option.options.length === 1) {
                    selections[option.id] = option.options[0].id;
                } else if (!this.data?.selections[option.id] && option.label.toLowerCase() === 'color') {
                    selections[option.id] = option.options[0].id;
                }
            }
        });

        this.setState({ selections }, this.doEffect);
    }

    doEffect() {
        const { loadProduct } = this.props;
        const { product } = this.props;

        if (this.simpleProductId) {
            loadProduct(this.simpleProductId, product.id, this.cartItem);
        }

        if (this.colorProductId) {
            loadProduct(this.colorProductId, product.id, this.cartItem);
        }
    }

    selectOption(k, v) {
        this.setState(s => ({
            selections: {
                ...s.selections,
                [k]: v,
            },
        }), this.doEffect);
    }

    protected async addItemToCart(repository: ICartRepository) {
        const { product } = this.props;
        const { multiSelections, isOrderMultiple } = this.state;
        if (isOrderMultiple && multiSelections.length) {
            await Promise.all(multiSelections.map(async (multiSelection: IMultiSelection): Promise<any> => {
                const simpleProductId = this.computeSimpleProduct(product, multiSelection.selections);
                if (!simpleProductId) {
                    return undefined;
                }
                return repository.addSimpleProduct(
                    simpleProductId,
                    multiSelection.qty,
                    product.id,
                );
            }));
        } else {
            await repository.addSimpleProduct(
                this.simpleProductId,
                this.quantityByOption.current,
                product.id,
            );
        }
    }

    async addToCart(repository: ICartRepository) {
        const {
            itemId,
            close,
            enqueue,
            t,
        } = this.props;

        try {
            if (!checkSelections(this.data)) {
                enqueue({
                    type: 'primary',
                    message: t('Not all required options were selected'),
                    time: 5000,
                });
                return;
            }
            const { enabledButton } = getEnableCommitInfo(this.data);
            if (!enabledButton) {
                enqueue({
                    type: 'primary',
                    message: t('This product is not available'),
                    time: 5000,
                });
                return;
            }
            if (!this.simpleProductId && !(this.orderMultiple.current && this.multiSelections.length)) {
                throw new Error(t('Need to select a product'));
            }

            const { minQuantity, maxQuantity } = this.simpleProduct;

            if (itemId && this.quantityByOption.current === 0) {
                await this.removeItem(repository);
            } else if (!isValidMinQty(this.data, minQuantity)) {
                enqueue({
                    type: 'primary',
                    message: t('Cannot add less than %1 of this item to your cart.', minQuantity),
                    time: 5000,
                });
                throw new Error(t('Too little quantity'));
            } else if (!isValidMaxQty(this.data, maxQuantity)) {
                enqueue({
                    type: 'primary',
                    message: t('Cannot add more than %1 of this item to your cart.', maxQuantity - 1),
                    time: 5000,
                });
                throw new Error(t('Too much quantity'));
            } else if (itemId) {
                await this.updateItem(repository);
            } else {
                await this.addNewItem(repository);
            }

            if (close) close();
        } finally {
            this._adding = false;
        }
    }

    render() {
        const { children } = this.props;
        this.data = {
            ...this.renderData(),
            type: 'ConfigurableConfig',
            attributes: this.attributes,
            validation: this.validation,
            quantity: this.quantityByOption,
            orderMultiple: this.orderMultiple,
            multiSelections: this.multiSelections,
            areConfigurableOptionsSelected: this.areConfigurableOptionsSelected,
        };

        return (
            <RenderConfigurator data={this.data}>
                {children}
            </RenderConfigurator>
        );
    }
}
