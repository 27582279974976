// TODO: use following get product type function to fetch the product type for checking product type
// currently only bundle type us used so commented out the other
enum EProductType {
    BUNDLE = 'bundle',
    CONFIGURABLE = 'configurable',
    // SIMPLE = 'simple',
    // VIRTUAL = 'virtual',
    // GIFTCARD = 'giftcard',
}

// export const getProductTypeSimple = () => EProductType.SIMPLE;
export const getProductTypeConfigurable = () => EProductType.CONFIGURABLE;
export const getProductTypeBundle = () => EProductType.BUNDLE;
// export const getProductTypeVirtual = () => EProductType.VIRTUAL;
// export const getProductTypeGiftcard = () => EProductType.GIFTCARD;
