import React from 'react';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import styles from './style.css';

interface IItemImageProps {
    item: ICartItemProps;
}

export const ItemImage: React.FC<IItemImageProps> = ({ item }) => (
    <div className={styles.productItemPhoto}>
        <span className={styles.productImageWrapper}>
            <img
                src={item.product?.small_image?.url}
                className={styles.productImagePhoto}
                alt={item.name}
            />
        </span>
    </div>
);
