import React from 'react';
import styles from './style.css';

export interface IFreeShippingProgressBarProps {
    cartFreeShippingProgressBar: string;
}

/* eslint-disable react/no-danger */
export const FreeShippingProgresBar: React.FC<IFreeShippingProgressBarProps> = ({ cartFreeShippingProgressBar }) => (
    <div className={styles.freeShipingProgressBar}>
        <div
            dangerouslySetInnerHTML={{
                __html: cartFreeShippingProgressBar,
            }}
        />
    </div>
);
