import React from 'react';
import { ConfigurableConfig } from './configurable-config';
import { BundleConfig } from './bundle-config';
import { IProductConfigData } from './product-config';
import { GiftCardConfig } from './gift-card-config';

interface ISelectConfiguratorProps {
    config: IProductConfigData;
    isQuickView: boolean;
    queryString?: {[key: string]: any};
}

export const SelectConfigurator: React.FC<ISelectConfiguratorProps> = ({
    config,
    isQuickView,
    queryString = {},
}) => {
    switch (config.type) {
        case 'ConfigurableConfig':
            return (
                <ConfigurableConfig
                    config={config}
                    isQuickView={isQuickView}
                    queryString={queryString}
                />
            );
        case 'BundleConfig':
            return <BundleConfig config={config} />;
        case 'GiftCardConfig':
            return <GiftCardConfig config={config} />;
        default:
            return null; // TODO: raise HDIGH
    }
};
