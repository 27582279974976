import React, { useRef } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { IMappedColorSwatchImages, IMappedSelectionsAttribute } from './group-selections-for-swatches';
import { BundleSelectionSwatch } from './bundle-selection-swatch';
import { IBundleSelectionData, SetSelectedBundleSelectionFn } from './bundle-option';
import styles from './style.css';
import { IProductConfigData } from '../product-config';

interface IBundleSelectionSwatchesProps {
    productName: string;
    swatchData: IMappedSelectionsAttribute;
    isChild?: boolean;
    setSelectedBundleSelection(
        ...args: Parameters<SetSelectedBundleSelectionFn>
    ): ReturnType<SetSelectedBundleSelectionFn>;
    selectionQuantity: string;
    setSelectionQuantity(value: string): void;
    selectedColor: string;
    setSelectedColor(value: string): void;
    selectedSize: string;
    setSelectedSize(value: string): void;
    originalSelectionsDict: Map<string, IBundleSelectionData>;
    mappedColorSwatchImages: IMappedColorSwatchImages;
    bundleConfig: IProductConfigData;
    label?: string;
    hideSelectionSwatches?: boolean;
    shouldHideLabel?: boolean;
}

export const BundleSelectionSwatches: React.FC<IBundleSelectionSwatchesProps> = ({
    productName,
    swatchData,
    isChild,
    setSelectedBundleSelection,
    selectionQuantity,
    setSelectionQuantity,
    originalSelectionsDict,
    mappedColorSwatchImages,
    selectedColor,
    setSelectedColor,
    selectedSize,
    setSelectedSize,
    bundleConfig,
    label,
    hideSelectionSwatches,
    shouldHideLabel,
}) => {
    const childSwatchesContainer = useRef<HTMLDivElement>(null);
    const selectedSwatch = (swatchData.attributeLabel === 'color') ? selectedColor : selectedSize;
    const setSelectedSwatch = (swatchData.attributeLabel === 'color') ? setSelectedColor : setSelectedSize;
    const t = usePhraseTranslater();
    let attrLabel = (
        <span className={styles.label}>
            {selectedSwatch ? '' : `${t('Select')} `}
            {swatchData.attributeLabel}
            :
            {selectedSwatch && (
                <span className={styles.labelSelected}>{selectedSwatch}</span>
            )}
        </span>
    );
    if (label) {
        attrLabel = (
            <span className={styles.labelAttr}>
                {label}
                {selectedSwatch && (
                    <span className={styles.labelSelected}>{`: ${selectedSwatch}`}</span>
                )}
            </span>
        );
    }

    return (
        <div
            className={isChild
                ? classes({
                    [styles.swatchesListWrapper]: true,
                    [styles.childWrapper]: true,
                    [styles.validate]: bundleConfig.validation?.current && !selectedSwatch,
                    [styles.labelCnt]: !!label,
                    [styles.hideSelectionSwatches]: !!hideSelectionSwatches,
                })
                : classes({
                    [styles.swatchesListWrapper]: true,
                    [styles.validate]: bundleConfig.validation?.current && !selectedSwatch,
                    [styles.labelCnt]: !!label,
                    [styles.hideSelectionSwatches]: !!hideSelectionSwatches,
                })
            }
        >
            {!shouldHideLabel && (attrLabel)}
            <ul className={styles.swatchesList}>
                {Object.keys(swatchData.values).map((swatchValue) => {
                    const nextSwatchData = swatchData.values[swatchValue];
                    let swatchImage: string | null = null;
                    if (swatchData.attributeLabel === 'color' &&
                        mappedColorSwatchImages[productName] &&
                        mappedColorSwatchImages[productName][swatchValue]) {
                        swatchImage = mappedColorSwatchImages[productName][swatchValue];
                    }

                    return (
                        <BundleSelectionSwatch
                            key={swatchValue}
                            productName={productName}
                            swatchValue={swatchValue}
                            nextSwatchData={nextSwatchData}
                            selectedSwatch={selectedSwatch}
                            setSelectedSwatch={setSelectedSwatch}
                            setSelectedBundleSelection={setSelectedBundleSelection}
                            selectionQuantity={selectionQuantity}
                            setSelectionQuantity={setSelectionQuantity}
                            originalSelectionsDict={originalSelectionsDict}
                            swatchImage={swatchImage}
                            mappedColorSwatchImages={mappedColorSwatchImages}
                            selectedColor={selectedColor}
                            setSelectedColor={setSelectedColor}
                            selectedSize={selectedSize}
                            setSelectedSize={setSelectedSize}
                            bundleConfig={bundleConfig}
                            childSwatchesContainerRef={childSwatchesContainer}
                        />
                    );
                })}
            </ul>
            <div ref={childSwatchesContainer} />
        </div>
    );
};
