import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { ICartItemProps, ICartSummaryData } from 'ui/component/cart/cart-interface';
import { Header } from './header';
import { Content } from './content';
import { FreeShippingProgresBar } from './free-shipping-progress-bar';
import styles from './style.css';

interface CartProps {
    cartItems: ICartItemProps[];
    cartSummaryData: ICartSummaryData;
    cartFreeShippingProgressBar: string;
    toggleMinicart?: () => void;
    isCheckoutPage?: boolean;
}
export const Cart: React.FC<CartProps> = ({
    cartItems,
    cartSummaryData,
    cartFreeShippingProgressBar,
    toggleMinicart,
    isCheckoutPage,
}) => (
    <div
        className={classes(styles.cart, styles.visible)}
    >
        {
            cartItems.length > 0 &&
            <FreeShippingProgresBar cartFreeShippingProgressBar={cartFreeShippingProgressBar} />
        }
        <Header cartItems={cartItems} />
        <Content
            cartItems={cartItems}
            cartSummaryData={cartSummaryData}
            toggleMinicart={toggleMinicart}
            isCheckoutPage={isCheckoutPage}
        />
    </div>
);
