import React, { useContext } from 'react';
import { connectCompare } from '@silkpwa/module/react-component/connect-compare';
import { connectAccount } from '@silkpwa/module/react-component/connect-account';
import { connectRouter } from '@silkpwa/module/react-component/connect-router';
import { useStoreMode } from '@silkpwa/module/store-mode';
import { connectCart } from 'ui/util/gql-connect-cart';
import { AccountIcon } from 'ui/component/header/icons/account-icon';
import { CartIcon } from 'ui/component/header/icons/cart-icon';
import { CompareIcon } from 'ui/component/header/icons/compare-icon';
import { AccountDropdown } from 'ui/component/account-dropdown';
import { SearchIcon } from 'ui/component/header/icons/search-icon';
import { IMobileHeaderDefaultValue, MobileHeaderContext } from 'ui/component/header/mobile-header/mobile-header-context';
import styles from './style.css';

const Icons = ({
    compareItems,
    toggleSearch,
    showSearch,
    accountActions,
    account,
    currentLocation,
    cartItems,
    toggleMinicart,
}) => {
    const mode = useStoreMode();
    const MobileHeaderConfig: IMobileHeaderDefaultValue = useContext(MobileHeaderContext);
    const showCompareIcon = false;

    return (
        <div
            id="customer-account-area"
            data-customer-logged-in={account.isLoggedIn}
            className={styles.icons}
        >
            {!MobileHeaderConfig.disableSearchIcon && showSearch && (
                <div className={styles.noBorder}>
                    <SearchIcon toggleSearch={toggleSearch} />
                </div>
            )}
            {showCompareIcon && compareItems.length > 0 ? (
                <CompareIcon compareItemCount={compareItems.length} />
            ) : null }
            {mode.isRetail ? (
                <AccountIcon account={account} currentLocation={currentLocation}>
                    {open => (
                        <AccountDropdown
                            open={open}
                            accountActions={accountActions}
                            className={styles.accountDropdownPosition}
                        />
                )}
                </AccountIcon>
            ) : null }
            <CartIcon cartItems={cartItems} toggleMinicart={toggleMinicart} top={10} />
        </div>
    );
};

const ConnectedIcons = connectRouter(connectCart(connectAccount(connectCompare(Icons))));

export { ConnectedIcons as Icons };
