import React from 'react';
import { selectCartTotal } from 'ui/util/select-cart-total';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { Count } from '../count';
import styles from './style.css';

interface CartIconProps {
    cartItems: any[];
    toggleMinicart: () => void;
    top?: number;
}

export const CartIcon = ({
    cartItems,
    toggleMinicart,
    top,
}: CartIconProps) => {
    const config = useConfig();
    const currentStoreUrl = config.store_config?.current_store?.url || '/';
    const getUrl = (url: string) => `${currentStoreUrl}${url}`;
    const topPosition = top ? `${top}px` : '25px';
    return (
        <div data-test="cart-icon" className={styles.cartIcon}>
            <a
                href={getUrl('checkout/cart')}
                onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    toggleMinicart();
                }}
            >
                <i className="fa fa-shopping-bag" aria-hidden="true" data-test="testAttribute">
                    <Count
                        dataTest="cart-icon-count"
                        count={selectCartTotal({ cartItems })}
                        size="20px"
                        top={topPosition}
                        right="-15px"
                        fontSize="12px"
                    />
                </i>
            </a>
        </div>
    );
};
