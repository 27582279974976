/* eslint-disable camelcase */
import { gql, TypedDocumentNode } from '@apollo/client';

interface IRatingVote {
    rating_code: string;
    percent: string;
    value: number;
}

export interface IProductReview {
    review_id: number;
    title: string;
    detail: string;
    nickname: string;
    created_at: string;
    rating_votes: IRatingVote[];
    recommend: number;
    location: string;
    verified_buyer: number;
    sizing: number;
    width: number;
    likes: number;
    dislikes: number;
}

export interface IProductReviewsOutput {
    productReviews: {
        items: IProductReview[];
        total: number;
        page: number;
        page_size: number;
    };
}

export interface IProductReviewsInput {
    productId: number;
    page: number;
    sort: string;
    searchTerm: string;
    starFilter: string;
}

export const GET_PRODUCT_REVIEWS: TypedDocumentNode<IProductReviewsOutput, IProductReviewsInput> = gql`
    query GET_PRODUCT_REVIEWS(
        $productId: Int!,
        $page: Int!,
        $sort: String,
        $searchTerm: String,
        $starFilter: String,
    ) {
        productReviews(
            productId: $productId,
            page: $page,
            sort: $sort,
            searchTerm: $searchTerm,
            starFilter: $starFilter,
        ) {
            items {
                review_id
                title
                detail
                nickname
                created_at
                rating_votes {
                    rating_code
                    percent
                    value
                }
                recommend
                location
                verified_buyer
                sizing
                width
                likes
                dislikes
            }
            total
            page
            page_size
        }
    }
`;

export const GET_REVIEW_METRICS: TypedDocumentNode = gql`
    query GET_REVIEW_METRICS($productId: Int!) {
        reviewMetrics(productId: $productId) {
            averageRating
            averageRatingRound
            averageRecommends
            averageSizing
            averageWidth
            reviewsCount
            ratingsCount {
                fiveStar
                fourStar
                threeStar
                twoStar
                oneStar
            }
        }
    }
`;

export const GET_IS_VOTED_BY_CUSTOMER = gql`
    query IsVotedByCustomer($reviewId: String!) {
        reviewHelpfulness(reviewId: $reviewId) {
            vote
        }
    }
`;

export const GET_HOMEPAGE_REVIEWS: TypedDocumentNode = gql`
    query GET_HOMEPAGE_REVIEWS($page: Int) {
        homePageReviews(page: $page) {
            items {
                review_id
                title
                detail
                nickname
                rating_votes {
                    rating_code
                    percent
                    value
                }
            }
            total
            page
            page_size
        }
    }
`;
