type FormatPriceFunction = (price: number) => string;
interface SummaryItem {
    code: string;
    value: number;
}
export const getSummaryItems = (summaryData: SummaryItem[]): SummaryItem[] => summaryData.filter(item => item.code !== 'grand_total');

export const getValue = (formatPrice: FormatPriceFunction, item: { value: number }) => {
    if (item.value < 0) return `-${formatPrice(-item.value)}`;
    return formatPrice(item.value);
};
