import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { DefaultWrap } from '../default-wrap';
import {
    MultiConfigurableQuantity,
} from '../../../product-configurator/product-config/quantity/multi-configurable-quantity';
import styles from './styles.css';
import { IProductConfigData } from '../../../product-configurator/product-config';

export type SwatchOptionType = { [key: string]: any };

export interface ISwatchesProps {
    theme: { [className: string]: string };
    SelectionBehavior: React.ComponentType<any>;
    Swatch: React.ComponentType<any>;
    attribute: { id?: number; label?: string; options: SwatchOptionType[] };
    swatchStyle?: any;
    handleClick?: any;
    Wrap?: any;
    productId?: number;
    isMultiSizeQty?: boolean;
    config?: IProductConfigData;
}

export const Swatches: React.FC<ISwatchesProps> = ({
    theme,
    Wrap,
    SelectionBehavior,
    Swatch,
    attribute,
    swatchStyle,
    handleClick,
    productId,
    isMultiSizeQty,
    config,
}) => {
    const getDataTest = (o: SwatchOptionType) => {
        if (attribute.label) {
            let value = attribute.label.toLowerCase();

            if (o.label) {
                value += `-${o.label}`;
            }

            return value;
        }

        return '';
    };

    const isDisable = (option: SwatchOptionType) => option.disabled && !option.isOutOfStock;

    const children = (
        attribute.options.map(o => (
            <>
                <SelectionBehavior
                    key={o.id}
                    option={o}
                    swatchStyle={swatchStyle}
                    handleClick={handleClick}
                    theme={theme}
                    isLocked={isMultiSizeQty}
                >
                    <Swatch
                        option={o}
                        dataTest={getDataTest(o)}
                        theme={theme}
                        productId={productId}
                    />
                </SelectionBehavior>
                {isMultiSizeQty && (
                    <>
                        <MultiConfigurableQuantity
                            attributeId={attribute.id}
                            optionId={o.id}
                            quantity={config?.quantity}
                            className={classes({ [styles.disabled]: isDisable(o) })}
                        />
                        <div className={styles.divider} />
                    </>
                )}
            </>
        ))
    );

    if (Wrap) {
        return (
            <Wrap>
                {children}
            </Wrap>
        );
    }

    return (
        <DefaultWrap theme={theme}>
            {children}
        </DefaultWrap>
    );
};
