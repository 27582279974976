/* eslint-disable no-console */

const getMessage = timer => `${timer.name} took: ${timer.timeSpan}s`;

export const report = (timer) => {
    if (timer.timeSpan >= timer.warnTime) {
        console.group(getMessage(timer));
        console.error(`${timer.name} should take less than ${timer.warnTime}s`);
        console.groupEnd();
    } else {
        console.groupCollapsed(getMessage(timer));
        console.groupEnd();
    }
};
