import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import EmbroideryIcon from 'assets/images/mockImgs/EmbroideryIcon-2.png';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import styles from './style.css';

interface IEmbroideryProps {
    item: ICartItemProps;
}

export const Embroidery: React.FC<IEmbroideryProps> = ({ item }) => {
    const t = usePhraseTranslater();
    const embroideryItems = item.embroidery_items || [];
    if (!embroideryItems.length) return null;
    return (
        <div className={styles.embWrapper}>
            <p className={styles.label}>{t('With Embroidery')}</p>
            <img className={styles.EmbroideryIcon} src={EmbroideryIcon} alt={t('With Embroidery')} />
        </div>
    );
};
