import { Http } from '@silkpwa/module/util/api/http';
import { ModuleCreator } from '@silkpwa/redux';
import { ICartItem } from '@silkpwa/module/react-component/product-config/base-product';
import {
    getPortalOverrideCategoryId,
} from 'ui/util/override-categories/id-param-resolver';
import { processResult } from './util';

import SilkRestappDataCartCartItemInterface =
    Magento.Definitions.SilkRestappDataCartCartItemInterface;

declare global {
    interface Window {
        dmPt: any;
    }
}
export interface ICart {
    items: Array<any /* TODO: fixme */>;
    summary: Array<{code: string; value: number; title: string}>;
    hasCustomer: boolean;
}

export interface ICartRepository {
    _selectors: { [key: string]: Function };

    addSimpleProduct(productId: string, quantity: number, parentId: string): Promise<any>;

    addBundleProduct(
        productId: number,
        selections: { [key: string]: any },
        quantity: number,
        bundleSelectionsQty: { [key: string]: any }
    ): Promise<any>;

    addGiftCardProduct(
        productId: number,
        selections: { [key: string]: any },
        quantity: number,
    ): Promise<any>;

    getCart(): Promise<ICart>;

    updateItem(itemId: number, quantity: number, item?: SilkRestappDataCartCartItemInterface|ICartItem): Promise<any>;

    removeItem(itemId: number): Promise<any>;

    setProductSelectors(selectors: ICartRepository['_selectors']): void;
}

export const CartRepository = new ModuleCreator({
    inject: ['magentoAPI'],
    create(magentoAPI: Http) {
        return <ICartRepository>{
            addSimpleProduct(productId, quantity, parentId) {
                const pId: number = parseInt((parentId || productId), 10);
                const overrideCategoryId: number|null = getPortalOverrideCategoryId(pId);
                return magentoAPI.post('/app-add-to-cart', {
                    data: {
                        data: {
                            id: productId,
                            qty: quantity,
                            parentId,
                            overrideCategoryId,
                        },
                    },
                });
            },
            addBundleProduct(productId, selections, quantity, bundleSelectionsQty) {
                const overrideCategoryId: number|null = getPortalOverrideCategoryId(productId);
                return magentoAPI.post('/app-add-to-cart', {
                    data: {
                        data: {
                            id: productId,
                            bundle_option: JSON.stringify(selections),
                            bundle_option_qty: JSON.stringify(bundleSelectionsQty),
                            qty: quantity,
                            overrideCategoryId,
                        },
                    },
                });
            },
            addGiftCardProduct(productId, selections, quantity) {
                return magentoAPI.post('/app-add-to-cart', {
                    data: {
                        data: {
                            id: productId,
                            giftcard_option: JSON.stringify(selections),
                            qty: quantity,
                        },
                    },
                });
            },
            getCart() {
                const queryString = new URLSearchParams(window.location.search);
                const cartId = queryString.get('cartId') || '';
                const urlQuery = cartId ? `/app-get-cart?cartId=${cartId}` : '/app-get-cart';
                const cartApiCall = magentoAPI.get(urlQuery).then(processResult(this._selectors));
                magentoAPI.get('/dotdigitalintegration/get-abandoned-cart-data').then((value) => {
                    if (value.customer_identity && typeof window.dmPt === 'function') {
                        window.dmPt('identify', value.customer_identity);
                        window.dmPt('track');
                    }
                });
                return cartApiCall;
            },
            updateItem(itemId, quantity, item?) {
                const { product_id: productId } = item ?? {};
                const pId: number = typeof productId === 'string' ? parseInt(productId, 10) : 0;
                const overrideCategoryId: number|null = getPortalOverrideCategoryId(pId, item);
                return magentoAPI.post('/app-update-cart', {
                    data: {
                        item_id: itemId,
                        qty: quantity,
                        overrideCategoryId,
                    },
                });
            },
            removeItem(itemId: number) {
                return magentoAPI.post('/app-remove-cart-item', {
                    data: {
                        item_id: itemId,
                    },
                });
            },
            setProductSelectors(selectors) {
                this._selectors = selectors;
            },
        };
    },
});
