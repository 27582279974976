import React from 'react';
import { useQuery } from '@apollo/client';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { GET_DYNAMIC_WIDGETS, IDynamicWidget } from 'graphql/cms/dynamic-widgets';
import { FooterLink } from './footer-link/footer-link';

export const FooterLinks = () => {
    const storeConfig = useConfig();
    const { data, error } = useQuery(GET_DYNAMIC_WIDGETS, {
        variables: {
            location: 'FOOTER',
            storeId: storeConfig?.store_config?.current_store?.id,
        },
    });
    if (error) return null;
    if (!data?.getDynamicWidgets?.length) return null;
    return (
        <>
            {
                data && data.getDynamicWidgets.map((block: IDynamicWidget) => (
                    <FooterLink key={block?.title.toString()} html={block?.content.toString()} />
                ))
            }
        </>
    );
};
