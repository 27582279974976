interface Cart {
    items: any[];
    prices: Record<string, any>;
    'billing_address': { uid?: string };
    'shipping_addresses': [
        {
            'selected_shipping_method'?: {
                amount: {
                    value: number;
                };
                'carrier_title': string;
            };
        }
    ];
    'free_shipping_progress_bar': string;
}

interface InputData {
    data: {
        removeItemFromCart?: { cart: Cart };
        updateCartItems?: { cart: Cart };
        cart?: Cart;
    };
}

interface IShippingCharge {
    amount: {
        value: number;
    };
    label: string | null;
}

const getCartData = (inputData: InputData) => {
    const rootData = inputData.data || {};
    return (
        rootData.removeItemFromCart?.cart ||
        rootData.updateCartItems?.cart ||
        rootData.cart || {
            items: [],
            prices: {},
            billing_address: { uid: null },
            shipping_addresses: [
                {
                    selected_shipping_method: {
                        amount: {
                            value: 0,
                        },
                        carrier_title: null,
                    },
                },
            ],
            free_shipping_progress_bar: null,
        }
    );
};

const getItemsCollection = (inputData: InputData): any[] => {
    const cart = getCartData(inputData);
    return cart.items || [];
};

const getPricesCollection = (inputData: InputData): Record<string, any> => {
    const cart = getCartData(inputData);
    return cart.prices || {};
};

const getCustomerBillingAddress = (inputData: InputData): string|null => {
    const cart = getCartData(inputData);
    return cart.billing_address?.uid || null;
};

const getFreeShippingProgressBar = (inputData: InputData): string => {
    const cart = getCartData(inputData);
    return cart?.free_shipping_progress_bar || '';
};

const getShippingCharge = (inputData: InputData): IShippingCharge => {
    const cart = getCartData(inputData);
    const shippingAmount = cart.shipping_addresses?.[0]?.selected_shipping_method?.amount ?? { value: 0 };
    const shippingLabel = cart.shipping_addresses?.[0]?.selected_shipping_method?.carrier_title ?? null;
    return { amount: shippingAmount, label: shippingLabel };
};

export const processResult = (inputData: InputData) => {
    const items = getItemsCollection(inputData);
    const summary = getPricesCollection(inputData);
    const hasCustomer = Boolean(getCustomerBillingAddress(inputData));
    summary.shipping_charge = getShippingCharge(inputData);
    return {
        items,
        summary,
        hasCustomer,
        checkoutDisabled: false,
        freeShippingProgressBar: getFreeShippingProgressBar(inputData),
    };
};
