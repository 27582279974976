import React from 'react';
import { keys } from '@silkpwa/module/util/keys';
import { classes } from '@silkpwa/module/util/classes';
import { CartQuantity } from 'ui/component/cart-quantity';
import styles from './style.css';

const getIncrDecrButtonStyle = (userCanEdit: boolean) => {
    if (userCanEdit) return {};
    return { display: 'none' };
};

export const Quantity: React.FC<{ item: any }> = ({ item }) => (
    <CartQuantity
        qty={item.quantity}
        remove={item.remove}
        setQty={item.setQty}
        simpleProductId={item.product.id}
    >
        {({
            increment,
            decrement,
            qty,
            setQty,
            applyUpdate,
            reset,
        }) => (
            <div className={styles.detailsQty}>
                <button
                    className={classes(styles.faMinus, styles.qtyButton)}
                    style={getIncrDecrButtonStyle(item.userCanEdit)}
                    onClick={() => {
                        decrement();
                    }}
                    type="button"
                />
                <input
                    className={styles.itemQty}
                    disabled={!item.userCanEdit}
                    data-test={`cart-item-qty ${item.sku}`}
                    type="text"
                    value={qty}
                    onClick={e => (e.target as HTMLInputElement).select()}
                    onKeyUp={(e) => {
                        if (e.keyCode === keys.ENTER) {
                            applyUpdate();
                        } else if (e.keyCode === keys.ESCAPE) {
                            reset();
                        }
                    }}
                    onBlur={applyUpdate}
                    onChange={e => setQty(e.target.value)}
                />
                <button
                    style={getIncrDecrButtonStyle(item.userCanEdit)}
                    className={classes(styles.faPlus, styles.qtyButton)}
                    onClick={() => {
                        increment();
                    }}
                    type="button"
                />
            </div>
        )}
    </CartQuantity>
);
