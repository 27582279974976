import { Http } from '@silkpwa/module/util/api/http';
import { ModuleCreator } from '@silkpwa/redux';
import { GraphQlClient } from 'chefworks-theme/src/graphql/base';
import {
    GET_CART,
    GET_CUSTOMER_CART,
    REMOVE_FROM_CART_MUTATION,
    UPDATE_CART_MUTATION,
} from 'chefworks-theme/src/graphql/cart/cart';
// TODO: NEED TO REMOVE THIS LINE
import { getSessionStorageData } from 'chefworks-theme/src/ui/util/session-storage';
import { processResult } from './util';

declare global {
    interface Window {
        dmPt: any;
    }
}
export interface ICart {
    data: {
        cart:
        {
            items: Array<any /* TODO: fixme */>;
            summary: Array<{ code: string; value: number; title: string }>;
            hasCustomer: boolean;
        };
    };
}

export const CartRepository = new ModuleCreator({
    inject: ['magentoAPI'],
    create(magentoAPI: Http) {
        return {
            getCart() {
                const queryString = new URLSearchParams(window.location.search);
                let cartId = queryString.get('cartId') || '';
                // TODO: START: NEED TO REMOVE THIS CODE
                if (!cartId) {
                    cartId = getSessionStorageData('cartId', true);
                }
                if (!cartId) {
                    // Prevents empty cartId api calls
                    return new Promise<any>(() => true).then(processResult);
                }
                const customerToken = getSessionStorageData('customerToken', true);
                const isCustomerLoggedIn = !!customerToken;
                // TODO: END: NEED TO REMOVE THIS CODE
                const cartApiCall = GraphQlClient.query({
                    query: isCustomerLoggedIn ? GET_CUSTOMER_CART : GET_CART,
                    variables: {
                        cartId,
                    },
                }).then(processResult);

                magentoAPI.get('/dotdigitalintegration/get-abandoned-cart-data').then((value) => {
                    if (value.customer_identity && typeof window.dmPt === 'function') {
                        window.dmPt('identify', value.customer_identity);
                        window.dmPt('track');
                    }
                });
                return cartApiCall;
            },
            updateItem(itemId: number, quantity: number) {
                const queryString = new URLSearchParams(window.location.search);
                let cartId = queryString.get('cartId') || '';
                // TODO: START: NEED TO REMOVE THIS CODE
                if (!cartId) {
                    cartId = getSessionStorageData('cartId', true);
                }
                // TODO: END: NEED TO REMOVE THIS CODE
                const cartApiCall = GraphQlClient.mutate({
                    mutation: UPDATE_CART_MUTATION,
                    variables: {
                        input: {
                            cart_id: cartId,
                            cart_items: [{
                                cart_item_id: itemId,
                                quantity: Number(quantity),
                            }],
                        },
                    },
                }).then(processResult);
                return cartApiCall;
            },
            removeItem(itemId: number) {
                const queryString = new URLSearchParams(window.location.search);
                let cartId = queryString.get('cartId') || '';
                // TODO: START: NEED TO REMOVE THIS CODE
                if (!cartId) {
                    cartId = getSessionStorageData('cartId', true);
                }
                const cartApiCall = GraphQlClient.mutate({
                    mutation: REMOVE_FROM_CART_MUTATION,
                    variables: {
                        input: {
                            cart_id: cartId,
                            cart_item_id: itemId,
                        },
                    },
                }).then(processResult);
                return cartApiCall;
            },
        };
    },
});
