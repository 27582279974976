import { ModuleCreator, Duck, Dispatch } from '@silkpwa/redux';
import { ICartItem } from '@silkpwa/module/react-component/product-config/base-product';
import { CART_ITEM_QUANTITY_UPDATED, CART_ITEM_REMOVED } from './events';

export const Cart = new ModuleCreator({
    inject: [
        'ecommerceCart', // TODO: remove REST Api cart dependencies once cart is fully moved to GQL
        'gqlCartRepository',
        'router',
        'account',
        'actionEvents',
        'persist',
        'appEventBus',
    ],
    create(cart, gqlCartRepository, router, account, actionEvents, persist, appEventBus) {
        const initialState = {
            cart: {
                items: [],
                summary: [],
                isCartOpen: true,
            },
        };

        return new Duck({
            name: 'gqlEcommerceCart',
            actionTypes: ['UPDATE_CART', 'CART_VIEW_OPEN_STATUS'],
            construct() {
                persist.persistPath([this.slice, 'cart'], 'keyed');
            },
            reducer(state = initialState, action) {
                switch (action.type) {
                    case this.actionTypes.UPDATE_CART:
                        return {
                            ...state,
                            cart: action.cart,
                        };
                    case this.actionTypes.CART_VIEW_OPEN_STATUS:
                        return {
                            ...state,
                            isCartOpen: !state.isCartOpen,
                        };
                    default:
                        return state;
                }
            },
            methods: {
                publishChange(item, eventType, ...args) {
                    return () => appEventBus.publish(eventType, item, ...args);
                },
            },
            actions: {
                updateCart(cart) {
                    return {
                        type: this.actionTypes.UPDATE_CART,
                        cart,
                    };
                },
                addProduct(productSpec) {
                    return async (dispatch) => {
                        try {
                            await productSpec.addToCart(gqlCartRepository);
                            await dispatch(this.actions.getItems);
                        } catch (e) { /* ignore error */ }
                    };
                },
                removeItem(itemId, item) {
                    return async (dispatch) => {
                        const commitEvent = this.publishChange(
                            item,
                            CART_ITEM_REMOVED,
                            itemId,
                        );

                        try {
                            const cart = await gqlCartRepository.removeItem(itemId);
                            dispatch(this.actions.updateCart(cart));
                            commitEvent();
                        } catch (e) { /* ignore error */ }
                    };
                },
                updateItem(itemId, quantity, item: ICartItem) {
                    return async (dispatch) => {
                        const commitEvent = this.publishChange(
                            item,
                            CART_ITEM_QUANTITY_UPDATED,
                            itemId,
                            quantity,
                        );

                        try {
                            const cart = await gqlCartRepository.updateItem(itemId, quantity, item);
                            dispatch(this.actions.updateCart(cart));
                            commitEvent();
                        } catch (e) { /* ignore error */ }
                    };
                },
                async getItems(dispatch: Dispatch) {
                    const cart = await gqlCartRepository.getCart();
                    dispatch(this.actions.updateCart(cart));
                },
                toggleMinicart(dispatch: Dispatch) {
                    dispatch({ type: this.actionTypes.CART_VIEW_OPEN_STATUS });
                },
            },
            selectors: {
                getCart(state) {
                    return this.select(state).cart;
                },
                getCartItems(state) {
                    return this.selectors.getCart(state).items;
                },
                getCartQuantity(state) {
                    return this.selectors.getCart(state).items.length;
                },
                getCartSummary(state) {
                    return this.selectors.getCart(state).summary;
                },
                getCartTotalQuantity(state) {
                    const { items } = this.selectors.getCart(state);
                    return items.reduce((acc, x) => acc + x.qty, 0);
                },
                getCartCheckoutDisabled(state) {
                    return this.selectors.getCart(state).checkoutDisabled;
                },
                getCartFreeShipppingProgressBar(state) {
                    return this.selectors.getCart(state).freeShippingProgressBar;
                },
                getModelCartStatus(state) {
                    return this.select(state).isCartOpen;
                },
            },
            initialize(store) {
                const getCart = () => store.dispatch(this.actions.getItems);
                const resetCart = () => store.dispatch(this.actions.updateCart(initialState.cart));

                actionEvents.addEventListener(
                    cart.actionTypes.UPDATE_CART,
                    getCart,
                );

                actionEvents.addEventListener(
                    account.actionTypes.USER_LOGGED_IN,
                    getCart,
                );

                actionEvents.addEventListener(
                    account.actionTypes.USER_LOGGED_OUT,
                    resetCart,
                );

                actionEvents.addEventListener(
                    account.actionTypes.USER_ACTUALLY_LOGGED_OUT,
                    resetCart,
                );

                router.addHandler('cart', (route) => {
                    route.progress(1);
                    getCart();
                });
                router.handle(async (route) => {
                    await getCart();
                    route.done();
                });
            },
        });
    },
});
