import React from 'react';
import { IProductAttribute } from '@silkpwa/module/react-component/product-config/util';
import { IProductConfigData } from 'ui/component/product-configurator/product-config';
import {
    ITextSwatchesProps,
    IVisualSwatchesProps,
} from 'ui/component/product-configurator/product-config/config-attribute/swatches';
import { SingleConfigurableQuantity } from 'ui/component/product-configurator/product-config/quantity';

interface ISingleSizeProps {
    Component: React.FC<IVisualSwatchesProps | ITextSwatchesProps>;
    attribute: IProductAttribute;
    config: IProductConfigData;
    queryString?: {[key: string]: any};
}

export const SingleSize: React.FC<ISingleSizeProps> = ({
    Component,
    attribute,
    config,
    queryString = {},
}) => (
    <>
        <Component attribute={attribute} />
        {config.simpleProduct.maxQuantity !== 1 && (
            <SingleConfigurableQuantity
                quantity={config.quantity}
                simpleProduct={config.simpleProduct}
                queryString={queryString}
            />
        )}
    </>
);
