import { useContainer } from '@silkpwa/redux';
import { useSelector } from 'react-redux';

export const useLocation = () => {
    const router = useContainer<any>('router');
    const location: any = useSelector(router.selectors.location);
    return location;
};

export const useResourceType = () => {
    const router = useContainer<any>('router');
    const getResource: any = useSelector(router.selectors.getCurrentResourceInfo);
    return getResource;
};
