import { connect } from '@silkpwa/redux';
import { ICartItem } from '@silkpwa/module/react-component/product-config/base-product';
import { ICartItemProps } from 'ui/component/cart/cart-interface';

export const connectCart = connect({
    using: ['gqlEcommerceCart'],
    mapStateToProps: module => state => ({
        cartItems: module.selectors.getCartItems(state),
        cartQty: module.selectors.getCartQuantity(state),
        cartTotalQty: module.selectors.getCartTotalQuantity(state),
        cartSummaryData: module.selectors.getCartSummary(state),
        cartCheckoutDisabled: module.selectors.getCartCheckoutDisabled(state),
        cartFreeShippingProgressBar: module.selectors.getCartFreeShipppingProgressBar(state),
        isCartOpen: module.selectors.getModelCartStatus(state),
    }),
    mapDispatchToProps: module => dispatch => ({
        getCartList: () => dispatch(module.actions.getItems),
        setCartItemQty: (
            itemId: number,
            qty: number,
            item?: ICartItem,
        ) => dispatch(module.actions.updateItem(itemId, qty, item)),
        removeCartItem: (itemId: number, item: ICartItemProps) => dispatch(module.actions.removeItem(itemId, item)),
        toggleMinicart: () => dispatch(module.actions.toggleMinicart),
    }),
});
