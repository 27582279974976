export enum EProductStockStatus {
    IN_STOCK = 'IN_STOCK',
    OUT_OF_STOCK = 'OUT_OF_STOCK'
}

export interface IProduct {
    sku: string;
    'stock_status': EProductStockStatus;
    id: number;
    name: string;
    'url_key': string;
    image: {
        url: string;
        label: string;
    };
    'small_image': {
        url: string;
        label: string;
    };
    'thumbnail': {
        url: string;
        label: string;
    };

}
export interface ICustomizableOptions {
    label: string;
    'option_code': string;
    values?: Array<{ value: string }>;
    '__typename': string;
}

export interface IBundleOptions {
    values?: Array<{ quantity: number; sku: string}>;
}

export interface IConfigurableOptions {
    sku: string;
    'option_label': string;
    'option_code': string;
    'value_label': string;
    '__typename': string;
}

interface IConfiguredVariant {
    sku: string;
    thumbnail: {
        label: string;
        url: string;
    };
}

export interface ICartItemProps {
    'cw_alternation_type'?: string;
    'customizable_options'?: Array<ICustomizableOptions>;
    'configurable_options'?: Array<IConfigurableOptions>;
    'bundle_options'?: Array<IBundleOptions>;
    id: number;
    name: string;
    quantity: number;
    embroideryPrice: number;
    remove: () => void;
    setQty: (qty: string) => void;
    userCanEdit: boolean;
    'embroidery_items'?: ICartItemProps[];
    'loyalty_message'?: string;
    'embroidery_item'?: number;
    prices?: {
        'row_total': {
            currency: string;
            value: number;
        };
    };
    product: IProduct;
    'configured_variant'?: IConfiguredVariant;
    'liquid_pixel_images'?: [{
        face: string;
        regular: string;
        large: string;
    }];
    __typename?: string;
}

export interface ICartItemsProps {
    item: {
        'embroidery_items': ICartItemProps[];
        embroideryPrice?: number;
        product: {
            id?: number;
            sku: string;
        };
    };
}

interface IPriceType {
    value: number;
    currency: string;
    '__typename': string;
}

export interface IRawTotals {
    code: string;
}

interface IDiscounts {
    label: string;
    amount: {
        value: number;
    };
}

export interface ICartSummaryData {
    'subtotal_excluding_tax': IPriceType;
    'subtotal_including_tax': IPriceType;
    'grand_total': IPriceType;
    'tax_total': {
        amount: IPriceType;
    };
    'shipping_charge'?: {
        amount: IPriceType;
        label: string;
    };
    'raw_totals': IRawTotals[];
    discounts: IDiscounts[];
}

export interface IRewardsInfo {
    'notification_message': string;
}
