import { Location } from 'history';
import { LocationArgType } from '@silkpwa/module/util/location-to-string';

import ICfur = Magento.Definitions.ChefworksCfUrDataCfurInterface;
import ICfurData = Magento.Definitions.ChefworksCfUrDataCfurInfoInterface;

export interface IAppliedCfur {
    [key: string]: ICfur|undefined;
}

export interface IQueryParams {
    [key: string]: string;
}

export interface ICfurState {
    originPath?: string;
    currentPath?: string;
    queryParams?: IQueryParams;
    latestAppliedCfur: IAppliedCfur;
}

export interface ExtendedLocation extends Location {
    pathname: string;
    isRedirect?: boolean;
    queryParams?: IQueryParams;
}

export const cfurState: ICfurState = {
    originPath: undefined,
    currentPath: undefined,
    queryParams: undefined,
    latestAppliedCfur: {},
};

export function getQueryParams(search: string): IQueryParams {
    const queryParamsStrings: string[] = search.split('&');
    const queryParams: IQueryParams = {};
    queryParamsStrings.forEach((paramString: string) => {
        const parts: string[] = paramString.split('=');
        // eslint-disable-next-line prefer-destructuring
        queryParams[parts[0]] = parts[1];
    });
    return queryParams;
}

export function excludeFromSearch(targetSearch: string, locationSearch: string): string {
    if (!targetSearch) {
        return locationSearch;
    }

    const targetSearchParams = getQueryParams(targetSearch);
    const locationSearchParams = getQueryParams(locationSearch);
    let filteredSearch = '?';
    Object.keys(locationSearchParams).forEach((key: string) => {
        const targetValues = targetSearchParams[key]?.split(',') ?? [];
        const locationValues = locationSearchParams[key].split(',') ?? [];
        const newLocationValues = locationValues.length && targetValues.length
            ? locationValues.filter((value: string) => !targetValues.includes(value))
            : locationValues;
        const values = newLocationValues.length ? newLocationValues.join(',') : '';
        filteredSearch += (values.length ? `${key}=${values}&` : '');
    });

    if (filteredSearch === '?') {
        return '';
    }

    const lastIndex = filteredSearch.length - 1;
    return filteredSearch.substring(lastIndex, 1);
}

export function processLocation(
    location: LocationArgType,
    data: ICfurData,
    setAppliedCfur: Function,
): ExtendedLocation {
    const newLocation: ExtendedLocation = location;
    const { originPath, currentPath } = cfurState;
    const cfurByRequest: ICfur|undefined = data.cfur_by_request;
    const cfurByTarget: ICfur|undefined = data.cfur_by_target;

    let newCurrentPath = currentPath;
    let targetPath = '';
    let currentCfur: ICfur|undefined;

    if (!originPath) {
        currentCfur = cfurByRequest || cfurByTarget;
    } else {
        currentCfur = cfurByTarget || cfurByRequest;
    }

    if (currentCfur) {
        targetPath = currentCfur.target_path;
        newCurrentPath = `/${currentCfur.request_path}`;
        newLocation.pathname = newCurrentPath;
    }

    const targetSearch: string = targetPath.split('?')[1] ?? '';
    const locationSearch: string = newLocation.search.split('?')[1] ?? '';
    newLocation.search = !newLocation.search ? '' : excludeFromSearch(targetSearch, locationSearch);

    const queryParams = targetSearch ? getQueryParams(targetSearch) : {};
    if (Object.keys(queryParams).length) {
        newLocation.queryParams = queryParams;
    }

    const newOriginPath = newCurrentPath?.split('/')[1] ?? '';

    cfurState.originPath = newOriginPath ? `/${newOriginPath}` : `${location.pathname}`;
    cfurState.currentPath = newCurrentPath;
    cfurState.queryParams = queryParams;
    if (newCurrentPath) {
        cfurState.latestAppliedCfur[newCurrentPath] = currentCfur;
    }

    setAppliedCfur(currentCfur);

    return newLocation;
}

export function shouldSearchOrigin(search: string): boolean {
    const { queryParams } = cfurState;
    const cleanSearch: string = search.split('?')[1] ?? '';
    return Boolean(queryParams && Object.keys(queryParams).length === 1 && !cleanSearch);
}

export function isQueryParamNotComplete(search: string): boolean {
    const { queryParams } = cfurState;
    const cleanSearch: string = search.split('?')[1] ?? '';
    if (!(queryParams && cleanSearch)) {
        return false;
    }

    let result = false;
    const searchParams = getQueryParams(cleanSearch);

    Object.keys(queryParams).forEach((key: string) => {
        const queryParamValues = `${queryParams[key]}`;
        const searchParamsValues = `${searchParams[key]}`;
        const queryParamArray = queryParamValues ? queryParamValues.split(',') : [];
        const searchParamsArray = searchParamsValues ? searchParamsValues.split(',') : [];
        if (searchParamsArray.length) {
            queryParamArray.forEach((paramValue) => {
                if (!searchParamsArray.includes(paramValue)) {
                    result = true;
                }
            });
        }
    });

    return result;
}

export function restoreOrigin(location: LocationArgType): ExtendedLocation {
    const { originPath } = cfurState;

    const newLocation: ExtendedLocation = location;

    newLocation.pathname = originPath ?? location.pathname;
    newLocation.queryParams = undefined;

    cfurState.originPath = undefined;
    cfurState.queryParams = undefined;

    return newLocation;
}
