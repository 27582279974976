import React from 'react';
import { useContainerHook } from '@silkpwa/redux';
import { IUsePriceFormatter } from '@silkpwa/module/ecommerce-catalog/config';
import { classes } from '@silkpwa/module/util/classes';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import styles from './style.css';

interface IItemPriceProps {
    item: ICartItemProps;
    toggleMinicart?: () => void;
}
export const ItemPrice: React.FC<IItemPriceProps> = ({ item }) => {
    const formatPrice = useContainerHook<IUsePriceFormatter>('usePriceFormatter');
    const itemPrice = parseFloat(String(item.prices?.row_total?.value || 0));
    return (
        <span className={classes(styles.priceExcludingTax, 'weglot-exclude')}>
            {formatPrice(itemPrice + item.embroideryPrice)}
        </span>
    );
};
