import React from 'react';
import { IProductAttribute } from '@silkpwa/module/react-component/product-config/util';
import { IProductConfigData } from 'ui/component/product-configurator/product-config';
import {
    ITextSwatchesProps,
    IVisualSwatchesProps,
} from 'ui/component/product-configurator/product-config/config-attribute/swatches';
import styles from './style.css';

interface IMultiSizeProps {
    Component: React.FC<IVisualSwatchesProps | ITextSwatchesProps>;
    attribute: IProductAttribute;
    config: IProductConfigData;
}

export const MultiSize: React.FC<IMultiSizeProps> = ({
    Component,
    attribute,
    config,
}) => (
    <div className={styles.multiSizeWrapper}>
        <Component attribute={attribute} isMultiSizeQty config={config} />
    </div>
);
