import React from 'react';
import { generateKey } from 'chefworks-theme/src/ui/util/generate-key';
import styles from './style.css';

import Cookie = Magento.Definitions.ChefworksPrCookieConsentConfigSettingsCookieInterface;

export class CookieItem extends React.Component<{
    t: any;
    cookies: Cookie[];
    currentCategoryName: string;
}> {
    private readonly t: any;

    private readonly cookies: [];

    private readonly currentCategoryName: string;

    constructor(props: any) {
        super(props);

        this.t = props.t;
        this.cookies = props.cookies;
        this.currentCategoryName = props.currentCategoryName;
    }

    getCookies(): Cookie[] {
        return this.cookies;
    }

    getCurrentCategoryName(): string {
        return this.currentCategoryName;
    }

    getTranslator(): any {
        return this.t;
    }

    render() {
        const t = this.getTranslator();
        const cookies: Cookie[] = this.getCookies();
        if (!cookies) {
            return '';
        }

        return (
            cookies.map((cookie: Cookie, index: number) => (
                <div
                    className={styles.prCookieGroupItem}
                    data-cookie-group-item-index={index}
                    key={generateKey([index], 'cookie')}
                >
                    <div className={styles.prCookieGroupItemRow}>
                        <span className={styles.prCookieGroupItemProperty}>{t('Name')}</span>
                        <span className={styles.prCookieGroupItemPropertyValue} title={cookie.name}>
                            {cookie.name}
                        </span>
                    </div>
                    <div className={styles.prCookieGroupItemRow}>
                        <span className={styles.prCookieGroupItemProperty}>{t('Domain')}</span>
                        <span className={styles.prCookieGroupItemPropertyValue} title={cookie.domain_label}>
                            {cookie.domain_label}
                        </span>
                    </div>
                    <div className={styles.prCookieGroupItemRow}>
                        <span className={styles.prCookieGroupItemProperty}>{t('Duration')}</span>
                        <span className={styles.prCookieGroupItemPropertyValue}>
                            {cookie.duration_label}
                        </span>
                    </div>
                    <div className={styles.prCookieGroupItemRow}>
                        <span className={styles.prCookieGroupItemProperty}>{t('Type')}</span>
                        <span className={styles.prCookieGroupItemPropertyValue}>
                            {cookie.type_label}
                        </span>
                    </div>
                    <div className={styles.prCookieGroupItemRow}>
                        <span className={styles.prCookieGroupItemProperty}>{t('Category')}</span>
                        <span className={styles.prCookieGroupItemPropertyValue}>
                            {this.getCurrentCategoryName()}
                        </span>
                    </div>
                    <div className={styles.prCookieGroupItemRow}>
                        <span className={styles.prCookieGroupItemProperty}>{t('Description')}</span>
                        <span className={styles.prCookieGroupItemPropertyValue}>
                            {cookie.description}
                        </span>
                    </div>
                </div>
            ))
        );
    }
}
