import { IProductConfigData } from 'ui/component/product-configurator/product-config';
import { isVirtualDoubleProduct } from 'ui/component/product-configurator/product-config/bundle-config/util';
import {
    IBundleOptionData,
    IBundleSelectionData,
} from 'ui/component/product-configurator/product-config/bundle-config/bundle-option';
import { trimEnd, trimStart } from 'lodash';
import { getOptionValue } from 'ui/util/get-option-value';
import { getLocalMediaURL } from '../util/get-local-media-url';

import ICoreConfigInfo = Magento.Definitions.SilkRestappDataConfigInfoInterface;

export interface ILiquidPixelBaseConfigObj {
    baseUrl: string;
    scaleFactor: number;
    bundleID: string;
    prodID: string;
    prodColor: string;
    colorize: string;
    prodView: string;
    previewSize: string;
    seed: string;
    call: string;
    sink: string;
}

interface ILiquidPixelBaseConfigArrayObj {
    bundleID: string[];
    prodID: string[];
    prodColor: string[];
    colorize: string[];
}

interface IProductAttribute {
    code: string;
    label: string;
    value: any;
    'backend_value': any;
}

interface IProductSelectionColorize {
    [key: number]: {
        isColorize: boolean;
        isProductColorize: boolean;
        colorizeSkus: Array<string>;
        virtualDoubleSkus: Array<string>;
    };
}

interface IProductSelectionIdentifier {
    [key: number]: {
        identifier: string;
    };
}

interface ISelectedProducts {
    [key: number]: (IBundleSelectionData | IConfigurableSelectionData | IDummySelectionData)[];
}

interface IUsedProductAttribute {
    enableLiquidPixelGallery: boolean;
    liquidPixelGalleryView: string[];
    liquidPixelSeed: string;
    liquidPixelDownloadedImages: boolean;
    liquidPixelColorizeProductSkus: string[];
    liquidPixelNonColorizeProductSkus: string[];
}

interface ILiquidPixelConfigProps {
    productConfig: IProductConfigData;
    coreConfig: ICoreConfigInfo;
}

interface IConfigurableSelectionData {
    'type_id': string;
    sku: string;
    color: string;
}

enum EProductType {
    'dummy' = 'dummy',
    'empty' = 'empty',
}

interface IDummySelectionData extends IConfigurableSelectionData {
    'type_id': EProductType;
}

export interface ILiquidPixelImage {
    type: 'image';
    'product_id': string;
    thumb: string;
    full: string;
    image: string;
    'is_thumbnail'?: number;
}

interface ILiquidPixelBase {
    getAllAvailableImages: () => ILiquidPixelImage[] | [];
    getConfig: () => ILiquidPixelBaseConfigObj;
}

export class LiquidPixelBase implements ILiquidPixelBase {
    protected config: ILiquidPixelBaseConfigObj;

    protected configArray: ILiquidPixelBaseConfigArrayObj;

    protected selectedProducts: ISelectedProducts = {};

    protected productAttribute: IUsedProductAttribute = {
        enableLiquidPixelGallery: false,
        liquidPixelGalleryView: [],
        liquidPixelSeed: '001',
        liquidPixelDownloadedImages: false,
        liquidPixelColorizeProductSkus: [],
        liquidPixelNonColorizeProductSkus: [],
    };

    protected productSku: string;

    protected productType: string;

    protected productSelectionColorize: IProductSelectionColorize = {};

    protected productSelectionIdentifier: IProductSelectionIdentifier = {};

    private readonly neutralColor: string = 'NEUTRAL';

    // when product is not colorize and no color is selected colorize should have NA
    private readonly naColor: string = 'NA';

    private readonly nullSku: string = 'NULL';

    /* FALSE value will prevent adding NULL to chain when no selection has been made for that product group */
    private readonly allowEmptyProductSelection: boolean = false;

    private readonly enableLiquidPixelGallery: string = 'enable_liquid_pixel_gallery';

    private readonly liquidPixelGalleryView: string = 'liquid_pixel_gallery_view';

    private readonly liquidPixelSeed: string = 'liquid_pixel_seed';

    private readonly liquidPixelDownloadedImages: string = 'liquid_pixel_downloaded_images';

    private readonly liquidPixelColorizeProductSkus: string = 'liquid_pixel_colorize_product_skus';

    private readonly liquidPixelNonColorizeProductSkus: string = 'liquid_pixel_non_colorize_product_skus';

    private readonly props: ILiquidPixelConfigProps;

    public constructor(props: ILiquidPixelConfigProps) {
        this.props = props;
        this.initConfig();
        this.setSelectedProducts();
        this.setProductsSelectionsColorize();
        this.setProductsSelectionsIdentifier();
        this.generateLiquidPixelParam();
    }

    private static extractColorCode(productColor: string) {
        const matches = productColor.match(/\(([\w]+)\)/);
        if (!matches || matches.length < 2) {
            return productColor;
        }

        return matches[1];
    }

    private static getEncodedUrlImages(images: ILiquidPixelImage[]): ILiquidPixelImage[] {
        const encodedImages: ILiquidPixelImage[] = [];
        images.forEach((imageObject: ILiquidPixelImage) => {
            const {
                thumb, full, image, ...rest
            } = imageObject;
            encodedImages.push({
                thumb: encodeURI(thumb),
                full: encodeURI(full),
                image: encodeURI(image),
                ...rest,
            });
        });
        return encodedImages;
    }

    public getAllAvailableImages(): ILiquidPixelImage[] {
        if (
            !this.productAttribute.enableLiquidPixelGallery ||
            !this.isPossibleToGenerateUrl() ||
            !this.productAttribute.liquidPixelGalleryView?.length
        ) {
            return [];
        }

        try {
            return this.prepareImagesByView();
        } catch (e) {
            return [];
        }
    }

    public getConfig(): ILiquidPixelBaseConfigObj {
        return this.config;
    }

    private prepareImagesByView(): ILiquidPixelImage[] {
        const images: ILiquidPixelImage[] = [];
        const previewUrlSetParam = Object.entries(this.config).map(([key, value]) => {
            if (!['call', 'sink', 'seed', 'baseUrl'].includes(key) && value !== '') {
                return `${key}[${value}]`;
            }
            return '';
        }).filter(Boolean).join(',');
        const previewUrlGlobalParam = Object.entries(this.config).map(([key, value]) => {
            if (['call', 'sink', 'seed'].includes(key)) {
                return `${key}=${value}`;
            }
            return '';
        }).filter(Boolean).join('&');

        this.productAttribute.liquidPixelGalleryView.forEach((prodView) => {
            const previewThumbParam = [previewUrlSetParam, `prodView[${prodView}]`, 'previewSize[small]'].join(',');
            const previewFullParam = [previewUrlSetParam, `prodView[${prodView}]`, 'previewSize[medium]'].join(',');
            const previewImageParam = [previewUrlSetParam, `prodView[${prodView}]`, 'previewSize[medium]'].join(',');
            const img: ILiquidPixelImage = {
                type: 'image',
                product_id: this.props.productConfig?.product?.product_id,
                thumb: this.config.baseUrl + [previewThumbParam, previewUrlGlobalParam].join('&'),
                full: this.config.baseUrl + [previewFullParam, previewUrlGlobalParam].join('&'),
                image: this.config.baseUrl + [previewImageParam, previewUrlGlobalParam].join('&'),
            };
            images.push(img);
        });
        if (this.productAttribute.liquidPixelDownloadedImages) {
            return this.convertToLocalMediaUrl(images);
        }
        return LiquidPixelBase.getEncodedUrlImages(images);
    }

    private convertToLocalMediaUrl(images: ILiquidPixelImage[]): ILiquidPixelImage[] {
        const sku = this.productSku;
        const url = this.props.coreConfig?.store_config?.current_store?.url ?? window.location.origin;
        const mediaDirectory = this.props.coreConfig.extension_attributes?.liquid_pixel_media_directory ?? '/media/catalog/liquidpixelchain/';
        const pathToMedia = `${trimEnd(url, '/')}/${trimEnd(trimStart(mediaDirectory, '/'), '/')}/${sku}/`;
        const convertedImages: ILiquidPixelImage[] = [];
        images.forEach((imageObject: ILiquidPixelImage) => {
            const {
                thumb, full, image, ...rest
            } = imageObject;
            convertedImages.push({
                thumb: getLocalMediaURL(thumb, pathToMedia),
                full: getLocalMediaURL(full, pathToMedia),
                image: getLocalMediaURL(image, pathToMedia),
                ...rest,
            });
        });
        return convertedImages;
    }

    private isColorizeProductSelected = (
        optionId: number,
        product: IBundleSelectionData | IConfigurableSelectionData | IDummySelectionData | null = null,
    ): boolean => {
        if (this.productSelectionColorize[optionId]?.isColorize ||
            this.productSelectionColorize[optionId]?.isProductColorize
        ) {
            const productGroup: IBundleSelectionData[] | IConfigurableSelectionData[] | IDummySelectionData[] =
                this.selectedProducts[optionId];
            const { colorizeSkus } = this.productSelectionColorize[optionId];
            if (product) {
                return !!colorizeSkus.find((doubleSkuOrColorizeSku) => {
                    if (product.type_id in EProductType) {
                        return product.sku.startsWith(doubleSkuOrColorizeSku);
                    }
                    return product.sku === doubleSkuOrColorizeSku;
                });
            }
            return !!productGroup.find(
                selectedProduct => !!colorizeSkus.find((doubleSkuOrColorizeSku) => {
                    if (selectedProduct.type_id in EProductType) {
                        return selectedProduct.sku.startsWith(doubleSkuOrColorizeSku);
                    }
                    return selectedProduct.sku === doubleSkuOrColorizeSku;
                }),
            );
        }
        return false;
    };

    private generateLiquidPixelParam() {
        if (!this.isPossibleToGenerateUrl()) {
            return;
        }
        if (this.selectedProducts && Object.keys(this.selectedProducts).length > 0) {
            Object.keys(this.selectedProducts).forEach((id) => {
                const productGroup: IBundleSelectionData[] | IConfigurableSelectionData[] | IDummySelectionData[] =
                    this.selectedProducts[Number(id)];
                if (productGroup.length === 1) {
                    const isProductColorize = this.isColorizeProductSelected(Number(id), productGroup[0]);
                    this.setNormalProductOptions(Number(id), productGroup[0], isProductColorize);
                } else if (productGroup.length > 1) {
                    this.setVirtualDoubleProductOptions(Number(id), productGroup);
                }
            });
        }
    }

    /**
     * Check if options are selected or not
     */
    private isPossibleToGenerateUrl() {
        const { productConfig } = this.props;
        return (productConfig.selections &&
            Object.keys(productConfig.selections).length &&
            !Object.values(productConfig.selections).some(value => value === '' || (Array.isArray(value) && value.length === 0))) ||
            (productConfig.selectedProductNames && Object.keys(productConfig.selectedProductNames).length);
    }

    /**
     * Initialize liquid pixel base configuration
     */
    private initConfig() {
        this.initProductAttribute();
        const { coreConfig } = this.props;
        this.config = {
            baseUrl: coreConfig.extension_attributes?.liquid_pixel_base_url ?? '',
            scaleFactor: 3,
            bundleID: '',
            prodID: '',
            prodColor: '',
            prodView: '',
            seed: this.productAttribute.liquidPixelSeed,
            call: coreConfig.extension_attributes?.liquid_pixel_call ?? 'url[file:main]',
            sink: coreConfig.extension_attributes?.liquid_pixel_sink ?? 'format[jpg]',
            colorize: '',
            previewSize: '',
        };

        this.configArray = {
            bundleID: [],
            prodID: [],
            prodColor: [],
            colorize: [],
        };
        this.productType = this.props.productConfig?.product?.type ?? '';
        this.selectedProducts = [];
        this.productSelectionColorize = {};
        this.productSku = this.props.productConfig?.product?.sku;
    }

    /**
     * Fetch all the required product attributes
     */
    private initProductAttribute() {
        const attributes = this.props.productConfig?.product?.attributes || {};

        attributes.forEach(({ code, backend_value: backendValue }: IProductAttribute) => {
            if (code === this.enableLiquidPixelGallery) {
                this.productAttribute.enableLiquidPixelGallery = Boolean(Number(backendValue));
            } else if (code === this.liquidPixelGalleryView && backendValue) {
                this.productAttribute.liquidPixelGalleryView = backendValue.split(',');
            } else if (code === this.liquidPixelSeed && backendValue) {
                this.productAttribute.liquidPixelSeed = backendValue;
            } else if (code === this.liquidPixelDownloadedImages) {
                this.productAttribute.liquidPixelDownloadedImages = Boolean(Number(backendValue));
            } else if (code === this.liquidPixelColorizeProductSkus && backendValue) {
                this.productAttribute.liquidPixelColorizeProductSkus = backendValue.split(',');
            } else if (code === this.liquidPixelNonColorizeProductSkus && backendValue) {
                this.productAttribute.liquidPixelNonColorizeProductSkus = backendValue.split(',');
            }
        });
    }

    // products without any colorization or product without any double strap options
    private setNormalProductOptions(
        optionId: number,
        product: IBundleSelectionData | IConfigurableSelectionData | IDummySelectionData,
        shouldColorize = false,
    ) {
        const skuDelimiter = '|';
        const colorizeDelimiter = '|';
        const identifier = this.productSelectionIdentifier[optionId]?.identifier;
        const identifierWithDelimiter = identifier ? `${identifier}_` : '';
        const color = LiquidPixelBase.extractColorCode(product.color);
        const sku = color ? product.sku.split(color)[0] || product.sku : product.sku;
        if (this.isBundleProduct()) {
            // When product is single strap or double strap then only we need to add non colorize single strap delimiter -S
            // e.g. Suspender Apron Strap does not offer double straps and should not include (-S) for that product,
            const { virtualDoubleSkus, isColorize: isProductGroupColorize } = this.productSelectionColorize[optionId];
            const isVirtualDoubleProductGroup = !!virtualDoubleSkus.find(
                virtualDoubleSku => sku === virtualDoubleSku || sku.startsWith(virtualDoubleSku),
            );
            const NonColorizeSingleWithDelimiter = (!shouldColorize && isVirtualDoubleProductGroup && isProductGroupColorize) ? '-S' : '';

            this.config.bundleID = this.config.bundleID ? `${this.config.bundleID}${skuDelimiter}${identifierWithDelimiter}${sku}${NonColorizeSingleWithDelimiter}` : `${sku}${NonColorizeSingleWithDelimiter}`;
            this.configArray.bundleID.push(`${identifierWithDelimiter}${sku}${NonColorizeSingleWithDelimiter}`);
        } else {
            this.config.prodID = this.config.prodID ? `${this.config.prodID}${skuDelimiter}${sku}` : `${sku}`;
            this.configArray.prodID.push(`${sku}`);
        }
        if (shouldColorize) {
            this.config.colorize = this.config.colorize ? `${this.config.colorize}${colorizeDelimiter}${color}` : color;
            this.configArray.colorize.push(`${color}`);
            this.setProductColor(`${this.neutralColor}`);
        } else {
            this.config.colorize = this.config.colorize ? `${this.config.colorize}${colorizeDelimiter}${this.naColor}` : this.naColor;
            this.configArray.colorize.push(`${this.naColor}`);
            this.setProductColor(`${color}`);
        }
    }

    private setVirtualDoubleProductOptions(
        optionId: number,
        productGroup: IBundleSelectionData[] | IConfigurableSelectionData[] | IDummySelectionData[],
    ) {
        if (!this.isBundleProduct()) {
            return;
        }
        const [firstProduct, secondProduct] = productGroup;
        const firstProductIsColorize = this.isColorizeProductSelected(optionId, firstProduct);
        const secondProductIsColorize = this.isColorizeProductSelected(optionId, secondProduct);
        const extractProductColorSku = (
            product: IBundleSelectionData | IConfigurableSelectionData | IDummySelectionData,
        ) => {
            const color = LiquidPixelBase.extractColorCode(product.color);
            const sku = color ? product.sku.split(color)[0] || product.sku : product.sku;
            return {
                color,
                sku,
            };
        };

        let bundleId = '';
        let colorize = '';
        let prodColor = '';
        const skuDelimiter = '-';
        const colorizeDelimiter = '|';
        const identifier = this.productSelectionIdentifier[optionId]?.identifier;
        const identifierWithDelimiter = identifier ? `${identifier}_` : '';
        const { color: firstColor, sku: firstSku } = extractProductColorSku(firstProduct);
        const { color: secondColor, sku: secondSku } = extractProductColorSku(secondProduct);
        // All four combination for double strap product is separate out to make simplify the logic
        // TODO: once chain working as expected we can reduce the code foot print.
        if (firstProductIsColorize && secondProductIsColorize) {
            // colorize - colorize
            const skuDelimiter = '-';
            const colorizeDelimiter = '-';
            bundleId = `${identifierWithDelimiter}${firstSku}${skuDelimiter}${secondSku}`;
            prodColor = `${this.neutralColor}`;
            colorize = `${firstColor}${colorizeDelimiter}${secondColor}`;
        } else if (!firstProductIsColorize && !secondProductIsColorize) {
            // non colorize - colorize
            bundleId = `${identifierWithDelimiter}${firstSku}${skuDelimiter}${secondSku}${skuDelimiter}L`;
            bundleId += `|${identifierWithDelimiter}${firstSku}${skuDelimiter}${secondSku}${skuDelimiter}R`;
            prodColor = `${firstColor}${colorizeDelimiter}${secondColor}`;
            colorize = `${this.naColor}${colorizeDelimiter}${this.naColor}`;
        } else if (firstProductIsColorize && !secondProductIsColorize) {
            // colorize - non colorize
            bundleId = `${identifierWithDelimiter}${firstSku}${skuDelimiter}${secondSku}`;
            bundleId += `|${identifierWithDelimiter}${firstSku}${skuDelimiter}${secondSku}${skuDelimiter}L`;
            prodColor = `${this.neutralColor}${colorizeDelimiter}${secondColor}`;
            colorize = `${firstColor}${colorizeDelimiter}${this.naColor}`;
        } else if (!firstProductIsColorize && secondProductIsColorize) {
            // non colorize - colorize
            bundleId = `${identifierWithDelimiter}${firstSku}${skuDelimiter}${secondSku}`;
            bundleId += `|${identifierWithDelimiter}${firstSku}${skuDelimiter}${secondSku}${skuDelimiter}R`;
            prodColor = `${this.neutralColor}${colorizeDelimiter}${firstColor}`;
            colorize = `${secondColor}${colorizeDelimiter}${this.naColor}`;
        }
        this.config.bundleID = this.config.bundleID ? `${this.config.bundleID}|${bundleId}` : `${bundleId}`;
        this.config.colorize = this.config.colorize ? `${this.config.colorize}|${colorize}` : `${colorize}`;
        this.configArray.bundleID.push(bundleId);
        this.configArray.colorize.push(colorize);
        this.setProductColor(prodColor);
    }

    private setProductColor(color: string) {
        const colorDelimiter = '|';
        this.config.prodColor = this.config.prodColor ? `${this.config.prodColor}${colorDelimiter}${color}` : color;
        this.configArray.prodColor.push(`${color}`);
    }

    private setSelectedProducts() {
        const { productConfig } = this.props;
        if (this.isPossibleToGenerateUrl()) {
            if (this.isBundleProduct()) {
                const bundleProductsSelections: IBundleSelectionData[] = productConfig.product.bundledProducts
                    .flatMap((bundleOption: IBundleOptionData) => bundleOption.selections);

                const selectedProducts: (IBundleSelectionData)[][] = Object.entries(productConfig.selections).reduce(
                    (acc: (IBundleSelectionData)[][], [id, selection]) => {
                        const selectionOptions = Array.isArray(selection) ? selection : [selection];
                        const selectedOptionProducts = selectionOptions.map(
                            (selectionOption: string) => bundleProductsSelections.find(
                                selection => selection.selection_id === selectionOption,
                            ),
                        ).filter((product): product is IBundleSelectionData => product !== undefined);

                        if (selectedOptionProducts !== undefined && selectedOptionProducts.length > 0) {
                            acc[Number(id)] = selectedOptionProducts;
                        }
                        return acc;
                    },
                    [],
                );
                this.selectedProducts = this.getWithRemainingSelectionProducts(selectedProducts);
            } else if (this.isConfigurableProduct()) {
                const getSelected = (config, id) => getOptionValue(
                    x => x.id === id,
                )(config.selections, config.product);

                const selectedProducts: (IConfigurableSelectionData)[][] = productConfig.attributes?.reduce(
                    (acc: (IConfigurableSelectionData)[][], att) => {
                        if (att.type === 'VisualSwatches') {
                            const selected = getSelected(productConfig, att.id);
                            if (selected) {
                                acc[att.id] = [{
                                    type_id: productConfig?.product.type,
                                    sku: productConfig?.product?.sku,
                                    color: selected,
                                }];
                            }
                        }
                        return acc;
                    },
                    [],
                ) ?? [];

                if (selectedProducts && selectedProducts.length > 0) {
                    this.selectedProducts = selectedProducts;
                }
            }
        }
    }

    private setProductsSelectionsColorize() {
        if (!this.isPossibleToGenerateUrl()) {
            return;
        }
        const { productConfig } = this.props;
        if (this.isBundleProduct()) {
            let isColorize = false;
            let isProductColorize = false;
            productConfig.product.bundledProducts.forEach((bundleOption: IBundleOptionData) => {
                let colorizeSkus: Array<string> = [];
                const virtualDoubleSkus: Array<string> = [];
                const doubleSkuProducts = bundleOption.selections.filter(
                    selection => isVirtualDoubleProduct(selection),
                );
                const fetchDoubleSkus = doubleSkuProducts.map(
                    doubleSkuProduct => doubleSkuProduct.sku.split('-').pop() || '',
                );
                isColorize = !!fetchDoubleSkus.length;
                if (isColorize) {
                    bundleOption.selections.forEach((selection) => {
                        const colorizeDoubleProductSkus = fetchDoubleSkus.find(
                            fetchDoubleSku => selection.sku.startsWith(fetchDoubleSku),
                        );
                        if (colorizeDoubleProductSkus) {
                            colorizeSkus.push(selection.sku);
                        }
                    });
                    // Adds virtual double sku (DOUBLE-XNS05) to colorizeSkus as well.
                    // In this case we add XNS05 to make sure that,
                    // when color is not selected `double-strap` should also consider as colorize
                    colorizeSkus.push(...fetchDoubleSkus);
                    virtualDoubleSkus.push(...fetchDoubleSkus);
                }

                // if non colorize sku is defined then we need consider that skus
                if (this.productAttribute.liquidPixelColorizeProductSkus) {
                    bundleOption.selections.forEach((selection) => {
                        const colorizeProductSkus = this.productAttribute.liquidPixelColorizeProductSkus
                            .filter(colorizeSku => selection.sku === colorizeSku);
                        if (!isProductColorize) {
                            isProductColorize = !!colorizeProductSkus.length;
                        }
                        colorizeSkus.push(...colorizeProductSkus);
                    });
                }

                // if non colorize sku is defined then we need to remove that skus from consideration
                if (this.productAttribute.liquidPixelNonColorizeProductSkus) {
                    colorizeSkus = colorizeSkus.filter(
                        colorizeSku => !this.productAttribute.liquidPixelNonColorizeProductSkus.includes(
                            colorizeSku,
                        ),
                    );
                }

                this.productSelectionColorize[Number(bundleOption.id)] = {
                    isColorize, /* purely represents that product selections has virtual-double product */
                    isProductColorize,
                    colorizeSkus,
                    virtualDoubleSkus,
                };
            });
        } else if (this.isConfigurableProduct()) {
            const colorizeSkus: Array<string> = this.productAttribute.liquidPixelColorizeProductSkus.filter(
                colorizeSku => !this.productAttribute.liquidPixelNonColorizeProductSkus.includes(colorizeSku),
            );
            const isProductColorize = !!colorizeSkus.length;
            Object.keys(this.selectedProducts).forEach((id) => {
                this.productSelectionColorize[Number(id)] = {
                    isColorize: false,
                    isProductColorize,
                    colorizeSkus,
                    virtualDoubleSkus: [],
                };
            });
        }
    }

    private getProductGroupIdentifier(bundleOption: IBundleOptionData): string {
        let identifier = '';
        if (bundleOption.selections.length) {
            const selectedOrDefaultProduct = this.selectedProducts[Number(bundleOption.id)]
                ? this.selectedProducts[Number(bundleOption.id)][0] : bundleOption.selections[0];
            const { sku } = selectedOrDefaultProduct;
            const color = LiquidPixelBase.extractColorCode(selectedOrDefaultProduct.color);
            identifier = color ? sku.split(color)[0] || sku : '';
        }
        return identifier;
    }

    private setProductsSelectionsIdentifier() {
        const { productConfig } = this.props;
        if (this.isPossibleToGenerateUrl() && this.isBundleProduct()) {
            const productGroupIdentifier: IProductSelectionIdentifier = {};
            productConfig.product.bundledProducts.forEach((bundleOption: IBundleOptionData) => {
                productGroupIdentifier[Number(bundleOption.id)] = {
                    identifier: this.getProductGroupIdentifier(bundleOption),
                };
            });

            let identifier = '';
            Object.keys(productGroupIdentifier).forEach((key) => {
                const optionId = Number(key);
                this.productSelectionIdentifier[optionId] = {
                    identifier,
                };
                identifier = identifier
                    ? `${identifier}_${productGroupIdentifier[optionId].identifier}`
                    : productGroupIdentifier[optionId].identifier;
            });
        }
    }

    private isBundleProduct(): boolean {
        return this.productType === 'bundle';
    }

    private isConfigurableProduct(): boolean {
        return this.productType === 'configurable';
    }

    /**
     * This is to pass not selected option product sku as dummy/empty product
     *
     * Adds dummy product to selectedProducts - when only product is selected and color is not
     * Adds empty product to selectedProducts - when no product option is selected
     *
     * @param selectedProducts
     * @private
     */
    private getWithRemainingSelectionProducts(selectedProducts: ISelectedProducts): ISelectedProducts {
        const { productConfig } = this.props;
        const withRemainingSelectionProducts = selectedProducts;
        if (this.isBundleProduct()) {
            productConfig.product.bundledProducts.forEach((bundleOption: IBundleOptionData) => {
                const partiallySelectedProductName = productConfig?.selectedProductNames
                    ? productConfig.selectedProductNames[Number(bundleOption.id)] || ''
                    : '';
                let partiallySelectedProduct = bundleOption.selections.find(
                    selection => selection.selection_title === partiallySelectedProductName,
                );
                const missingProducts: (IBundleSelectionData | IConfigurableSelectionData | IDummySelectionData)[] = [];
                if (!Object.keys(selectedProducts).includes(String(bundleOption.id))) {
                    if (partiallySelectedProduct) {
                        let shouldAddTwice = false;
                        if (isVirtualDoubleProduct(partiallySelectedProduct)) {
                            shouldAddTwice = true;
                            const fetchDoubleSku = partiallySelectedProduct.sku.split('-').pop();
                            if (fetchDoubleSku) {
                                partiallySelectedProduct = bundleOption.selections.find(
                                    selection => selection.sku.startsWith(fetchDoubleSku),
                                );
                            }
                        }
                        const color = LiquidPixelBase.extractColorCode(partiallySelectedProduct?.color || '');
                        const sku = color ? partiallySelectedProduct?.sku.split(color)[0] || '' : '';

                        if (sku) {
                            const dummyProduct: IDummySelectionData = {
                                type_id: EProductType.dummy,
                                sku,
                                color: this.neutralColor,
                            };
                            missingProducts.push(dummyProduct);
                            if (shouldAddTwice) {
                                missingProducts.push(dummyProduct);
                            }
                        }
                    } else if (this.allowEmptyProductSelection) {
                        // This means none of the option is selected for this bundle product group
                        // thus, we need to add a empty product
                        const dummyProduct: IDummySelectionData = {
                            type_id: EProductType.empty,
                            sku: this.nullSku,
                            color: this.neutralColor,
                        };
                        missingProducts.push(dummyProduct);
                    }
                } else if (partiallySelectedProduct && isVirtualDoubleProduct(partiallySelectedProduct)) {
                    if (selectedProducts[Number(bundleOption.id)] &&
                        selectedProducts[Number(bundleOption.id)].length === 1
                    ) {
                        const userSelects = selectedProducts[Number(bundleOption.id)][0];
                        missingProducts.push(userSelects);
                        const dummyProduct: IDummySelectionData = {
                            type_id: EProductType.dummy,
                            sku: userSelects.sku,
                            color: userSelects.color,
                        };
                        missingProducts.push(dummyProduct);
                    }
                }
                if (missingProducts.length > 0) {
                    withRemainingSelectionProducts[Number(bundleOption.id)] = missingProducts;
                }
            });
        }
        return withRemainingSelectionProducts;
    }
}
