import React, { useEffect, useState } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { generateKey } from 'ui/util/generate-key';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import {
    IMappedBundleSwatchImages,
    IMappedColorSwatchImages,
    IMappedSelectionsForSwatches,
} from './group-selections-for-swatches';
import { BundleSelectionSwatches } from './bundle-selection-swatches';
import { IBundleSelectionData, SetSelectedBundleSelectionFn } from './bundle-option';
import { BundleSelectionQuantity } from './bundle-selection-quantity';
import { IProductConfigData } from '../product-config';
import { getDoubleCounterProductName, isVirtualDoubleProduct } from './util';
import { BundleSelectionSecondOptionCheckbox } from './bundle-selection-second-option-checkbox';
import styles from './style.css';

interface StrapLabels {
    label1: string;
    label2: string;
}

interface StrapData {
    'single neck strap': null;
    'double neck strap': StrapLabels;
    'default': StrapLabels;
}

type StrapDataKeys = 'single neck strap' | 'double neck strap' | 'default';

interface IBundleSelectionSelectImageProps {
    mappedSelections: IMappedSelectionsForSwatches;
    mappedColorSwatchImages: IMappedColorSwatchImages;
    mappedBundleSwatchImages: IMappedBundleSwatchImages;
    originalSelections: Array<IBundleSelectionData>;
    selectedSelectionId: string|string[];
    selectedSelectionQty: string | number;
    bundleOptionId: string;
    bundleConfig: IProductConfigData;

    setSelectedBundleSelection(
        ...args: Parameters<SetSelectedBundleSelectionFn>
    ): ReturnType<SetSelectedBundleSelectionFn>;
    getProductNameGroupLabel: (findByName: string) => string;
    isVirtualDoubleProductInBundleOption: () => boolean;
    isVirtualDoubleProductExist: () => boolean;
    setSelectedTitle: (title: string) => void;
}

export const BundleSelectionSelectImage: React.FC<IBundleSelectionSelectImageProps> = ({
    mappedSelections,
    mappedColorSwatchImages,
    mappedBundleSwatchImages,
    setSelectedBundleSelection,
    originalSelections,
    selectedSelectionId,
    selectedSelectionQty,
    bundleOptionId,
    bundleConfig,
    getProductNameGroupLabel,
    isVirtualDoubleProductInBundleOption,
    isVirtualDoubleProductExist,
    setSelectedTitle,
}) => {
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectedColor, setSelectedColor] = useState([]);
    const [selectedSize, setSelectedSize] = useState([]);
    const [isSecondOptionChecked, setIsSecondOptionChecked] = useState(false);

    const [selectionQuantity, setSelectionQuantity] = useState('-1');

    const configExtensionAttribute = useConfig().extension_attributes;

    const bundleAttrJson = typeof configExtensionAttribute?.cw_bundle_attr_label_text === 'string'
        ? JSON.parse(configExtensionAttribute?.cw_bundle_attr_label_text) as StrapData : undefined;

    const bundleStrapData = bundleAttrJson ? Object.keys(bundleAttrJson) : [];

    let selectedSelection: IBundleSelectionData | null = null;
    const originalSelectionsDict: Map<string, IBundleSelectionData> = new Map();
    originalSelections.forEach((selection) => {
        originalSelectionsDict.set(selection.selection_id, selection);
        if (selection.selection_id === selectedSelectionId) {
            selectedSelection = selection;
        }
    });

    function getStrapLabel(bundleAttrJson: StrapData, key: string, index: number): string {
        const validKey = key as StrapDataKeys;
        if (validKey in bundleAttrJson) {
            const strapLabels = bundleAttrJson[validKey as keyof StrapData];
            return index === 0
                ? strapLabels?.label1 || 'Left Strap'
                : strapLabels?.label2 || 'Right Strap';
        }

        return index === 0 ? 'Left Strap' : 'Right Strap'; // Fallback value
    }

    const resetSelection = () => {
        setSelectedColor([]);
        setSelectedSize([]);
        setSelectedBundleSelection('', '');
    };

    const handleBundleProductImageClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        const { productName } = event.currentTarget.dataset;
        setSelectedProduct([productName]);
        setSelectedTitle(productName);
        resetSelection();
    };

    const addOrUpdateItemAtIndex = (index, newValue, setSelectedSwatch) => {
        setSelectedSwatch((prevItems) => {
            // Create a copy of the array and add or update the value at the index
            const updatedItems = [...prevItems];
            updatedItems[index] = newValue;
            return updatedItems;
        });
    };

    const getItemAtIndex = (index, selectedSwatch): string => {
        if (index >= 0 && index < selectedSwatch.length) {
            return selectedSwatch[index];
        }
        return '';
    };

    const handleToggleSecondOptions = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setIsSecondOptionChecked(event.target.checked);
        resetSelection();
    };

    const isSelected = (): boolean => !!(bundleOptionId &&
            bundleConfig?.selectedProductNames &&
            bundleConfig.selectedProductNames[Number(bundleOptionId)]);

    useEffect(() => {
        if (selectedSelectionId === '') {
            return;
        }
        // TODO: Preselected color, size in case of product edit
        const selectedSelectionIds = Array.isArray(selectedSelectionId) ? selectedSelectionId : [selectedSelectionId];
        let product: string[] = [];
        const colors: string[] = [];
        const size: string[] = [];
        selectedSelectionIds.forEach((selectionId, index) => {
            originalSelections.forEach((selection) => {
                if (selection.selection_id === selectionId) {
                    product[index] = selection.selection_title;
                    colors[index] = selection.color;
                    size[index] = selection.size;
                }
            });
        });
        // replace actual product selection to virtual product
        originalSelections.forEach((selection) => {
            if (isVirtualDoubleProduct(selection)) {
                // check that double products are selected and replace with actual virtual product
                if (Array.isArray(selectedSelectionId) || colors.length > 1 || size.length > 1) {
                    product = [selection.selection_title];
                }
            }
        });
        // when product is selected we don't want to reset the preselected values
        if (!selectedProduct.length && product.length) {
            setSelectedProduct(product);
            setSelectedColor(colors);
            setSelectedSize(size);
            if (selectedSelectionQty) {
                setSelectionQuantity(selectedSelectionQty.toString());
            }
            // when 2 different colors are selected check the checkbox
            if (selectedSelectionIds.length === 2 && selectedSelectionIds[0] !== selectedSelectionIds[1]) {
                setIsSecondOptionChecked(true);
            }
        }
    }, [selectedSelectionId]);

    return (
        <>
            {/* Dropdown Image for selecting products */}
            <div
                className={classes(
                    styles.productImageSelect,
                    {
                        [styles.virtualDoubleProduct]: isVirtualDoubleProductInBundleOption(),
                        [styles.validate]: bundleConfig?.validation?.current && !isSelected(),
                    },
                )}
            >
                {Object.keys(mappedSelections).map(productName => (
                    <div
                        className={styles.productImageSelectItemWrapper}
                        onClick={handleBundleProductImageClick}
                        role="presentation"
                        data-product-name={productName}
                        key={productName}
                    >
                        <button
                            value={productName}
                            type="button"
                            className={classes({
                                [styles.productImageSelectItem]: true,
                                [styles.selected]: selectedProduct.includes(productName),
                            })}
                        >
                            <img src={mappedBundleSwatchImages[productName]?.bundle_swatch_image} alt="" />
                        </button>
                        <span className={productName}>{getProductNameGroupLabel(productName)}</span>
                    </div>
                ))}
            </div>

            {/* Swatches for the color and size attributes */}
            {Object.keys(mappedSelections).map((productName) => {
                if (selectedProduct.includes(productName)) {
                    let iterate = 1;
                    let fetchSwatchByProductName = productName;
                    const isVirtualDoubleStrap = () => iterate === 2;
                    const getInputType = () => {
                        if (isVirtualDoubleStrap()) {
                            return 'multi';
                        }
                        return 'select';
                    };
                    const doubleProductName = getDoubleCounterProductName(originalSelections, productName);
                    if (doubleProductName !== '') {
                        fetchSwatchByProductName = doubleProductName;
                        iterate = 2;
                    }
                    return Array.from({ length: iterate }).map((_, index) => {
                        let label: string | undefined;
                        if (iterate > 1 && bundleAttrJson &&
                            bundleStrapData.includes(getProductNameGroupLabel(productName)?.toLowerCase())
                        ) {
                            const productKey = getProductNameGroupLabel(productName)?.toLowerCase();
                            label = productKey && getStrapLabel(bundleAttrJson, productKey, index);
                        } else if (iterate > 1 && bundleAttrJson) {
                            label = getStrapLabel(bundleAttrJson, 'default', index);
                        } else {
                            label = undefined;
                        }

                        const getAttributeTitle = (includeSelectedValue = true): string => {
                            const attributeLabel = mappedSelections[fetchSwatchByProductName]?.attributeLabel;
                            const selectedColorValue = getItemAtIndex(index, selectedColor);
                            const selectedSizeValue = getItemAtIndex(index, selectedSize);
                            const selectedValue = attributeLabel === 'color' ? selectedColorValue : selectedSizeValue;
                            if (includeSelectedValue && selectedValue) {
                                return `${attributeLabel}: ${selectedValue}`;
                            }
                            return `Select ${attributeLabel}:`;
                        };

                        return (
                            <>
                                {iterate > 1 && index === 0 && (
                                    <div className={styles.swatchesListWrapper}>
                                        <span className={styles.label}>
                                            {getAttributeTitle(!isSecondOptionChecked)}
                                        </span>
                                    </div>
                                )}
                                {iterate > 1 && index === 0 && (
                                    <BundleSelectionSecondOptionCheckbox
                                        checked={isSecondOptionChecked}
                                        onChange={handleToggleSecondOptions}
                                    />
                                )}
                                <BundleSelectionSwatches
                                    key={generateKey([index], fetchSwatchByProductName)}
                                    productName={fetchSwatchByProductName}
                                    swatchData={mappedSelections[fetchSwatchByProductName]}
                                    setSelectedBundleSelection={(selectedId, selectedQuantity) => {
                                        setSelectedBundleSelection(selectedId, selectedQuantity, getInputType(), index);
                                        if (iterate > 1 && index === 0 && !isSecondOptionChecked) {
                                            // selects second option when customer did not want different colors
                                            setSelectedBundleSelection(
                                                selectedId,
                                                selectedQuantity,
                                                getInputType(),
                                                index + 1,
                                            );
                                        }
                                    }}
                                    selectionQuantity={selectionQuantity}
                                    setSelectionQuantity={setSelectionQuantity}
                                    originalSelectionsDict={originalSelectionsDict}
                                    mappedColorSwatchImages={mappedColorSwatchImages}
                                    selectedColor={getItemAtIndex(index, selectedColor)}
                                    setSelectedColor={(value) => {
                                        addOrUpdateItemAtIndex(index, value, setSelectedColor);
                                        if (iterate > 1 && index === 0 && !isSecondOptionChecked) {
                                            addOrUpdateItemAtIndex(index + 1, value, setSelectedColor);
                                        }
                                    }}
                                    selectedSize={getItemAtIndex(index, selectedSize)}
                                    setSelectedSize={(value) => {
                                        addOrUpdateItemAtIndex(index, value, setSelectedSize);
                                        if (iterate > 1 && index === 0 && !isSecondOptionChecked) {
                                            addOrUpdateItemAtIndex(index + 1, value, setSelectedSize);
                                        }
                                    }}
                                    bundleConfig={bundleConfig}
                                    label={label}
                                    hideSelectionSwatches={index === 0 ? false : !isSecondOptionChecked}
                                    shouldHideLabel={iterate > 1 ? !isSecondOptionChecked : false}
                                />
                            </>
                        );
                    });
                }
                return '';
            })}
            {/* Quantity input if a selection is made */}
            {selectedSelection && !isVirtualDoubleProductExist() && (
                <BundleSelectionQuantity
                    bundleSelection={selectedSelection}
                    selectionQuantity={selectionQuantity}
                    setSelectionQuantity={setSelectionQuantity}
                    inline={false}
                    extraOnChangeAction={(quantity) => {
                        if (selectedSelection) {
                            setSelectedBundleSelection(selectedSelection.selection_id, quantity);
                        }
                    }}
                />
            )}
        </>
    );
};
