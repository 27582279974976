import React from 'react';
import { getProductSkuValue } from 'ui/util/get-product-info';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import { ItemImage } from './item-image';
import { ItemInformation } from './item-information';
import styles from './style.css';

interface ILineItemProps {
    item: ICartItemProps;
    toggleMinicart?: () => void;
}

export const LineItem: React.FC<ILineItemProps> = ({ item, toggleMinicart }) => (
    <li className={styles.productItem} data-test={`cart-item ${getProductSkuValue(item)}`}>
        <div className={styles.productInfo}>
            <ItemImage item={item} />
            <ItemInformation item={item} toggleMinicart={toggleMinicart} />
        </div>
    </li>
);
