import React from 'react';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import { Quantity } from './quantity';
import { ItemEdit } from './item-edit';
import { ItemPrice } from './item-price';
import styles from './style.css';

interface IItemActionsProps {
    item: ICartItemProps;
    toggleMinicart?: () => void;
}

export const ItemActions: React.FC<IItemActionsProps> = ({ item, toggleMinicart }) => (
    <div className={styles.actionsWrapper}>
        <div className={styles.priceQtyWrapper}>
            <ItemPrice item={item} />
            <Quantity item={item} />
        </div>
        <div className={styles.editContainer}>
            {item.userCanEdit && <ItemEdit item={item} toggleMinicart={toggleMinicart} />}
        </div>
    </div>
);
