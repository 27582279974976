import React from 'react';
import { selectCartQuantityTotal } from 'ui/util/select-cart-total';
import { connectCart } from 'ui/util/gql-connect-cart';
import { Count } from '../count';
import styles from './style.css';

interface CheckoutCartIconProps {
    children?: any;
    cartItems: any[];
    testAttribute?: string;
    top?: string;
    right?: string;
    toggleMinicart?: () => void;
}

const CheckoutCartIconImp = ({
    cartItems,
    testAttribute,
    top,
    right,
    toggleMinicart,
}: CheckoutCartIconProps) => (
    <div data-test="cart-icon" className={styles.checkoutCartIcon}>
        <i
            className="fa-solid fa-cart-shopping"
            aria-hidden="true"
            data-test={testAttribute}
            onClick={() => {
                toggleMinicart();
            }}
        >
            <Count
                dataTest="cart-icon-count"
                count={selectCartQuantityTotal({ cartItems })}
                size="20px"
                top={top || '4px'}
                right={right || '-10px'}
                fontSize="12px"
            />
        </i>
    </div>
);
export const CheckoutCartIcon = connectCart(CheckoutCartIconImp);
