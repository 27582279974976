import { AnyAction } from 'redux';
import { inject, DuckModuleWithoutReducer } from '@silkpwa/redux';
import { GET_RECENTLY_VIEWED_PRODUCTS, UPDATE_RECENTLY_VIEWED_PRODUCTS } from 'chefworks-theme/src/graphql/catalog/product/RecentViewed';
import { GraphQlClient } from 'chefworks-theme/src/graphql/base';
import { Router } from '../../../router';
import { ICache, ICacheFactory } from '../../../multistore';

const initialState = {
    recentlyViewedItems: [],
    recentlyViewedIds: [],
};

@inject(
    'StoreLevelCacheFactory',
    'router',
    'account',
    'ecommerceProductEntity',
    'actionEvents',
)
export class GqlRecentlyViewed extends DuckModuleWithoutReducer {
    public readonly actions: Pick<GqlRecentlyViewed, ('fetchRecentlyViewed' | 'setRecentlyViewed' | 'setLocalRecentViewToCustomerAccount' | 'removeRecentViewed')>;

    public readonly selectors: Pick<GqlRecentlyViewed, 'getRecentlyViewed'>;

    private readonly cache: ICache<any>;

    protected routeSourceId = null;

    private account;

    private products;

    private actionEvents;

    constructor(
        storeLevelCacheFactory: ICacheFactory,
        private router: Router,
        account: any,
        products: any,
        actionEvents: any,
    ) {
        super('GqlRecentlyViewed');
        this.account = account;
        this.products = products;
        this.actionEvents = actionEvents;
        this.cache = storeLevelCacheFactory.create(
            'GqlRecentlyViewed',
            this.reduceRecentlyViewed.bind(this),
        );
        this.addDuck('cache', this.cache);

        this.cache.persistSlice(['recentlyViewedItems'], 0);
        this.cache.persistSlice(['recentlyViewedIds'], 0);

        this.fetchRecentlyViewed = this.fetchRecentlyViewed.bind(this);
        this.setRecentlyViewed = this.setRecentlyViewed.bind(this);
        this.getRecentlyViewed = this.getRecentlyViewed.bind(this);
        this.setLocalRecentViewToCustomerAccount = this.setLocalRecentViewToCustomerAccount.bind(this);
        this.removeRecentViewed = this.removeRecentViewed.bind(this);

        this.actions = {
            fetchRecentlyViewed: this.fetchRecentlyViewed,
            setRecentlyViewed: this.setRecentlyViewed,
            setLocalRecentViewToCustomerAccount: this.setLocalRecentViewToCustomerAccount,
            removeRecentViewed: this.removeRecentViewed,
        };
        this.selectors = { getRecentlyViewed: this.getRecentlyViewed };
    }

    // eslint-disable-next-line class-methods-use-this
    protected get actionNames() {
        return ['SET_RECENTLY_VIEWED_PRODUCT', 'SET_RECENTLY_VIEWED_ID', 'UPDATE_RECENTLY_VIEWED_IDS'];
    }

    private reduceRecentlyViewed(state = initialState, action: AnyAction) {
        switch (action.type) {
            case this.actionTypes.SET_RECENTLY_VIEWED_PRODUCT:
                return { ...state, recentlyViewedItems: action.recentlyViewedItems };
            case this.actionTypes.SET_RECENTLY_VIEWED_ID: {
                const { recentlyViewedIds = [] } = state;
                const { id = null } = action;
                if (id) {
                    recentlyViewedIds.unshift(id);
                }
                const uniqueIds = recentlyViewedIds.filter((value, index, self) => self.indexOf(value) === index);
                if (uniqueIds.length > 10) {
                    uniqueIds.pop();
                }
                return {
                    ...state,
                    recentlyViewedIds: uniqueIds,
                };
            }
            case this.actionTypes.UPDATE_RECENTLY_VIEWED_IDS:
                return {
                    ...state,
                    recentlyViewedIds: action.recentlyViewedIds,
                };
            default:
                return state;
        }
    }

    public async setRecentlyViewed(dispatch, state) {
        const { isLoggedIn } = this.account.selectors.getAccount(state());
        if (isLoggedIn) {
            await GraphQlClient.mutate({
                mutation: UPDATE_RECENTLY_VIEWED_PRODUCTS,
                variables: {
                    productIds: [this.routeSourceId],
                },
            });
        }
        dispatch(this.cache.wrapAction({
            type: this.actionTypes.SET_RECENTLY_VIEWED_ID,
            id: this.routeSourceId,
        }));
    }

    public async fetchRecentlyViewed(dispatch: any, state) {
        const { isLoggedIn } = this.account.selectors.getAccount(state());
        if (isLoggedIn) {
            const result = await GraphQlClient.query({
                query: GET_RECENTLY_VIEWED_PRODUCTS,
                variables: {
                    pageSize: 10,
                },
            });
            const products = result?.data?.recentViewedProducts?.items || [];
            const ids = products.map(item => item.id);
            dispatch(this.cache.wrapAction({
                type: this.actionTypes.UPDATE_RECENTLY_VIEWED_IDS,
                recentlyViewedIds: ids,
            }));
        }
    }

    public getRecentlyViewed(state: any) {
        const { recentlyViewedIds } = this.cache.getCurrentState(state);
        return this.products.selectors
            .getProducts(state, recentlyViewedIds);
    }

    public async setLocalRecentViewToCustomerAccount(state) {
        const { recentlyViewedIds } = this.cache.getCurrentState(state);
        await GraphQlClient.mutate({
            mutation: UPDATE_RECENTLY_VIEWED_PRODUCTS,
            variables: {
                productIds: recentlyViewedIds,
            },
        });
    }

    public async removeRecentViewed(dispatch) {
        dispatch(this.cache.wrapAction({
            type: this.actionTypes.UPDATE_RECENTLY_VIEWED_IDS,
            recentlyViewedIds: [],
        }));
    }

    public initialize(store) {
        this.actionEvents.addEventListener(
            this.account.actionTypes.USER_LOGGED_IN,
            this.setLocalRecentViewToCustomerAccount,
        );

        this.actionEvents.addEventListener(
            this.account.actionTypes.USER_LOGGED_OUT,
            store.dispatch(this.removeRecentViewed),
        );
        this.router.handle((route) => {
            const unsubscribe = this.router.onPageLoaded(async () => {
                const { resource: { resourceType, resourceId } } = route || {};
                if (resourceType === 'product') {
                    this.routeSourceId = resourceId;
                    await store.dispatch(this.setRecentlyViewed);
                }
                await store.dispatch(this.fetchRecentlyViewed);
                route.done();
                unsubscribe();
            });
        });
    }
}
