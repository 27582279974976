import { IProductConfigData } from 'chefworks-theme/src/ui/component/product-configurator/product-config';
import {
    IMultiSelection,
} from '@silkpwa/module/react-component/product-config/configurable-product/configurable-product';

import IProductInfo = Magento.Definitions.SilkRestappDataProductInfoInterface;
import IProductOptionStock = Magento.Definitions.SilkRestappDataProductOptionStockItemInterface;
import IProductOptionPrice = Magento.Definitions.SilkRestappDataProductOptionOptionPriceInterface;

interface IHasOriginalProduct {
    originalProduct: IProductInfo;
}

export interface ISelections {
    [key: number]: number;
}

type TypeSelectOption = (key: number, value: number) => void;

interface IIndexSelection {
    attribute: number;
    value: number;
}

interface IProductIndex {
    productId: number;
    selections: IIndexSelection[];
}

interface ISingleOption {
    id: number;
    label: string;
    type: string;
    value: string;
}

export interface IProductOption {
    id: number;
    label: string;
    options: ISingleOption[];
    type: string;
}

interface IProduct extends IHasOriginalProduct {
    index: IProductIndex[];
    options: IProductOption[];
}

const SWATCHES_VISUAL = 'VisualSwatches';
const SWATCHES_TEXT = 'TextSwatches';

export const getColors = (product: IProduct) => product.options.filter(o => o.type === SWATCHES_VISUAL)[0];
export const getSizes = (product: IProduct) => product.options.filter(o => o.type === SWATCHES_TEXT)[0];

function findStockItem(
    { originalProduct }: IHasOriginalProduct,
    productId: number,
): IProductOptionStock {
    const stockItem: IProductOptionStock = {
        product_id: productId,
        is_in_stock: 0,
        stock_message: '',
        is_item_saleable: 1,
    };

    if ((originalProduct.options || {}).option_stock_items) {
        return originalProduct.options.option_stock_items.find(o => o.product_id === productId) || stockItem;
    }
    return stockItem;
}

function findFinalPrice(
    { originalProduct }: IHasOriginalProduct,
    productId: number,
): number {
    if ((originalProduct.options || {}).option_prices) {
        const priceOptions: IProductOptionPrice[]|undefined = originalProduct.options.option_prices;
        const priceOption = priceOptions?.find(o => Number(o.product_id) === Number(productId));
        return priceOption?.final_price?.amount ?? 0;
    }
    return 0;
}

/**
 * Check either stock option parents (e.g. color) is/are selected, then
 * so we may know either the stock option can show their
 * simple product specific stock message labels
 *
 * @param product
 * @param selections
 */
function stockOptionParentsSelected(product: IProduct, selections: ISelections): boolean {
    const parentOptions: Array<string> = product.options.slice(0, product.options.length - 1).map(o => `${o.id}`);
    const selectedOptions = Object.keys(selections);

    return parentOptions.every(i => selectedOptions.includes(i));
}

/**
 * Get `The smallest` (by Size) item product id taking into account current chosen color option.
 *
 * @param product
 * @param selections
 * @param colorOption
 * @param sizeOption
 */
const getTheSmallestItemId = (
    product: IProduct,
    selections: ISelections,
    colorOption: IProductOption,
    sizeOption?: IProductOption,
): number => {
    let smallestItemId = product?.index[0]?.productId;

    if (!colorOption || !colorOption.id || !sizeOption || !sizeOption.id) {
        return smallestItemId;
    }

    const currentColorOptionId = selections[colorOption.id];
    const currentColorIndexes = product.index.filter((indexOption) => {
        const colorSelections = indexOption.selections.filter(
            (s: IIndexSelection) => s.attribute === colorOption.id,
        ) ?? [];
        const colorSelection = colorSelections[0];
        return colorSelection && colorSelection.value === currentColorOptionId;
    });

    let firstMatchFound = false;
    sizeOption.options.forEach((size) => {
        const foundIndexesBySize = currentColorIndexes.filter((colorIndex) => {
            const sizeSelections = colorIndex.selections.filter(
                (s: IIndexSelection) => s.attribute === sizeOption.id && s.value === size.id,
            ) ?? [];
            return sizeSelections && sizeSelections.length;
        });
        if (!firstMatchFound && foundIndexesBySize && foundIndexesBySize.length) {
            smallestItemId = foundIndexesBySize[0].productId;
            firstMatchFound = true;
        }
    });

    return smallestItemId;
};

interface IComputedOptionOption {
    id: number;
    isAllowed: boolean;
    label: string;
    optionsLength: number;
    stockLabel: string;
    type: string;
    upcharge: number | undefined;
    isOutOfStock: boolean | undefined;
    value: string;
}

interface IComputedOption {
    id: number;
    label: string;
    options: IComputedOptionOption[];
    type: string;
}

function computeOptions(product: IProduct, selections: ISelections): IComputedOption[] {
    /* This implementation is very inefficient, but the logic is correct now */
    const isValueAllowed = (
        attributeToCheck: number,
        valueToCheck: number,
        optType: string,
        stockOption: boolean,
    ) => {
        /**
         * Get all items reachable via option selection
         */
        const items = product.index.filter((indexEntry) => {
            /**
             * Always return true it this is not the last stock-option (e.g. size).
             * When only color option do not return true and keep continue loop.
             */
            if ((!stockOption || optType === 'ImageSwatches') && product.options.length !== 1) {
                return true;
            }

            return indexEntry.selections.every((possibleSelection) => {
                /**
                 * When we find the attribute we are checking in the entry's selections,
                 * it must have the same value as what we are checking.
                 */
                if (possibleSelection.attribute === attributeToCheck) {
                    return possibleSelection.value === valueToCheck;
                }

                /**
                 * Otherwise, the attribute must either not currently be selected,
                 * or its selected value must match this index entry's selected value.
                 */
                const selectedValue = selections[possibleSelection.attribute];
                const attributeIsSelected = !!selectedValue;
                const valueIsSelected = selectedValue === possibleSelection.value;
                return !attributeIsSelected || valueIsSelected;
            });
        });

        let stockLabel = '';
        let isAllowed = false;
        let isOutOfStock = false;
        let upcharge;

        if (items.length) {
            /**
             * At least one item needs to be in stock for swatch to be enabled.
             */
            isAllowed = items.some(({ productId }: { productId: number }) => {
                const { is_in_stock: isInStock } = findStockItem(product, productId);
                return !!isInStock;
            });

            if (stockOption && stockOptionParentsSelected(product, selections)) {
                /**
                 * If this is for a stockOption swatch (e.g. size) AND the parent option is selected (e.g. color),
                 * then there should be exactly one item in items.
                 */
                const {
                    is_in_stock: isInStock,
                    stock_message: stockMessage,
                } = findStockItem(product, items[0].productId);
                stockLabel = stockMessage;
                isOutOfStock = !isInStock;

                const colorOption = getColors(product);
                if (colorOption) {
                    const sizeOption = getSizes(product);
                    const smallestItemId = getTheSmallestItemId(product, selections, colorOption, sizeOption);
                    const minPrice = findFinalPrice(product, smallestItemId);
                    const finalPrice = findFinalPrice(product, items[0].productId);
                    upcharge = (finalPrice - minPrice) !== 0 ? finalPrice - minPrice : 0;
                }
            }
        }

        return {
            stockLabel,
            isAllowed,
            isOutOfStock,
            upcharge,
        };
    };

    return product.options.map((o, idx: number) => {
        const isStockOption = product.options.length === idx + 1;
        return {
            ...o,
            options: o.options.map((opt) => {
                const {
                    isAllowed,
                    stockLabel,
                    upcharge,
                    isOutOfStock,
                } = isValueAllowed(o.id, opt.id, opt.type, isStockOption);
                return {
                    ...opt,
                    stockLabel,
                    optionsLength: product.options.length || 0,
                    isAllowed,
                    upcharge,
                    isOutOfStock,
                };
            }),
        };
    }).filter(o => o.options.length);
}

function scrollToValidationArea() {
    const validationArea = document.getElementById('validation-area') || {
        scrollIntoView: () => null,
    };
    validationArea.scrollIntoView();
}

interface IProductAttributeOption extends IComputedOptionOption {
    displayValue: string;
    label: string;
    selected: boolean;
    handleClick: () => void;
    disabled: boolean;
}

export interface IProductAttribute extends IComputedOption {
    options: IProductAttributeOption[];
}

const computeAttributes = (
    options: IComputedOption[],
    selections: ISelections,
    selectOption: TypeSelectOption,
): IProductAttribute[] => {
    const isSelected = (o: IComputedOption, opt: IComputedOptionOption) => selections[o.id] === opt.id;
    return options.map(o => ({
        ...o,
        options: o.options.map(opt => ({
            ...opt,
            displayValue: opt.value,
            label: opt.label,
            selected: isSelected(o, opt) && opt.isAllowed,
            handleClick: () => {
                selectOption(o.id, opt.id);
            },
            disabled: !opt.isAllowed,
        })),
    }));
};

export const computeProductAttributes = (
    product: IProduct,
    selections: ISelections,
    selectOption: TypeSelectOption,
) => (
    computeAttributes(
        computeOptions(product, selections),
        selections,
        selectOption,
    )
);

export const fixValue = (n, d, min) => {
    let v = Number(n);
    if (Number.isNaN(v)) {
        v = d;
    }

    if (v < min) {
        v = min;
    }

    return Math.round(v);
};

export const checkGiftCardSelections = (giftCardConfig: IProductConfigData) => {
    if (giftCardConfig.validation) {
        // giftCardConfig.validation.set({ validation: true });
        giftCardConfig.validation.set(true);
    }
    return false;
};

const isValidBundleSelections = (product: IProductConfigData['product'], selections: IProductConfigData['selections']): boolean => {
    let result = true;
    const isValidProductSelection = (option) => {
        const selection = selections[option.id];
        return selection !== undefined && (!Array.isArray(selection) || selection.length > 1);
    };

    product.bundledProducts.forEach((option) => {
        if (option.required === 1 && !(isValidProductSelection(option))) {
            result = false;
        }
    });
    return result;
};

export const checkBundleSelections = (bundleConfig) => {
    const { selections } = bundleConfig;
    const { product } = bundleConfig.props;
    if (bundleConfig.validation) {
        bundleConfig.validation.set(true);
    }
    const result = isValidBundleSelections(product, selections);
    if (!result) {
        scrollToValidationArea();
    }
    return result;
};

export const checkSelections = (config) => {
    const { selections, orderMultiple, multiSelections } = config;
    const { product } = config;
    if (config.validation) {
        config.validation.set({ validation: true });
    }
    let result = false;
    if (orderMultiple && orderMultiple.current) {
        if (multiSelections.length) {
            result = multiSelections.every(
                (items: IMultiSelection) => Object.keys(items.selections).length === product.options.length,
            );
        }
    } else {
        result = Object.keys(selections).length === product.options.length;
    }
    if (!result) {
        scrollToValidationArea();
    }
    return result;
};

export const checkSelectionsByType = (config: IProductConfigData) => {
    const { selections } = config;
    const { product } = config;
    let result: boolean;
    if (config.validation) {
        config.validation.set(true);
    }
    if (config.type === 'BundleConfig') {
        result = isValidBundleSelections(product, selections);
    } else {
        result = checkSelections(config);
    }

    if (!result) {
        scrollToValidationArea();
    }
    return result;
};

export const isValidMinQty = (config, minQty: number) => {
    const {
        quantity,
        orderMultiple,
    } = config;
    let result = false;
    if (orderMultiple && orderMultiple.current) {
        if (config.multiSelections?.length) {
            result = config.multiSelections.every(
                (items: IMultiSelection) => {
                    let { minQuantity } = items.simpleProduct;
                    minQuantity = minQuantity ?? minQty;
                    return items.qty >= minQuantity;
                },
            );
        }
    } else {
        result = quantity.current >= minQty;
    }
    return result;
};

export const isValidMaxQty = (config, maxQty: number) => {
    const {
        quantity,
        orderMultiple,
    } = config;
    let result = false;
    if (orderMultiple && orderMultiple.current) {
        if (config.multiSelections?.length) {
            result = config.multiSelections.every(
                (items: IMultiSelection) => {
                    let { maxQuantity } = items.simpleProduct;
                    maxQuantity = maxQuantity ?? maxQty;
                    return items.qty <= maxQuantity;
                },
            );
        }
    } else {
        result = quantity.current <= maxQty;
    }
    return result;
};
