import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { getProductSkuValue } from 'ui/util/get-product-info';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import styles from './style.css';

interface IItemSKUProps {
    item: ICartItemProps;
}

export const ItemSKU: React.FC<IItemSKUProps> = ({ item }) => {
    const t = usePhraseTranslater();
    return (
        <p className={styles.sku}>
            <strong className={styles.skuLabel}>{t('Item#:')}</strong>
            <span className={classes(styles.productSku, 'weglot-exclude')}>{getProductSkuValue(item)}</span>
        </p>
    );
};
