import { useQuery } from '@apollo/client';
import {
    GET_IS_VOTED_BY_CUSTOMER,
    GET_PRODUCT_REVIEWS,
    GET_REVIEW_METRICS,
    GET_HOMEPAGE_REVIEWS,
} from 'chefworks-theme/src/graphql/review/reviews';

export const useProductReviews = (productId: number, page: number, sort = 'date-desc', searchTerm = '', starFilter = '') => {
    const {
        data,
        loading,
        error,
        refetch,
    } = useQuery(GET_PRODUCT_REVIEWS, {
        variables: {
            productId,
            page,
            sort,
            searchTerm,
            starFilter,
        },
    });

    return {
        productReviews: data?.productReviews?.items || [],
        totalReviews: data?.productReviews?.total || 0,
        pageSize: data?.productReviews?.page_size || 0,
        loading,
        error,
        refetch,
    };
};

export const useReviewMetrics = (productId: number) => {
    const {
        data,
        loading,
        error,
    } = useQuery(GET_REVIEW_METRICS, {
        variables: { productId },
    });

    return {
        averageRating: data?.reviewMetrics?.averageRating || 0,
        averageRatingRound: data?.reviewMetrics?.averageRatingRound || 0,
        averageRecommends: data?.reviewMetrics?.averageRecommends || 0,
        averageSizing: data?.reviewMetrics?.averageSizing || 0,
        averageWidth: data?.reviewMetrics?.averageWidth || 0,
        reviewsCount: data?.reviewMetrics?.reviewsCount || 0,
        ratingsCount: data?.reviewMetrics?.ratingsCount || {},
        loading,
        error,
    };
};

export const useHomePageReviews = (page: number) => {
    const {
        data,
        loading,
        error,
        refetch,
    } = useQuery(GET_HOMEPAGE_REVIEWS, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        variables: { page },
    });

    return {
        homePageReviews: data?.homePageReviews?.items || [],
        total: data?.homePageReviews?.total || 0,
        pageSize: data?.homePageReviews?.page_size || 0,
        page: data?.homePageReviews?.page || 1,
        loading,
        error,
        refetch,
    };
};

export const useReviewHelpfulness = (reviewId: string) => {
    const {
        data,
        loading,
        error,
    } = useQuery(GET_IS_VOTED_BY_CUSTOMER, {
        variables: { reviewId },
    });

    return {
        vote: data?.reviewHelpfulness?.vote,
        loading,
        error,
    };
};
