import React from 'react';
import { computeWidthStyle } from 'ui/util/compute-width';
import { OriginalPrice } from 'ui/component/product-configurator/product-info/original-price';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { useAccount } from '@silkpwa/module/account';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import { DefaultImage } from './default-image';
import { ProductImage } from './product-image';
import { ProductName } from './product-name';
import { ProductPrice } from './product-price';

interface ProductCardProps {
    theme: { [className: string]: string };
    product: any;
    displayed: number;
    location: string;
    image?: any;
}

export function isSpecialPriceCustomer(account: AccountState): boolean {
    if (account.info.extensionAttributes?.special_price_flag != null) {
        return account.info.extensionAttributes.special_price_flag;
    }
    return false;
}

export const ProductCard = ({
    theme,
    product,
    image,
    displayed,
    location,
}: ProductCardProps) => {
    const account = useAccount();
    const config = useConfig();
    const isSpecialPrice = isSpecialPriceCustomer(account);
    const isMsrpShowEnable = !isSpecialPrice && !!config.extension_attributes?.cw_display_msrp;
    return (
        <div className={theme.productCard} style={computeWidthStyle(displayed)} data-sku={product.sku}>
            <div className={theme.productCardWrap}>
                <ProductImage
                    product={product}
                    image={image || <DefaultImage product={product} />}
                    location={location}
                />
                <ProductName
                    theme={theme}
                    product={product}
                    location={location}
                />
                { isMsrpShowEnable ? (
                    <div style={{
                            display: 'flex',
                            gap: '1rem',
                            justifyContent: 'center',
                            alignItems: 'baseline',
                        }}
                    >
                        <ProductPrice
                            theme={theme}
                            product={product}
                            isMsrpShowEnable={isMsrpShowEnable}
                        />
                        <OriginalPrice
                            product={product}
                            isMsrpShowEnabled={isMsrpShowEnable}
                            isListingPage
                        />
                    </div>
                ) : (
                    <ProductPrice
                        theme={theme}
                        product={product}
                    />
                )}
            </div>
        </div>
    );
};
