import React from 'react';
import { useQuery } from '@apollo/client';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { GET_DYNAMIC_WIDGETS, IDynamicWidget } from 'graphql/cms/dynamic-widgets';
import { HTMLRenderer } from './html-renderer';

interface IDynamicWidgetProps {
    location: string;
    className?: string;
}

export const DynamicWidget: React.FC<IDynamicWidgetProps> = ({ location, className = '' }) => {
    const storeConfig = useConfig();
    const { data, error } = useQuery(GET_DYNAMIC_WIDGETS, {
        variables: {
            location,
            storeId: storeConfig?.store_config?.current_store?.id,
        },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
    });
    if (error) return null;
    if (!data?.getDynamicWidgets?.length) return null;
    return (
        <>
            {data && data.getDynamicWidgets.map((block: IDynamicWidget) => {
                const blockTitle = block?.title.toString();
                const htmlString = block?.content.toString();
                return <HTMLRenderer html={htmlString} key={blockTitle} title={blockTitle} className={className} />;
            })}
        </>
    );
};
