import React from 'react';
import { connectRouter } from '@silkpwa/module/react-component/connect-router';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { WindowSize } from '@silkpwa/module/react-component/window-size';
import { useStoreMode } from '@silkpwa/module/store-mode';
import { usePortalInfo } from '@silkpwa/module/portal';
import { DotdigitalIntegration } from 'ui/component/dotdigital-integration/dotdigital-integration';
import { RecentlyViewedProductsWidget } from 'ui/component/product/product-widget/recently-viewed/recently-viewed';
import { CookiePrivacy } from 'ui/component/cookie-privacy';
import { SignUp } from 'ui/component/footer/sign-up';
import { FooterLinks } from 'ui/component/footer/footer-links';
import { FooterButtons } from 'ui/component/footer/footer-buttons';
import { FooterMidContent } from 'ui/component/footer/footer-mid-content';
import { FooterLowerContent } from 'ui/component/footer/footer-lower-content';
import { PortalData, Area } from 'ui/component/portal/portal-data';

import styles from './style.css';

interface IFooterProps {
    currentLocation: string;
}

const Footer: React.FC<IFooterProps> = ({ currentLocation }) => {
    const mode = useStoreMode();
    const {
        isPortal,
        isForceLogin,
        portal,
        isCustomerLoggedIn,
    } = usePortalInfo();
    const hasPortalData = Boolean(portal && portal.entity_id && portal.is_active);
    const ecommerceConfig = useConfig();
    if (!mode.isRetail) return null;

    if (currentLocation.startsWith('/checkout') &&
        !currentLocation.startsWith('/checkout/cart') &&
        !currentLocation.startsWith('/checkout/success') &&
        !currentLocation.startsWith('/checkout/index/index')
    ) {
        return null;
    }
    return (
        <footer className={styles.footer}>
            <style>
                {`
                    .mobile {
                        display: none;
                    }
                    a.contact.button {
                        background: white;
                        color: black;
                        border-radius: 50px;
                        padding: 10px;
                        font-weight: bold;
                    }
                `}
            </style>
            <WindowSize maxWidth={1040}>
                <style>
                    {`
                        .desktop {
                            display: none;
                        }
                        .mobile {
                            display: block;
                            line-height: 2;
                        }
                    `}
                </style>
            </WindowSize>
            <RecentlyViewedProductsWidget />
            {isPortal && (isForceLogin || hasPortalData) ? (
                <>
                    <PortalData
                        area={Area.FooterNavigation}
                        data={portal}
                        isCustomerLoggedIn={isCustomerLoggedIn}
                    />
                    <PortalData
                        area={Area.Disclaimer}
                        data={portal}
                        isCustomerLoggedIn={isCustomerLoggedIn}
                    />
                    <PortalData
                        area={Area.Popup}
                        data={portal}
                        isCustomerLoggedIn={isCustomerLoggedIn}
                    />
                </>
            ) : (
                <>
                    <SignUp />

                    <div className={styles.footerBackground}>
                        <div className={styles.footerContainer}>
                            <div className={styles.footerLinksContainer}>
                                <FooterLinks />
                            </div>
                            <div className={styles.footerButtonsContainer}>
                                <FooterButtons />
                            </div>
                        </div>
                    </div>
                    <div className={styles.footerMid}>
                        <div className={styles.footerContainer}>
                            <FooterMidContent />
                        </div>
                    </div>
                </>
            )}
            <div id="lowerFooter" className={styles.footerLow}>
                <div className={styles.footerContainer}>
                    <FooterLowerContent />
                </div>
            </div>
            <CookiePrivacy />
            <DotdigitalIntegration data={ecommerceConfig} />
        </footer>
    );
};

const ConnectedFooter = connectRouter(Footer);

export { ConnectedFooter as Footer };
