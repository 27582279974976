import React from 'react';
import { connectCart } from '@silkpwa/module/react-component/gql-connect-cart';
import { selectCartItems } from './gql-select-cart-items';

type StripComponentType<T> = T extends React.ComponentType<infer K> ? K : never;

type CartProps<C> = StripComponentType<C> & Partial<CartItems>;

interface CartItems {
    cartItems?: any[];
}

/* eslint-disable arrow-parens */
const chefworksConnectCart = <P, >(Component: React.ComponentType<P>) => {
    const Connected = connectCart((props: P) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...props} cartItems={selectCartItems(props)} />
    ));

    return Connected as unknown as React.ComponentType<CartProps<typeof Connected>>;
};

export { chefworksConnectCart as connectCart };
