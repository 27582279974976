import React from 'react';
import { MainColumn } from 'ui/component/main-column';
import screenSwitch from 'ui/styles/screen-switch.css';
import { gqlConnectRecentlyViewed } from '@silkpwa/module/react-component/gql/connect-recently-viewed';
import styles from './style.css';
import { DynamicProductSlider as ProductSlider } from '../product-slider';

const RecentlyViewedProductsWidgetImpl = ({ products }) => {
    if (products.length === 0) return null;
    return (
        <MainColumn>
            <div className={styles.titleText}>Recently Viewed</div>
            <div className={screenSwitch.showOnDesktop}>
                <ProductSlider items={products} show={6} location="recentlyViewedProducts" />
            </div>
            <div className={`${screenSwitch.showOnLargeMobile} ${screenSwitch.showOnMediumMobile}`}>
                <ProductSlider items={products} show={3} location="recentlyViewedProducts" />
            </div>
            <div className={screenSwitch.showOnSmallMobile}>
                <ProductSlider items={products} show={2} location="recentlyViewedProducts" />
            </div>
            <div className={screenSwitch.showOnVerySmallMobile}>
                <ProductSlider items={products} show={1} location="recentlyViewedProducts" />
            </div>
        </MainColumn>
    );
};

export const RecentlyViewedProductsWidget = gqlConnectRecentlyViewed(RecentlyViewedProductsWidgetImpl);
