import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import styles from './style.css';

interface IHeaderProps {
    cartItems: ICartItemProps[];
}
const useItemCount = (cartItems: ICartItemProps[]) => {
    const t = usePhraseTranslater();
    const num = cartItems.reduce((total, item) => total + item.quantity, 0);
    if (num === 1) {
        return t('%1 ITEM', num);
    }
    return t('%1 ITEMS', num);
};

export const Header: React.FC<IHeaderProps> = ({ cartItems }) => {
    const itemCount = useItemCount(cartItems);
    return (
        <div className={styles.itemTotal}>
            <span className={styles.totalText}>{itemCount}</span>
        </div>
    );
};
