import React, { useEffect, useRef } from 'react';
import { connectCart } from 'ui/util/gql-connect-cart';
import { classes } from '@silkpwa/module/util/classes';
import { MiniCart } from 'ui/component/minicart';
import styles from './style.css';

const CartModelImp: React.FC<any> = ({
    cartItems,
    cartSummaryData,
    cartFreeShippingProgressBar,
    isCartOpen,
    toggleMinicart,
}) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const handleOutsideClick = (event: React.MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            toggleMinicart();
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Escape' && isCartOpen === true) {
            handleOutsideClick(event as unknown as React.MouseEvent);
        }
    };

    useEffect(() => {
        if (isCartOpen) {
            document.body.style.overflow = 'hidden';
            document.body.style.height = '100vh';
        } else {
            document.body.style.overflow = '';
            document.body.style.height = '';
        }
    }, [isCartOpen]);
    return (
        <div
            onClick={handleOutsideClick}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
            className={classes(styles.modalOverlay, isCartOpen ? styles.modalOverlayOpen : '')}
        >
            <div ref={modalRef} className={styles.cartModal}>
                <button
                    type="button"
                    className={styles.closeButton}
                    onClick={() => toggleMinicart()}
                >
                    <i className="fa-solid fa-xmark" />
                </button>
                <MiniCart
                    cartItems={cartItems}
                    cartSummaryData={cartSummaryData}
                    cartFreeShippingProgressBar={cartFreeShippingProgressBar}
                    toggleMinicart={toggleMinicart}
                />
            </div>
        </div>
    );
};

export const CartModel = connectCart(CartModelImp);
