import { inject, DuckModuleWithoutReducer } from '@silkpwa/redux';

/* TODO: Write tests for this code after we get the e2e integration set up */

/**
 * Sends data to Certona for each visited page.
 */
@inject(
    'certonaAPI',
    'appEventBus',
    'ecommerceCompare',
    'ecommerceWishlist',
    'ecommerceProductEntity',
)
export class CertonaEvents extends DuckModuleWithoutReducer {
    private store;

    constructor(
        private certonaAPI,
        appEventBus,
        compare,
        wishlist,
        private products,
    ) {
        super('certonaEvents');

        appEventBus.subscribe(
            'chefworks.quickview.opened',
            this.handleQuickviewOpened.bind(this),
        );

        appEventBus.subscribe(
            'cart.item.added',
            this.handleCartItemAdded.bind(this),
        );

        appEventBus.subscribe(
            'cart.item.removed',
            this.handleCartItemRemoved.bind(this),
        );

        compare.addEventListener(
            'add',
            this.handleCompareItemAdded.bind(this),
        );

        compare.addEventListener(
            'remove',
            this.handleCompareItemRemoved.bind(this),
        );

        wishlist.addEventListener(
            'add',
            this.handleWishlistItemAdded.bind(this),
        );

        wishlist.addEventListener(
            'remove',
            this.handleWishlistItemRemoved.bind(this),
        );
    }

    initialize(store) {
        this.store = store;
    }

    handleCartItemAdded(product) {
        this.certonaAPI.sendPageEvent({
            pagetype: 'addtocart',
            itemid: product.sku,
        });
    }

    handleCartItemRemoved(item) {
        this.certonaAPI.sendPageEvent({
            pagetype: 'cartremove',
            itemid: item?.product?.sku,
        });
    }

    handleCompareItemAdded(productId) {
        this.certonaAPI.sendPageEvent({
            pagetype: 'compareadd',
            itemid: this.getSKU(productId),
        });
    }

    handleCompareItemRemoved(productId) {
        this.certonaAPI.sendPageEvent({
            pagetype: 'compareremove',
            itemid: this.getSKU(productId),
        });
    }

    handleWishlistItemAdded(productId) {
        this.certonaAPI.sendPageEvent({
            pagetype: 'wishlistadd',
            itemid: this.getSKU(productId),
        });
    }

    handleWishlistItemRemoved(productId) {
        this.certonaAPI.sendPageEvent({
            pagetype: 'wishlistremove',
            itemid: this.getSKU(productId),
        });
    }

    handleQuickviewOpened(productId) {
        this.certonaAPI.sendPageEvent({
            pagetype: 'quickview',
            itemid: this.getSKU(productId),
        });
    }

    private getSKU(productId: number) {
        const state = this.store.getState();
        const product = this.products.selectors.getProduct(state, productId);
        return product.sku;
    }
}
