import { DigitizingSku } from 'ui/static-config';
import { ICartItemProps, EProductStockStatus } from 'ui/component/cart/cart-interface';

interface SelectCartItemsProps {
    cartItems?: ICartItemProps[];
    setCartItemQty?: (id: number, qty: number, item: ICartItemProps) => void;
    removeCartItem?: (id: number, item: ICartItemProps) => void;
}

export const selectCartItems = (props: SelectCartItemsProps): ICartItemProps[] => {
    const embroideryItemsMap: Record<string, ICartItemProps[]> = {};
    const cartItems = props.cartItems || [];
    cartItems.forEach((item) => {
        if (item.embroidery_item) {
            const embroideryId = String(item.embroidery_item);

            if (!embroideryItemsMap[embroideryId]) {
                embroideryItemsMap[embroideryId] = [];
            }

            const updatedEmbItem = {
                ...item,
                embroidery_items: [],
                options: [],
            };

            embroideryItemsMap[embroideryId].push(updatedEmbItem);
        }
    });

    const updatedItems = cartItems.map((item) => {
        if (item.embroidery_item) return null;

        const { id, product, quantity } = item;

        const embroideryItems = embroideryItemsMap[id] || [];

        let embroideryPrice = 0;
        if (embroideryItems.length > 0) {
            embroideryPrice = embroideryItems.reduce((total, embroidery) => {
                const price = embroidery.prices?.row_total?.value || 0;
                return total + price;
            }, 0);

            embroideryPrice *= quantity;
        }

        const updatedItem: ICartItemProps = {
            ...item,
            embroideryPrice,
            setQty: (qty: string) => props.setCartItemQty?.(id, Number(qty), item),
            remove: () => props.removeCartItem?.(id, item),
            userCanEdit: product.sku !== DigitizingSku,
            embroidery_items: embroideryItems,
        };

        return updatedItem;
    }).filter(item => item !== null) as ICartItemProps[];

    return updatedItems;
};

export const isInStock = (item: ICartItemProps): boolean => item.product.stock_status === EProductStockStatus.IN_STOCK;
