const computeSimpleProduct = (product, selections) => {
    const matching = product.index.filter((x) => {
        const matchingSelections = x.selections.filter(y => (
            y.value === selections[y.attribute]
        ));
        return matchingSelections.length === x.selections.length;
    });

    if (matching[0]) {
        return matching[0].productId;
    }

    return undefined;
};

const computeBundleSimpleProducts = (product, selections) => {
    const selectedSimpleProducts: number[] = [];

    product.bundledProducts.forEach((bundleOption: {
        id: string;
        selections: [{'selection_id': string; 'product_id': number}];
    }) => {
        const selectionId = selections[bundleOption.id];
        const selectionItem = bundleOption.selections.find(
            selection => selection.selection_id === selectionId,
        );
        if (selectionItem) {
            selectedSimpleProducts.push(Number(selectionItem.product_id));
        }
    });

    if (selectedSimpleProducts.length) {
        return selectedSimpleProducts;
    }
    return undefined;
};

export { computeSimpleProduct, computeBundleSimpleProducts };
