import React from 'react';
import { useStoreMode } from '@silkpwa/module/store-mode';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { usePortalInfo } from '@silkpwa/module/portal';
import styles from './style.css';

interface ICartButtonsProps {
    toggleMinicart?: () => void;
}
export const CartButtons: React.FC<ICartButtonsProps> = ({ toggleMinicart }) => {
    const t = usePhraseTranslater();
    const mode = useStoreMode();
    const { isPortalActive, isCustomerLoggedIn, portalCode } = usePortalInfo();
    const isDisplayDiscountNotice = !(isPortalActive && isCustomerLoggedIn && portalCode);
    return (
        <div className={styles.minicartButtons}>
            <div className={styles.actions}>
                <a
                    href={mode.checkOutUrl}
                    data-test="mini-cart-btn-checkout"
                    className={styles.checkoutButton}
                    onClick={() => {
                        if (toggleMinicart) {
                            toggleMinicart();
                        }
                    }}
                >
                    {mode.checkoutButtonText}
                </a>
                <a
                    href={mode.cartUrl}
                    data-test="mini-cart-btn-cart"
                    className={styles.cartButton}
                    onClick={() => {
                        if (toggleMinicart) {
                            toggleMinicart();
                        }
                    }}
                >
                    {t('View Cart')}
                </a>
            </div>
            {isDisplayDiscountNotice && (
                <p className={styles.promoText}>{t('Add promo code, coupons & rewards in the next step')}</p>
            )}
            <p className={styles.linkwrapper}>
                <a
                    className={styles.continueButton}
                    href="/"
                    onClick={() => {
                        if (toggleMinicart) {
                            toggleMinicart();
                        }
                    }}
                >
                    {t('CONTINUE SHOPPING')}
                </a>
            </p>
        </div>
    );
};
