import { processURL } from './url';
import { makeOptions } from './make-options';

// eslint-disable-next-line max-len
import ChefworksLoyaltyProgramRewardsPointPdpDataInterface = Magento.Definitions.ChefworksLoyaltyProgramRewardsPointPdpDataInterface;
// eslint-disable-next-line max-len
import SilkRestappDataProductProductDetailInterface = Magento.Definitions.SilkRestappDataProductProductDetailInterface;
// eslint-disable-next-line max-len
import SilkRestappDataProductGalleryImageInterface = Magento.Definitions.SilkRestappDataProductGalleryImageInterface;

const processImages = (images: Array<IProductImage & { img?: string }>) => images.map((i) => {
    const res = { ...i };

    if (res.img) res.image = res.img;

    return res;
});

const getSubProductIds = (indices) => {
    const res = {};
    indices.forEach((index) => {
        Object.keys(index).forEach((i) => {
            res[i] = true;
        });
    });

    return Object.keys(res);
};

const getSubProducts = (indices) => {
    const indicesArr = Object.keys(indices).map(k => indices[k]);
    const ids = getSubProductIds(indicesArr);
    return ids.map(id => ({
        id: Number(id),
        type: 'simple',
        images: processImages(indices.imageIndex[id] || []),
        price: indices.priceIndex[id] ? indices.priceIndex[id].price : 0,
        originalPrice: indices.priceIndex[id] ? indices.priceIndex[id].originalPrice : 0,
    }));
};

function extractRewardsData(productExtraInfo: string) {
    const parsed: any = JSON.parse(productExtraInfo);

    const { reward_points_pdp_data: rewardData } = (parsed || {});

    return rewardData as ChefworksLoyaltyProgramRewardsPointPdpDataInterface;
}

function extractStockData(productExtraInfo: string) {
    const parsed: any = JSON.parse(productExtraInfo);
    const { stock: stockData } = (parsed || {});
    return stockData;
}


/* TODO: Something funky here, magento.d should return
    all the needed properties and we shouldn't need this */
export interface IProductImage extends SilkRestappDataProductGalleryImageInterface{
    type: string;
    // eslint-disable-next-line camelcase
    product_id: string;
    img?: string;
}

function removeEmptyOptions(product: SilkRestappDataProductProductDetailInterface) {
    if (!product.options) {
        return;
    }

    const updatedAttributes = product.options.attributes;

    (updatedAttributes || []).forEach((attribute, attributeIndex) => {
        attribute.options.forEach((option, optionIndex) => {
            if (option.products.length === 0) {
                attribute.options.splice(optionIndex, 1);
            }
        });

        updatedAttributes[attributeIndex] = attribute;
    });
}

interface IMoreInfoDefaultLogoInfo
{
    defaultNumbers: string|null;
    lock: number|null;
}

function getDefaultLogoInfo(
    product: SilkRestappDataProductProductDetailInterface,
): IMoreInfoDefaultLogoInfo|null {
    const result: IMoreInfoDefaultLogoInfo = {
        defaultNumbers: null,
        lock: null,
    };

    if (!product.more_information) {
        return null;
    }

    product.more_information.forEach((info) => {
        switch (info.code) {
            case 'logo_embroidery_default_numbers':
                result.defaultNumbers = info.value;
                break;
            case 'logo_embroidery_lock_default':
                result.lock = parseInt(info.backend_value, 10);
                break;
            default:
                break;
        }
    });

    return result;
}

function hasDefaultEmbroideryAndLocked(
    product: SilkRestappDataProductProductDetailInterface,
): boolean {
    const defaultLogoInfo = getDefaultLogoInfo(product);
    return Boolean(defaultLogoInfo &&
        defaultLogoInfo.lock &&
        defaultLogoInfo.defaultNumbers);
}

export function mapProduct(product) {
    if (product.product_url === undefined) {
        // already mapped
        return product;
    }
    removeEmptyOptions(product);

    const {
        options,
        index,
        colorIndex,
        priceIndex,
        imageIndex,
    } = makeOptions(product.options);

    const images = processImages(product.images || [{
        thumb: product.imgurl,
        full: product.imgurl,
        image: product.imgurl,
        type: 'image',
    }]);

    const productUrl = processURL(product.product_url);
    const temporaryRedirectUrl = processURL(product.canonical || '');
    const rewardsData = extractRewardsData(product.extra_info);
    const stockData = extractStockData(product.extra_info);

    const result = {
        id: Number(product.id),
        type: product.type,
        url: productUrl,
        options: (options && options.length) ? options : undefined,
        index: (index && index.length) ? index : undefined,
        colorIndex: (colorIndex && Object.keys(colorIndex).length) ? colorIndex : undefined,
        name: product.name,
        images,
        price: product.prices ? Number(product.prices.final_price.amount) : 0,
        maxPrice: product.prices ? Number(product.prices.final_price.maximal_price) : 0,
        minPrice: product.prices ? Number(product.prices.final_price.minimal_price) : 0,
        originalPrice: product.prices ? Number(product.prices.old_price.amount) : 0,
        msrpPrice: product.prices ? Number(product.prices.msrp_price.amount) : 0,
        inStock: product.is_saleable !== 0,
        description: product.description || undefined,
        shortDescription: product.short_description || undefined,
        bundledProducts: product.bundle_options || [],
        subProducts: [],
        sku: product.sku,
        quantity: product.qty,
        pdpMessage: product.product_pdp_message ? product.product_pdp_message : '',
        isQtyIncrementsEnable: product.enable_qty_increments,
        pdpUnitMessage: product.product_unit_message,
        qtyIncrements: product.qty_increments,
        attributes: product.more_information || undefined,
        customOptions: product.options ? product.options.custom_options : undefined,
        breadcrumbs: product.breadcrumbs ? product.breadcrumbs.map(e => ({
            label: e.label,
            url: processURL(e.link || ''),
        })) : undefined,
        meta_keyword: product.meta_keyword,
        meta_title: product.meta_title || product.name,
        meta_description: product.meta_description,
        canonical: product.canonical,
        hreflang: product.hreflang,
        robots: product.robots,
        minQuantity: product.min_qty,
        maxQuantity: product.max_qty,
        hasDefaultEmbroideryAndLocked: hasDefaultEmbroideryAndLocked(product),
        rewardsData,
        richSnippet: product.rich_snippet,
        stockData,
        isEnabled: product.is_available === 1,
        temporaryRedirectUrl: productUrl === temporaryRedirectUrl ? '' : temporaryRedirectUrl,
        originalProduct: product,
        loading: false,
        instagramTags: product.instagram_tags,
    };

    Object.keys(result).filter(k => result[k] === undefined).forEach((k) => {
        delete result[k];
    });

    if (result.type === 'configurable' && priceIndex && imageIndex) {
        result.subProducts = getSubProducts({
            imageIndex,
            priceIndex,
        });
    }

    return result;
}

export function mapProductOptions(product) {
    if (product.options === null || product.product_url !== undefined) {
        // if product_url exists it's not options loading
        return product;
    }
    removeEmptyOptions(product);

    const {
        options,
        index,
        colorIndex,
        priceIndex,
        imageIndex,
    } = makeOptions(product.options);

    const result = Object.assign({}, product);
    result.options = options;
    result.index = index;
    result.colorIndex = colorIndex;
    result.subProducts = [];

    Object.keys(product).filter(k => result[k] === undefined).forEach((k) => {
        delete result[k];
    });

    if (result.type === 'configurable' && priceIndex && imageIndex) {
        result.subProducts = getSubProducts({
            imageIndex,
            priceIndex,
        });
    }

    return result;
}

export function mapProductPrices(product) {
    if (product.prices === null || product.product_url !== undefined) {
        // if product_url exists it's not pricing loading
        return product;
    }

    const result = Object.assign({}, product);
    result.price = product.prices ? Number(product.prices.final_price.amount) : 0;
    result.maxPrice = product.prices ? Number(product.prices.final_price.maximal_price) : 0;
    result.minPrice = product.prices ? Number(product.prices.final_price.minimal_price) : 0;
    result.originalPrice = product.prices ? Number(product.prices.old_price.amount) : 0;
    return result;
}

/**
 * Recently Viewed Products data
 *
 * @param product
 */
export function mapRecentlyViewedProduct(product) {
    const images = processImages(product.images || [{
        thumb: product.imgurl,
        full: product.imgurl,
        image: product.imgurl,
        type: 'image',
    }]);
    const productUrl = processURL(product.product_url);
    /**
     * Rewards Data is needed for rendering rewards info on the Recently Viewed product tile
     */
    const rewardsData = extractRewardsData(product.extra_info);
    const { prices } = product;
    /**
     * We still need this property `product.originalProduct` in order to perform `hasPrice` checking:
     *  - !!product.originalProduct.prices.configured_price.amount;
     *  - see `hasPrice` in `chefworks-theme/src/ui/component/product/product-card/product-price/product-price.tsx`.
     */
    const originalProduct = {
        prices,
    };

    /**
     * Simplified data analogue to `mapProduct` containing specific data only for the `Recently Viewed` widget
     */
    const result = {
        id: Number(product.id),
        type: product.type,
        url: productUrl,
        name: product.name,
        images,
        price: Number(prices.final_price.amount),
        maxPrice: Number(prices.final_price.maximal_price),
        minPrice: Number(prices.final_price.minimal_price),
        originalPrice: Number(prices.old_price.amount),
        sku: product.sku,
        rewardsData,
        originalProduct,
        loading: false,
    };

    Object.keys(result).filter(k => result[k] === undefined).forEach((k) => {
        delete result[k];
    });

    return result;
}
