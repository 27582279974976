import { gql, TypedDocumentNode } from '@apollo/client';

type RecentlyViewedProduct = {
    id: string;
    sku: string;
};

type GetRecentlyViewedProductsResponse = {
    recentViewedProducts: {
        currentPage: number;
        items: RecentlyViewedProduct[];
        pageSize: number;
        totalPages: number;
        'total_count': number;
    };
};

type GetRecentlyViewedProductsVariables = {
    pageSize: number;
    currentPage?: number;
};

export const GET_RECENTLY_VIEWED_PRODUCTS: TypedDocumentNode<GetRecentlyViewedProductsResponse, GetRecentlyViewedProductsVariables> = gql`
    query GetRecentlyViewedProducts($pageSize: Int, $currentPage: Int) {
        recentViewedProducts(pageSize: $pageSize, currentPage: $currentPage) {
            currentPage
            items {
                id
                sku
            }
            pageSize
            totalPages
            total_count
        }
    }
`;

type UpdateRecentlyViewedProductsResponse = {
    recentViewedProducts: boolean;
};

type UpdateRecentlyViewedProductsVariables = {
    productIds: number[];
};

export const UPDATE_RECENTLY_VIEWED_PRODUCTS: TypedDocumentNode<UpdateRecentlyViewedProductsResponse, UpdateRecentlyViewedProductsVariables> = gql`
    mutation recentViewedProducts($productIds: [Int]!) {
        recentViewedProducts(product_ids: $productIds)
    }
`;
