import { ICartItemProps } from 'ui/component/cart/cart-interface';

export const getProductSkuValue = (item: ICartItemProps) => {
    const variantSku = item?.configured_variant?.sku || null;
    if (variantSku) {
        return variantSku;
    }
    if (item.__typename === 'BundleCartItem') {
        const bundleOptions = item.bundle_options || [];
        let productName = item.product?.sku;
        bundleOptions.forEach((option) => {
            const values = option.values || [];
            values.forEach((value: { sku: any }) => {
                productName += `-${value.sku}`;
            });
        });
        return productName;
    }
    return item.product?.sku;
};
