import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import styles from './style.css';

interface IRemoveItemProps {
    item: ICartItemProps;
}

export const RemoveItem: React.FC<IRemoveItemProps> = ({ item }) => {
    const t = usePhraseTranslater();
    if (!item.userCanEdit) return null;

    return (
        <button
            className={styles.removeItem}
            onClick={() => {
                item.remove();
            }}
            title={t('Remove')}
            type="button"
        />

    );
};
