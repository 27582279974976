import React from 'react';
import {
    Swatches,
    ApplySelection,
    TextSwatch,
    ISwatchesProps,
} from 'ui/component/product/swatches';
import textSwatch from './text-swatch.css';
import { IProductConfigData } from '../../product-config';

export interface ITextSwatchesProps {
    attribute: ISwatchesProps['attribute'];
    isMultiSizeQty?: boolean;
    config?: IProductConfigData;
}

export const TextSwatches: React.FC<ITextSwatchesProps> = ({
    attribute,
    isMultiSizeQty = false,
    config,
}) => (
    <Swatches
        theme={textSwatch}
        SelectionBehavior={ApplySelection}
        Swatch={TextSwatch}
        attribute={attribute}
        isMultiSizeQty={isMultiSizeQty}
        config={config}
    />
);
