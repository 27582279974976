import { computeSimpleProduct } from '@silkpwa/module/util/compute-simple-product';
// TODO: NEED TO REMOVE THIS LINE
import { getSessionStorageData, setSessionStorageData } from 'chefworks-theme/src/ui/util/session-storage';
import { processURL } from '../util/url';
import { ICart } from './repository';

export const processOptions = options => (options || []).map((x) => {
    const k = Number.parseInt(x.attribute_id, 10);
    const v = Number.parseInt(x.option_id, 10);

    return [k, v];
});

import SilkRestappDataQuoteInfoInterface = Magento.Definitions.SilkRestappDataQuoteInfoInterface;

const processSelections = (selections) => {
    let parsedSelections = selections;
    if (typeof selections === 'string') {
        parsedSelections = JSON.parse(selections);
    }

    return parsedSelections;
};

// TODO: START NEED TO REMOVE CODE
const setMaskIdToSession = (cart: any) => {
    const existMaskId = getSessionStorageData('cartId', true);
    const maskId = cart?.mask_id ?? '';
    if (maskId && existMaskId !== maskId) {
        setSessionStorageData('cartId', maskId);
    }
};
// TODO: END NEED TO REMOVE CODE

export const getChildProduct = selectors => (item) => {
    if (item.product_type === 'bundle' || item.product_type === 'giftcard') {
        return {
            ...item,
        };
    }
    const selections = {};
    item.selections.forEach(([k, v]) => {
        selections[k] = v;
    });

    const simpleProductId = computeSimpleProduct(
        selectors.getProduct(item.product_id),
        selections,
    );

    return {
        ...item,
        simpleProductId,
    };
};

export interface ICartSummaryData {
    code: string;
    title: string;
    value: number;
}

export function processResult(selectors): (inputData: SilkRestappDataQuoteInfoInterface) => ICart {
    return (inputData: SilkRestappDataQuoteInfoInterface) => {
        setMaskIdToSession(inputData);
        const items = inputData?.cart?.items?.map(i => ({
            ...i,
            url: processURL(i.url),
            selections: (i.product_type === 'bundle' || i.product_type === 'giftcard')
                ? processSelections(i.selections)
                : processOptions(i.options),
            selections_qty: i.product_type === 'bundle'
                ? processSelections(i.selections_qty)
                : '',
        })).map(getChildProduct(selectors));

        const summary = inputData?.cart?.totals
            .filter(f => f.code === 'grand_total' || Number(f.value) !== 0)
            .map(f => ({
                ...f,
                value: Number(f.value),
            }));

        return {
            items,
            summary,
            hasCustomer: !!(inputData?.billing_address && inputData?.billing_address?.id),
            checkoutDisabled: inputData?.cart?.checkout_disabled,
            freeShippingProgressBar: inputData?.free_shipping_progress_bar,
        };
    };
}
