import React from 'react';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import { LineItem } from './line-item';
import styles from './style.css';

interface ILineItemsProps {
    cartItems: ICartItemProps[];
    toggleMinicart?: () => void;
}
export const LineItems: React.FC<ILineItemsProps> = ({
    cartItems,
    toggleMinicart,
}) => (
    <div
        className={styles.minicartItemsWrapper}
    >
        <ol className={styles.minicartItems}>
            {cartItems.map(item => (
                <LineItem
                    key={item.id}
                    item={item}
                    toggleMinicart={toggleMinicart}
                />
            ))}
        </ol>
    </div>
);
