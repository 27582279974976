import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { isInStock } from 'ui/util/gql-select-cart-items';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import { ItemName } from './item-name';
import { RemoveItem } from './remove-item';
import { Embroidery } from './embroidery';
import styles from './style.css';

interface IItemHeaderProps {
    item: ICartItemProps;
}

export const ItemHeader: React.FC<IItemHeaderProps> = ({ item }) => {
    const t = usePhraseTranslater();
    return (
        <strong className={styles.productItemName}>
            <ItemName item={item} />
            <Embroidery item={item} />
            {
                (!isInStock(item)) && (
                    <span className={styles.oosNotification}>
                        {t('Product is no longer available, please remove from cart')}
                    </span>
                )
            }
            <RemoveItem item={item} />
        </strong>
    );
};
