import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { formatCurrency } from 'ui/component/checkout/util/get-currency';
import { useGetCartTotalPrice } from 'ui/util/get-cart-total-price';
import { RawSpan } from 'ui/component/raw/span';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

export const CartTotal = ({ cartSummaryData }) => {
    const t = usePhraseTranslater();
    const getCartTotalPrice = useGetCartTotalPrice();
    // eslint-disable-next-line camelcase
    const { raw_totals } = cartSummaryData;

    const rewardsTotal = raw_totals.filter(item => item.code === 'rewards-total')[0] ?? {};
    const rewardsTotalHtml = rewardsTotal?.label?.replace(/{{REWARDS}}/g, formatCurrency(rewardsTotal?.amount));

    return (
        <div className={styles.total}>
            <span>{t('TOTAL:')}</span>
            <span className={classes(styles.amount, 'weglot-exclude')}>{getCartTotalPrice(cartSummaryData)}</span>
            { rewardsTotalHtml && (
                <span className={styles.rewardsTotal}>
                    <RawSpan className={styles.discountWrapper} data-test="discount-label">{rewardsTotalHtml}</RawSpan>
                </span>
            )}
        </div>
    );
};
