import React from 'react';
import {
    Swatches,
    ApplySelection,
    ColorSwatch,
    ISwatchesProps,
} from 'ui/component/product/swatches';
import colorSwatch from './color-swatch.css';
import { IProductConfigData } from '../../product-config';

export interface IVisualSwatchesProps {
    attribute: ISwatchesProps['attribute'];
    isMultiSizeQty?: boolean;
    config?: IProductConfigData;
}

export const VisualSwatches: React.FC<IVisualSwatchesProps> = ({
    attribute,
    isMultiSizeQty = false,
    config,
}) => (
    <Swatches
        theme={colorSwatch}
        SelectionBehavior={ApplySelection}
        Swatch={ColorSwatch}
        attribute={attribute}
        isMultiSizeQty={isMultiSizeQty}
        config={config}
    />
);
