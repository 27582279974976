import { AnyAction } from 'redux';
import {
    inject,
    DuckModuleWithReducer,
    Dispatch,
    Store,
    DispatcherFun,
    GetState,
} from '@silkpwa/redux';
import { CMSRouter } from '@silkpwa/magento/api/cms-router/router';
import { Http } from '@silkpwa/module/util/api/http';
import {
    cfurState,
    processLocation,
    restoreOrigin,
    shouldSearchOrigin,
    isQueryParamNotComplete,
    ExtendedLocation,
} from '@silkpwa/module/cfur';
import { History, Location, LocationDescriptorObject } from 'history';
import { locationToString, LocationArgType } from '../util/location-to-string';
import { IPersist } from '../persist';
import { IScrolling } from '../scrolling';
import { IDebounce } from '../interfaces/debounce';
import { EventEmitter } from '../util/event-emitter';
import { isSameLocation, parseLocation } from './util';
import {
    IProgressVals,
    IRouter,
    IRouterActions,
    IRouterSelectors,
    IResourceMapping,
    IResourceMap,
    IRouteCallback,
} from './i-router';
import { RouteFactory } from './route-factory';
import {
    ICache,
    ICacheFactory,
    StoreCache,
    StoreSelector,
} from '../multistore';

import ICfur = Magento.Definitions.ChefworksCfUrDataCfurInterface;
import ICfurData = Magento.Definitions.ChefworksCfUrDataCfurInfoInterface;
import IPortalDataRedirect = Magento.Definitions.ChefworksPortalDataPortalDataRedirectInterface;

const emptyLocation: LocationDescriptorObject = {
    pathname: '',
    hash: '',
    search: '',
};

const initialState = {
    location: undefined,
    dataProgress: 0,
    internalKey: 0,
    scrollPosition: {},
};

@inject(
    'history',
    'magentoAPI',
    'persist',
    'cmsRouter',
    'scrolling',
    'debounce',
    'StoreLevelCacheFactory',
    'StoreSelector',
)
export class Router extends DuckModuleWithReducer implements IRouter {
    private emitter = new EventEmitter();

    private readonly locationInfoCache: ICache<any>;

    private readonly baseCfurApiUrl = '/app-cfur-check-url';

    private readonly appIsPortalCheckIsRedirectUrl = '/app-portal-check-is-redirect';

    private appliedCfur: ICfur | undefined = undefined;

    public handle = this.emitter.createSubscribeMethod('globalhandler');

    public handleOnce = this.emitter.createOnceMethod('initialhandler');

    public onPageLoaded = this.emitter.createSubscribeMethod('pageloaded');

    public onDataLoaded = this.emitter.createSubscribeMethod('dataloaded');

    public actions: IRouterActions;

    public selectors: IRouterSelectors;

    constructor(
        private history: History,
        private magentoAPI: Http,
        private persist: IPersist,
        private cmsRouter: CMSRouter,
        private scrolling: IScrolling,
        private debounce: IDebounce,
        storeLevelCacheFactory: ICacheFactory,
        private storeSelector: StoreSelector,
    ) {
        super('router');
        this.locationInfoCache = storeLevelCacheFactory.create(
            'router.locationInfo',
            this.reduceLocationInfo.bind(this),
        );
        this.locationInfoCache.persistAll();
        this.addDuck('locationInfoCache', this.locationInfoCache);
        this.persist.persistPath([this.slice, 'scrollPosition'], 'table');

        this.notifyHandlers = this.notifyHandlers.bind(this);
        this.setLocation = this.setLocation.bind(this);
        this.saveRoutes = this.saveRoutes.bind(this);
        this.navigate = this.navigate.bind(this);
        this.hookHistory = this.hookHistory.bind(this);
        this.hookScrolling = this.hookScrolling.bind(this);

        this.actions = {
            saveRoutes: this.saveRoutes,
            navigate: this.navigate,
        };

        this.selectors = {
            location: this.location.bind(this),
            getProgress: this.getProgress.bind(this),
            getLocationKey: this.getLocationKey.bind(this),
            getKey: this.getKey.bind(this),
            getCurrentResourceInfo: this.getCurrentResourceInfo.bind(this),
            getAppliedCfur: this.getAppliedCfur.bind(this),
        };
    }

    /**
     * Used to create the action types for this DuckModule.
     */
    // eslint-disable-next-line class-methods-use-this
    get actionNames(): string[] {
        return [
            'LOCATION_CHANGED',
            'SET_DATA_PROGRESS',
            'SAVE_ROUTES',
            'SET_SCROLL_POS',
        ];
    }

    /**
     * Performs initial tasks when the application is created.
     */
    initialize(store: Store) {
        this.persist.afterHydrate(() => {
            store.dispatch(this.hookHistory);
            store.dispatch(this.hookScrolling);
        });
    }

    reduceLocationInfo(state: IResourceMap = {}, action: AnyAction): IResourceMap {
        if (action.type === this.actionTypes.SAVE_ROUTES) {
            const locationInfo: IResourceMap = { ...state };
            action.routes.forEach(({ pathname, resource }: IResourceMapping) => {
                locationInfo[pathname] = resource;
            });

            return locationInfo;
        }

        return state;
    }

    /**
     * Updates the state of the router when an action occurs.
     */
    protected reduce(state = initialState, action: AnyAction) {
        switch (action.type) {
            case this.actionTypes.LOCATION_CHANGED:
                return {
                    ...state,
                    location: action.location,
                    dataProgress: 0,
                    internalKey: state.internalKey + 1,
                };
            case this.actionTypes.SET_DATA_PROGRESS:
                return {
                    ...state,
                    dataProgress: action.value,
                };
            case this.actionTypes.SET_SCROLL_POS:
                return {
                    ...state,
                    scrollPosition: {
                        ...state.scrollPosition,
                        [action.key]: action.value,
                    },
                };
            default:
                return state;
        }
    }

    /**
     * Adds a handler for a specific resource type.
     */
    addHandler(resourceType: string, cb: IRouteCallback) {
        this.emitter.subscribe(`handle.${resourceType}`, cb);
    }

    /**
     * Notify handlers of routes so they can look up data for the resource.
     *
     * Causes events to be emitted when the primary data is loaded and
     * all data is loaded for the page.
     */
    private notifyHandlers(dispatch: Dispatch, getState: GetState) {
        const location = this.location(getState());
        const routeKey = this.getKey(getState());
        const resource = this.getCurrentResourceInfo(getState());

        const routes = new RouteFactory(
            this.emitter,
            location,
            resource,
            (totalProgress: number) => {
                const currentKey = this.getKey(getState());
                // update progress for current route if this is still current
                // route
                if (currentKey === routeKey) {
                    dispatch({
                        type: this.actionTypes.SET_DATA_PROGRESS,
                        value: totalProgress,
                    });
                }
            },
        );

        this.emitter.send(
            [`handle.${resource.resourceType}`, 'globalhandler', 'initialhandler'],
            i => [routes.createRoute(i)],
        );

        routes.begin();
    }

    /**
     * Updates the current location of the application. Determine the resource
     * for the location from the backend and notify route handlers so they can
     * retrieve data for the resource.
     *
     * Note that the handlers are notified about the most up to date resource
     * for the location so that they pull the most up to date data for the location.
     */
    private setLocation(location: Location) {
        return async (dispatch: Dispatch) => {
            dispatch({
                type: this.actionTypes.LOCATION_CHANGED,
                location,
            });

            const resource: any = await this.cmsRouter.getResourceInfo(location);

            dispatch(this.saveRoutes([{
                pathname: location.pathname,
                resource,
            }]));

            dispatch(this.notifyHandlers);

            dispatch(this.handleStoreChange(resource));
        };
    }

    private handleStoreChange(resource: any) {
        return (dispatch: Dispatch, getState: GetState) => {
            if (resource.storeId &&
                resource.storeId !== this.storeSelector.getCurrentStore(getState())) {
                dispatch(this.storeSelector.setCurrentStore(resource.storeId));
            }
        };
    }

    /**
     * Save resources for routes so they can be used later without having to
     * call the backend router.
     */
    public saveRoutes(routes: any[]) {
        return this.locationInfoCache.wrapAction({
            type: this.actionTypes.SAVE_ROUTES,
            routes,
        });
    }

    /**
     * Programatically navigate to a new location.
     */
    public navigate(newLocation: LocationArgType, isRedirect = false): DispatcherFun {
        return (_: Dispatch, getState: GetState) => {
            const theLocation = {
                ...parseLocation(newLocation),
                queryParams: newLocation.queryParams,
                isRedirect,
            };

            const currentLocation = this.location(getState());

            const { originPath } = cfurState;

            /**
             * If current requested location path does NOT include the existing origin path we should process
             * history as it was before, as this could mean a click on other links than filters.
             * Origin path is the base category path a user landed on for the first time before any CFUR is matched,
             * and if it was matched - we saved it into the variable `originPath`.
             */
            if (originPath && originPath.length && theLocation.pathname?.indexOf(originPath) === -1) {
                /**
                 * Reset Applied CFUR data in case of visiting another page link as this could keep overriding
                 * a category details such as meta tile, description, canonical link etc.
                 */
                this.setAppliedCfur(undefined);
                this.processHistory(currentLocation, theLocation);
                return;
            }

            this.customProcessHistory(currentLocation, theLocation);
        };
    }

    /**
     * @param currentLocation
     * @param theLocation
     * @private
     */
    private customProcessHistory(currentLocation: LocationArgType, theLocation: LocationArgType): void {
        const { originPath, queryParams } = cfurState;

        const newLocation = theLocation;

        let url = `${newLocation.pathname}`;
        let search = `${newLocation.search}`;

        let searchRequestByTarget = false;
        if (queryParams && originPath) {
            const isSearchByOrigin = shouldSearchOrigin(search);
            if (isSearchByOrigin || isQueryParamNotComplete(search)) {
                searchRequestByTarget = true;
                url = originPath;
            }

            if (isSearchByOrigin) {
                search = '';
            }
        }

        const { appIsPortalCheckIsRedirectUrl } = this;
        const data = {
            urlcode: btoa(url),
            search: btoa(search),
        };
        this.magentoAPI.post(appIsPortalCheckIsRedirectUrl, { data }).then((redirectResult: IPortalDataRedirect) => {
            const { result, redirect } = redirectResult;
            if (!!result && newLocation.pathname === '/') {
                const defaultCategoryLocation = newLocation;
                defaultCategoryLocation.pathname = redirect;
                defaultCategoryLocation.queryParams = undefined;
                this.setAppliedCfur(undefined);
                this.processHistory(currentLocation, defaultCategoryLocation);
                return;
            }

            const appCfurCheckUrl: string = this.getCfurUrl(data, searchRequestByTarget);
            this.magentoAPI.get(appCfurCheckUrl).then((data: ICfurData): void => {
                const isCfurFound = Boolean(data.cfur_by_target || data.cfur_by_request);
                let cfurLocation: ExtendedLocation|undefined;

                if (isCfurFound) {
                    cfurLocation = processLocation(newLocation, data, this.setAppliedCfur.bind(this));
                } else if (queryParams && searchRequestByTarget) {
                    cfurLocation = restoreOrigin(newLocation);
                }

                if (cfurLocation) {
                    newLocation.pathname = cfurLocation.pathname;
                    newLocation.search = cfurLocation.search;
                    newLocation.queryParams = cfurLocation.queryParams;
                }

                if (!isCfurFound) {
                    this.setAppliedCfur(undefined);
                }

                this.processHistory(currentLocation, newLocation);
            });
        });
    }

    /**
     * Get full composed CFUR API check url
     *
     * @param urlData
     * @param searchRequestByTarget
     * @private
     */
    private getCfurUrl(urlData: { urlcode: string; search: string }, searchRequestByTarget: boolean): string {
        const { urlcode, search } = urlData;

        const { baseCfurApiUrl } = this;
        const theUrl = `urlCode=${urlcode}`;
        const theSearch = `searchCode=${search}`;
        const requestByTarget = `&searchRequestByTarget=${searchRequestByTarget}`;

        return `${baseCfurApiUrl}?${theUrl}&${theSearch}&${requestByTarget}`;
    }

    /**
     * Process location using history
     *
     * @param currentLocation
     * @param theLocation
     * @private
     */
    private processHistory(currentLocation: LocationArgType, theLocation: LocationArgType): void {
        if (isSameLocation(currentLocation, theLocation)) {
            this.history.replace(theLocation);
        } else {
            this.history.push(theLocation);
        }
    }

    /**
     * Trigger the router whenever the location changes. Also
     * triggers the router on the initial location.
     */
    private hookHistory(dispatch: Dispatch, getState: GetState) {
        this.history.listen((location: ExtendedLocation) => {
            const state = this.select(getState());
            const oldLocation = state.location;
            const oldKey = oldLocation?.key;
            const newKey = location.key;

            // only update location when visiting
            // a different location
            if (oldKey !== newKey) {
                const { currentPath, queryParams, latestAppliedCfur } = cfurState;

                if (!this.appliedCfur && latestAppliedCfur[location.pathname]) {
                    this.setAppliedCfur(latestAppliedCfur[location.pathname]);
                } else if (this.appliedCfur && !latestAppliedCfur[location.pathname]) {
                    this.setAppliedCfur(undefined);
                }

                /**
                 * If `currentPath` was pre-saved before we went out from a PLP to e.g. a PDP and clicked `Back`, we
                 * have to check if `location.pathname` is the same and there are pre-saved `queryParams` in order
                 * to restore them back for loading products data correctly and apply pre-selected color.
                 */
                if (currentPath && currentPath === location.pathname && queryParams && !location.queryParams) {
                    const restoredCfurLocation: ExtendedLocation = {
                        ...location,
                        queryParams,
                    };
                    dispatch(this.setLocation(restoredCfurLocation));
                } else {
                    dispatch(this.setLocation(location));
                }
            }

            this.restoreScrollLocation(getState, location, oldLocation);
        });

        dispatch(this.navigate(this.history.location));
    }

    /**
     * Restores the scroll position when navigating back to an old
     * location.
     */
    private restoreScrollLocation(getState: GetState, location: Location, oldLocation: Location) {
        const { scrollPosition } = this.select(getState());
        let defaultValue = 0;
        if (location.pathname === oldLocation?.pathname) {
            defaultValue = scrollPosition[oldLocation.key || ''];
        }
        this.scrolling.scrollWindowTo({
            top: scrollPosition[location.key || ''] || defaultValue,
            behavior: 'auto',
        });
    }

    /**
     * Stores the scroll position whenever the window is scrolled
     * so it can be restored when navigating back to the same location
     * later.
     */
    private hookScrolling(dispatch: Dispatch, getState: GetState) {
        this.scrolling.addListener(this.debounce(() => {
            const { location } = this.select(getState());
            dispatch({
                type: this.actionTypes.SET_SCROLL_POS,
                key: location.key,
                value: this.scrolling.windowScrollPosition.y,
            });
        }, 200));
    }

    /**
     * Gets the current location of the application.
     */
    location(state: any): Location {
        return this.select(state).location || emptyLocation;
    }

    /**
     * Gets the percentage progress of the routing and data look up
     * for the current location.
     */
    getProgress(state: object, progressVals: IProgressVals): number {
        const foundRoute = this.getCurrentResourceInfo(state).resourceType;

        if (!foundRoute) return progressVals.routing;

        const percent = this.select(state).dataProgress;
        return (1 - progressVals.found) * percent + progressVals.found;
    }

    /**
     * Gets the history library's key for the current location.
     */
    getLocationKey(state: any): string {
        return locationToString(this.location(state));
    }

    /**
     * Gets the internal key for the current location. This changes
     * even for the same history entry if it is revisted.
     */
    getKey(state: object): number {
        return this.select(state).internalKey;
    }

    /**
     * Get's the resource for the current location.
     */
    getCurrentResourceInfo(state: object): any {
        const { location } = this.select(state);
        const locationInfo: StoreCache<any> = this.locationInfoCache.getCurrentState(state);
        if (!location) return {};
        if (!locationInfo[location.pathname]) return {};
        return locationInfo[location.pathname];
    }

    /**
     * Get current applied Category Filter Url Rewrite.
     */
    getAppliedCfur(state: object): ICfur|undefined {
        const info = this.getCurrentResourceInfo(state);
        const { resourceType } = info;
        if (resourceType && resourceType === 'category') {
            const { appliedCfur } = this;
            return appliedCfur;
        }

        return undefined;
    }

    /**
     * Set current applied Category Filter Url Rewrite.
     */
    setAppliedCfur(appliedCfur: ICfur|undefined): void {
        this.appliedCfur = appliedCfur;
    }
}
