import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ICartItemProps, ICartSummaryData } from 'ui/component/cart/cart-interface';
import { ContentWithItems } from './content-with-items';
import styles from './style.css';

interface ContentProps {
    cartItems: ICartItemProps[];
    cartSummaryData: ICartSummaryData;
    toggleMinicart?: () => void;
    isCheckoutPage?: boolean;
}
export const Content: React.FC<ContentProps> = ({
    cartItems,
    cartSummaryData,
    toggleMinicart,
    isCheckoutPage = false,
}) => {
    const t = usePhraseTranslater();
    if (cartItems.length > 0) {
        return (
            <ContentWithItems
                cartItems={cartItems}
                cartSummaryData={cartSummaryData}
                toggleMinicart={toggleMinicart}
                isCheckoutPage={isCheckoutPage}
            />
        );
    }
    return (
        <p className={styles.emptyMessage}>{t('Your cart is empty')}</p>
    );
};
