import React from 'react';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { useLocation } from '@silkpwa/module/router/use-location';
import { classes } from '@silkpwa/module/util/classes';
import { ICartItemProps } from 'ui/component/cart/cart-interface';
import styles from './style.css';

interface IItemNameProps {
    item: ICartItemProps;
}

export const ItemName: React.FC<IItemNameProps> = ({ item }) => {
    const { pathname } = useLocation();
    const isCheckoutPage = pathname === '/checkout' || pathname === '/checkout/';
    const config = useConfig();
    const getUrl = (url: string) => `${config.store_config?.current_store?.url}${url}`;
    const name = item.product?.url_key ? <a href={getUrl(item.product?.url_key)}>{item.name}</a> : item.name;
    return (
        <div className={classes(styles.productItemNameText, isCheckoutPage ? styles.productItemNameTextCkt : {})}>
            {name}
        </div>
    );
};
